import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NoAuthSider from "../../components/NoAuthSider";
import { useForm } from "antd/lib/form/Form";
import { connect } from "react-redux";
import { setError } from "../../store/reducers/errorSlice";

function ForgotPassword(props) {
  const [form] = useForm();
  const navigate = useNavigate();
  const [isFormReady, setIsFormReady] = useState(false);

  useEffect(() => {
    // form.validateFields();
    const interval = setInterval(() => {
      setIsFormReady(form.isFieldTouched("email") && !form.getFieldsError().some((item) => item.errors.length > 0));

      return () => {
        clearInterval(interval);
      };
    }, 200);
  }, [form]);

  const onFinish = (values) => {
    axios
      .post("/auth/forgot-password", {
        email: values.email,
      })
      .then(() => {
        navigate("/");
        props.setError({
          show: true,
          message:
            // eslint-disable-next-line max-len
            "We have emailed you a reset password link.",
          color: "#97BE11",
        });
      })
      .catch((e) => {});
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div id="forgot password" className="no-auth-page flex">
      <div className="sider">
        <NoAuthSider image="/images/retail-delivery-Adore-Me-Caviar-coronavirus-DoorDash.jpg" />
      </div>
      <div className="other">
        <div className="content flex">
          <div className="wrapper">
            <h1 className="title">Forgot password? </h1>
            <p className="description">A reset password link will be sent to your email</p>
            <Form
              layout="vertical"
              name="basic"
              form={form}
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
                size: "large",
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    key="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="123456@gmail.com" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                  >
                    <Button type="primary" htmlType="submit" disabled={!isFormReady}>
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setError: (obj) => dispatch(setError(obj)),
  };
}

export default connect(null, mapDispatchToProps)(ForgotPassword);
