/* eslint-disable no-throw-literal */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import axios from 'axios';

//#region create
export const createProduct = createAsyncThunk(
  'data/createProduct',
  async (arg) => {
    const data = arg;
    return await axios
      .post('/products', data)
      .then((res) => res)
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        else throw 'An error has occurred please contact system administrator';
      });
  },
);

export const createProductPending = (state, action) => {
  state.isLoading = true;
};
export const createProductFulfilled = (state, action) => {
  state.isLoading = false;
  notification.success({
    message: `Success`,
    description: 'Product created',
    placement: 'bottomRight',
  });
};
export const createProductRejected = (state, action) => {
  // console.error(action);
  state.isLoading = false;
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion

//#region update
export const updateProduct = createAsyncThunk(
  'data/updateProduct',
  async (arg) => {
    const data = arg;
    const isDelete = arg.Deleted;
    const res = await axios
      .put(`/products/${arg.id}`, data)
      .then((res) => res)
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        else throw 'An error has occurred please contact system administrator';
      });
    return { ...res, isDelete };
  },
);

export const updateProductPending = (state, action) => {
  state.isLoading = true;
};
export const updateProductFulfilled = (state, action) => {
  state.isLoading = false;
  if (action.payload.isDelete)
    notification.success({
      message: `Success`,
      description: 'The Order Guide has been deleted.',
      placement: 'bottomRight',
    });
  else
    notification.success({
      message: `Success`,
      description: 'Product updated',
      placement: 'bottomRight',
    });
};
export const updateProductRejected = (state, action) => {
  // console.error(action);
  state.isLoading = false;
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion

//#region Delete
export const deleteProduct = createAsyncThunk(
  'data/deleteProduct',
  async (arg) => {
    const data = arg;
    return await axios.put(`/products/${arg.id}`, data).then((res) => res);
  },
);

export const deleteProductPending = (state, action) => {
  state.isLoading = true;
};
export const deleteProductFulfilled = (state, action) => {
  state.isLoading = false;
  message.success('The Order Guide has been deleted.');
};
export const deleteProductRejected = (state, action) => {
  // console.error(action);
  state.isLoading = false;
  message.error('Failed to delete the Order Guide. Please try again later.');
};
//#endregion
