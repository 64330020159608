import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

function NotFoundPage() {
	return (
		<div style={{ marginTop: "100px" }}>
			<Row>
				<Col span={6} offset={9}>
					<img style={{ width: "100%", marginBottom: "24px" }} src="/images/404.png" alt="404" />
				</Col>
			</Row>
			<Row>
				<Col
					span={24}
					style={{
						textAlign: "center",
						margin: "16px 0",
						fontWeight: 700,
						fontSize: "56px",
						lineHeight: "110%",
						color: "#4D5E55",
					}}
				>
					OOPS!
				</Col>
			</Row>
			<Row>
				<Col
					span={24}
					style={{ textAlign: "center", fontWeight: 400, fontSize: "32px", lineHeight: "120%", color: "#4D5E55" }}
				>
					Page not found
				</Col>
			</Row>
			<Row>
				<Col span={24} style={{ textAlign: "center", margin: "70px 0" }}>
					<Link to={"/"} className="btn" style={{ margin: 0, minWidth: "400px" }}>
						{"Go to Dashboard "}
					</Link>
				</Col>
			</Row>
		</div>
	);
}

export default NotFoundPage;
