import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import MaskedInput from 'antd-mask-input';

import { createUser, updateUser } from '../../store/thunks/user';
import { setQuery, setSelect, setUrl } from '../../store/reducers/dataSlice';
import * as roles from './../../services/constants/roles';
import axios from 'axios';
import { setSelectedMenu } from '../../store/reducers/uiSlice';

const { Option } = Select;

function UserInfoPage(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigateTo = useNavigate();
  const [permit, setPermit] = useState(true);
  const [isTouched, setIsTouched] = useState(false);
  const [districts, setDistricts] = useState([]);

  function fillForm(data) {
    let hasPermit = true;
    if (props?.roleId === roles.DISTRICT_ROLE_ID) {
      if (data.role.id === roles.DISTRICT_ROLE_ID && data.id !== props.myId)
        hasPermit = false;
      else if (
        data.role.id === roles.SCHOOL_ROLE_ID &&
        data.DistrictId.id !== props.myId
      )
        hasPermit = false;
    }

    if (props?.roleId === roles.SCHOOL_ROLE_ID) hasPermit = false;
    setPermit(hasPermit);
    hasPermit &&
      form.setFieldsValue({
        DistrictOrSchoolName: data.DistrictOrSchoolName,
        UserStatus: data.UserStatus,
        FirstName: data.FirstName,
        LastName: data.LastName,
        UserDeliveryDays: data.UserDeliveryDays
          ? data.UserDeliveryDays.map((dd) => dd.DayId)
          : [],
        email: data.email,
        DirectorName:
          data.DirectorName || `${props.me?.FirstName} ${props.me?.LastName}`,
        Phone: data.Phone,
        IsCoOp: data.IsCoOp,
        shippingAddress: data.shippingAddress,
        billingAddress: data.billingAddress,
        billingEmail: data.billingEmail,
        confirmed: data.confirmed,
        ccEmails: data?.ccEmails || [],
      });
  }
  useEffect(() => {
    props.setSelectedMenu('5');

    if (
      props.functionality === 'create' &&
      props?.roleId === roles.ADMIN_ROLE_ID
    ) {
      axios
        .get(`/users?&role.id=3&_sort=DistrictOrSchoolName:ASC`)
        .then((res) => {
          setDistricts(res.data);
        })
        .catch((e) => {
          notification.error({
            message: `Error`,
            description: `can't fetch districts list`,
            placement: 'bottomRight',
          });
        });
    }
  }, [props.functionality, props?.roleId]);

  useEffect(() => {
    if (props.functionality === 'update') {
      props.setUrl(`/users`);
      props.setSelect('');
      props.setQuery(`&id=${id}`);
    } else props.setQuery(`&##`);
  }, [id, props]);

  useEffect(() => {
    if (props.data && props.functionality === 'update')
      setTimeout(() => fillForm(props.data), 1000);
    else {
      form.setFieldsValue({ UserStatus: 'pendingReview' });
      if (props?.roleId === roles.DISTRICT_ROLE_ID)
        form.setFieldsValue({
          DirectorName: `${props.me?.FirstName} ${props.me?.LastName}`,
        });
    }
  }, [form, props?.data, props?.functionality]);

  function chooseThunkAndCall(data) {
    if (props.functionality === 'create') {
      dispatch(createUser(data));
    } else {
      const { password, confirm, ...updateData } = data;
      dispatch(
        updateUser(
          props?.me?.role?.id === 4 ||
            props?.data?.DistrictId?.id === props?.me?.id
            ? data
            : updateData,
        ),
      );
    }
  }

  const [IsPhoneValid, setIsPhoneValid] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });
  const phoneValidation = (val) => {
    // const regex = /\b\d{10}\b/g;
    const regex = /^(?=(?:.*?\d){10})/gm;
    if (regex.test(val)) {
      return { validateStatus: 'success', errorMsg: null };
    }

    return { validateStatus: 'error', errorMsg: 'Phone is not correct' };
  };

  const onFinish = (values) => {
    const days = [
      { DayId: 'saturday' },
      { DayId: 'sunday' },
      { DayId: 'monday' },
      { DayId: 'tuesday' },
      { DayId: 'wednesday' },
      { DayId: 'thursday' },
      { DayId: 'friday' },
    ];

    let UserDeliveryDays = days.filter(
      (day) =>
        values.UserDeliveryDays && values.UserDeliveryDays.includes(day.DayId),
    );

    if (props.functionality === 'update')
      if (props.data?.role?.id === roles.DISTRICT_ROLE_ID) {
        //choose role
        let data = {
          ...values,
          UserDeliveryDays,
          id,
          username: props.data?.username,
        };
        chooseThunkAndCall(data);
      } else {
        let data = { ...values, UserDeliveryDays, id };
        chooseThunkAndCall(data);
      }
    else {
      dispatch(
        createUser({
          ...values,
          DistrictId:
            props?.roleId === roles.ADMIN_ROLE_ID
              ? values.DistrictId
              : props?.myId,
          blocked: true,
          confirmed: false,
          UserDeliveryDays,
          username: values.email,
          password: (Math.random() + 1).toString(36).substring(2),
          role: 'authenticated',
          ccEmails: values?.ccEmails || [],
        }),
      ).then((res) => {
        navigateTo('/users');
      });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const PasswordComponent = () =>
    (props?.me?.role?.id === 4 ||
      props?.data?.DistrictId?.id === props?.me?.id) && (
      <>
        <Col span={24}>
          <h2 className="title">Account</h2>
        </Col>
        <Col md={12} span={24}>
          <Form.Item label="Change Password" name="password">
            <Input.Password
              size="large"
              placeholder="Enter your password"
              rules={[
                {
                  required: props.functionality === 'create' ? true : false,
                  message: 'Please enter your password!',
                },
                {
                  pattern:
                    props.functionality === 'create'
                      ? /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
                      : false,
                  message:
                    'Password must be at least eight characters, one numerical letter,' +
                    'one lowercase letter, one uppercase letter and, one special character!',
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col md={12} span={24}>
          <Form.Item
            label="Confirm Password"
            name="confirm"
            rules={[
              {
                required: props.functionality === 'create',
                message: 'Confirm password is required!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              placeholder="Enter your confirmation password, 6+ characters"
            />
          </Form.Item>
        </Col>
      </>
    );

  return (
    <div id="user-info-page" className="info-page">
      {permit && (
        <div className="container">
          <h2 className="title go-back" onClick={() => navigateTo(-1)}>
            <img src="/vectors/arrow-left.png" alt="" />
            Go Back
          </h2>

          <Spin
            spinning={props.functionality === 'update' && props.loading}
            tip="Loading..."
          >
            <h2 className="title">
              {props.data && props.data?.role
                ? props.data?.role?.name
                : 'School'}{' '}
              user information
            </h2>
            {props.functionality === 'update' &&
            props.data &&
            props.data?.role?.id === roles.DISTRICT_ROLE_ID ? (
              <Form
                onFieldsChange={() => {
                  setIsTouched(true);
                }}
                className="district-info"
                layout="vertical"
                name="basic"
                form={form}
                labelCol={{
                  span: 8,
                }}
                initialValues={{
                  IsCoOp: props.data?.IsCoOp ? true : false,
                  // size: "large",
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row>
                  <Col md={12} span={24}>
                    <Form.Item
                      label="District Name"
                      name="DistrictOrSchoolName"
                      key="DistrictOrSchoolName"
                      rules={[]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter your district name"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} span={24}>
                    <Form.Item
                      label="Status"
                      name="UserStatus"
                      key="UserStatus"
                    >
                      <Select allowClear={true} size={'large'}>
                        <Option value="pendingReview" key="1">
                          Pending Review
                        </Option>
                        <Option value="active" key="2">
                          Active
                        </Option>
                        <Option value="inactive" key="3">
                          Inactive
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} span={24}>
                    <Form.Item
                      label="First Name"
                      name="FirstName"
                      key="FirstName"
                    >
                      <Input size="large" placeholder="Enter your first name" />
                    </Form.Item>
                  </Col>
                  <Col md={12} span={24}>
                    <Form.Item label="Last Name" name="LastName" key="LastName">
                      <Input size="large" placeholder="Enter your last name" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} span={24}>
                    <Form.Item
                      label="Email"
                      name="email"
                      key="email"
                      rules={[
                        {
                          type: 'email',
                          message: 'Please enter a valid email',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter your email"
                        disabled={roles.ADMIN_ROLE_ID !== props?.roleId}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} span={24}>
                    <Form.Item
                      label="Phone"
                      name="Phone"
                      key="Phone"
                      validateStatus={IsPhoneValid.validateStatus}
                      help={IsPhoneValid.errorMsg}
                    >
                      <MaskedInput
                        mask="(000) 000-0000"
                        size="large"
                        placeholder="Enter Your phone number"
                        onChange={(e) => {
                          setIsPhoneValid({
                            ...phoneValidation(e.target.value),
                            value: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12} span={24}>
                    <Form.Item
                      label="Billing Email"
                      name="billingEmail"
                      key="billingEmail"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your billing email!',
                        },
                        {
                          type: 'email',
                          message: 'Please input a valid email address!',
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Enter your email" />
                    </Form.Item>
                  </Col>
                  <Col md={12} span={24}>
                    <Form.Item
                      label={
                        <>
                          <span style={{ color: 'red', marginRight: 5 }}>
                            *
                          </span>
                          Billing Address
                        </>
                      }
                      name="billingAddress"
                      key="billingAddress"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value.trim()) {
                              return Promise.reject(
                                new Error('Please input your billing address!'),
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input size="large" placeholder="Your billing address" />
                    </Form.Item>
                  </Col>

                  <Col md={12} span={24}>
                    <Form.Item
                      label={
                        <>
                          <span style={{ color: 'red', marginRight: 5 }}>
                            *
                          </span>
                          Shipping Address
                        </>
                      }
                      name="shippingAddress"
                      key="shippingAddress"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value.trim()) {
                              return Promise.reject(
                                new Error(
                                  'Please input your shipping address!',
                                ),
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input size="large" placeholder="Your shipping address" />
                    </Form.Item>
                  </Col>
                  <Col md={12} span={24}>
                    <Form.Item
                      label="Select Delivery Days"
                      name="UserDeliveryDays"
                      key="UserDeliveryDays"
                      rules={[
                        {
                          required: true,
                          message: 'Please select delivery days!',
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        allowClear
                        size={'large'}
                        placeholder="Please select"
                        // defaultValue={props.data.UserDeliveryDays}
                      >
                        <Option value={'sunday'}>Sunday</Option>
                        <Option value={'monday'}>Monday</Option>
                        <Option value={'tuesday'}>Tuesday</Option>
                        <Option value={'wednesday'}>Wednesday</Option>
                        <Option value={'thursday'}>Thursday</Option>
                        <Option value={'friday'}>Friday</Option>
                        <Option value={'saturday'}>Saturday</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} span={24}>
                    <Form.Item label="CC Emails" name="ccEmails" key="ccEmails">
                      <Select
                        mode="tags"
                        allowClear={true}
                        size={'large'}
                        placeholder="Please select"
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} span={24}>
                    <Form.Item
                      name="confirmed"
                      key={'confirmed'}
                      valuePropName="checked"
                      wrapperCol={{
                        offset: 0,
                        span: 16,
                      }}
                    >
                      <Checkbox>Email confirmed?</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                {/* <Row>
                <Col md={12} span={24}>
                  <Form.Item
                    name="IsCoOp"
                    key={"IsCoOp"}
                    valuePropName="checked"
                    wrapperCol={{
                      offset: 0,
                      span: 16,
                    }}
                  >
                    <Checkbox>Super Co op</Checkbox>
                  </Form.Item>
                </Col>
              </Row> */}

                <Row>
                  <PasswordComponent />
                </Row>

                <div className="btn-gp">
                  {props.functionality === 'create' ? (
                    <Form.Item
                      wrapperCol={{
                        offset: 0,
                        span: 24,
                      }}
                    >
                      <Button className="btn" type="primary" htmlType="submit">
                        Confirm
                      </Button>
                    </Form.Item>
                  ) : (
                    <>
                      <Form.Item
                        wrapperCol={{
                          offset: 0,
                          span: 24,
                        }}
                      >
                        <Button
                          className="btn"
                          type="primary"
                          htmlType="submit"
                          disabled={!isTouched}
                        >
                          Update
                        </Button>
                      </Form.Item>
                      {!props?.data?.confirmed && (
                        <Button
                          className="btn"
                          type="secondary"
                          onClick={() => {
                            axios
                              .post(`/auth/send-email-confirmation`, {
                                email: props.data?.email,
                              })
                              .then(() => {
                                notification.success({
                                  message: `Success`,
                                  description:
                                    'Email confirmation has been sent!',
                                  placement: 'bottomRight',
                                });
                              })
                              .catch((error) => {
                                notification.error({
                                  message: `Error`,
                                  description: `Sending email confirmation was not successful`,
                                  placement: 'bottomRight',
                                });
                              });
                          }}
                        >
                          Send Confirmation Email
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </Form>
            ) : (
              <Form
                className="school-info"
                layout="vertical"
                form={form}
                name="basic"
                labelCol={{
                  span: 8,
                }}
                initialValues={{
                  remember: true,
                  size: 'large',
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row>
                  <Col md={12} span={24}>
                    <Form.Item
                      label="First Name"
                      name="FirstName"
                      key="FirstName"
                      rules={[
                        {
                          required: true,
                          message: 'Please input first name!',
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Enter your first name" />
                    </Form.Item>
                  </Col>

                  <Col md={12} span={24}>
                    <Form.Item
                      label="Status"
                      name="UserStatus"
                      key="UserStatus"
                      rules={[
                        {
                          required: true,
                          message: 'Please pick status!',
                        },
                      ]}
                    >
                      <Select
                        allowClear={true}
                        // style={{ width: 120 }}
                        size="large"
                        defaultValue={
                          props.data && props.functionality === 'update'
                            ? props.data?.UserStatus
                            : null
                        }
                        disabled={props.functionality === 'create'}
                      >
                        <Option value="pendingReview" key="1">
                          Pending Review
                        </Option>
                        <Option value="active" key="2">
                          Active
                        </Option>
                        <Option value="inactive" key="3">
                          Inactive
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} span={24}>
                    <Form.Item
                      label="Last Name"
                      name="LastName"
                      key="LastName"
                      rules={[
                        {
                          required: true,
                          message: 'Please input last name!',
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Enter your last name" />
                    </Form.Item>
                  </Col>

                  <Col md={12} span={24}>
                    <Form.Item
                      label="Director Name"
                      name="DirectorName"
                      key="DirectorName"
                      rules={[
                        {
                          required: true,
                          message: 'Please input director name!',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter your director name"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} span={24}>
                    <Form.Item
                      label="Email"
                      name="email"
                      key="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your email!',
                        },
                        {
                          type: 'email',
                          message: 'Please enter a valid email',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter your email"
                        disabled={
                          props.functionality === 'update' &&
                          roles.ADMIN_ROLE_ID !== props?.roleId
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} span={24}>
                    <Form.Item
                      label="School Name"
                      name="DistrictOrSchoolName"
                      key="DistrictOrSchoolName"
                      rules={[
                        {
                          required: true,
                          message: 'Please input director name!',
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter your school name"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} span={24}>
                    <Form.Item
                      label="Phone"
                      name="Phone"
                      key="Phone"
                      validateStatus={IsPhoneValid.validateStatus}
                      help={IsPhoneValid.errorMsg}
                      rules={[
                        {
                          required: true,
                          message: 'Please input phone!',
                        },
                      ]}
                    >
                      <MaskedInput
                        mask="(000) 000-0000"
                        size="large"
                        placeholder="Enter Your phone number"
                        onChange={(e) => {
                          setIsPhoneValid({
                            ...phoneValidation(e.target.value),
                            value: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {props.functionality === 'update' && (
                    <>
                      <Col md={12} span={24}>
                        <Form.Item
                          label="Billing Email"
                          name="billingEmail"
                          key="billingEmail"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your billing email!',
                            },
                            {
                              type: 'email',
                              message: 'Please input a valid email address!',
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Enter your email" />
                        </Form.Item>
                      </Col>
                      <Col md={12} span={24}>
                        <Form.Item
                          label={
                            <>
                              <span style={{ color: 'red', marginRight: 5 }}>
                                *
                              </span>
                              Billing Address
                            </>
                          }
                          name="billingAddress"
                          key="billingAddress"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (!value.trim()) {
                                  return Promise.reject(
                                    new Error(
                                      'Please input your billing address!',
                                    ),
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Your billing address"
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} span={24}>
                        <Form.Item
                          label={
                            <>
                              <span style={{ color: 'red', marginRight: 5 }}>
                                *
                              </span>
                              Shipping Address
                            </>
                          }
                          name="shippingAddress"
                          key="shippingAddress"
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (!value.trim()) {
                                  return Promise.reject(
                                    new Error(
                                      'Please input your shipping address!',
                                    ),
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Your shipping address"
                          />
                        </Form.Item>
                      </Col>
                      <PasswordComponent />
                    </>
                  )}
                  {props.functionality === 'create' &&
                    props?.roleId === roles.ADMIN_ROLE_ID && (
                      <Col md={12} span={24}>
                        <Form.Item
                          label="District Name"
                          name="DistrictId"
                          key="DistrictId"
                          rules={[
                            {
                              required: true,
                              message: 'Please select your district ',
                            },
                          ]}
                        >
                          <Select
                            allowClear={true}
                            showSearch
                            size={'large'}
                            optionFilterProp="children"
                            required={true}
                            onChange={(v) => {
                              let target = districts.find((d) => d.id === v);
                              let text = `${target.FirstName} ${target.LastName}`;
                              form.setFieldsValue({
                                DirectorName: text,
                              });
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {districts ? (
                              districts.map((option) => (
                                <Option value={option.id} key={option.id}>
                                  {option.DistrictOrSchoolName}
                                </Option>
                              ))
                            ) : (
                              <></>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                </Row>

                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  <div className="btn-gp">
                    {props.functionality === 'create' ? (
                      <Form.Item
                        wrapperCol={{
                          offset: 0,
                          span: 24,
                        }}
                      >
                        <Button
                          disabled={!form.isFieldTouched}
                          className="btn"
                          type="primary"
                          htmlType="submit"
                        >
                          Invite
                        </Button>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        wrapperCol={{
                          offset: 0,
                          span: 24,
                        }}
                      >
                        <Button
                          className="btn"
                          type="primary"
                          htmlType="submit"
                        >
                          Update
                        </Button>
                      </Form.Item>
                    )}
                  </div>
                </Form.Item>
              </Form>
            )}
            <div style={{ height: '120px' }}></div>
          </Spin>
        </div>
      )}
      {!permit && (
        <div style={{ marginTop: '100px' }}>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: 'center',
                margin: '16px 0',
                fontWeight: 700,
                fontSize: '56px',
                lineHeight: '110%',
                color: '#4D5E55',
              }}
            >
              OOPS!
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: 'center',
                fontWeight: 400,
                fontSize: '32px',
                lineHeight: '120%',
                color: '#4D5E55',
              }}
            >
              Permission is denied!
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'center', margin: '70px 0' }}>
              <Link
                to={'/'}
                className="btn"
                style={{ margin: 0, minWidth: '400px' }}
              >
                {'Go to Dashboard '}
              </Link>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data:
      state.data && state.data.data && state.data.data[0]
        ? state.data.data[0]
        : null,
    loading: state.data.isLoading,

    me: state.me.data,
    myId: state.me.id,
    roleId: state.me.roleId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (q) => dispatch(setUrl(q)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoPage);
