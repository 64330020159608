import { connect, useDispatch } from 'react-redux';
import {
  setSelect,
  setUrl,
  setQuery,
  getData,
} from '../../store/reducers/dataSlice';
import Table from '../../components/Table';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import * as roles from './../../services/constants/roles';
import { notification, Spin, Tooltip } from 'antd';

import moment from 'moment';
import { useState } from 'react';
import axios from 'axios';
import { setSelectedMenu } from '../../store/reducers/uiSlice';
import { CSVLink } from 'react-csv';
import { orderStatusLabels } from '../../utils/helpers';

function OrdersPage(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setCount] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    props.setSelectedMenu('2');

    props.setUrl('/orders');
    props.setSelect(
      `${
        props.roleId === roles.ADMIN_ROLE_ID
          ? ``
          : `${
              props.roleId === roles.DISTRICT_ROLE_ID
                ? `&DistrictUserId.id=${props.userId}&`
                : props.roleId === roles.SCHOOL_ROLE_ID
                  ? `&SchoolUserId.id=${props.userId}&`
                  : ''
            }`
      }`,
    );
    props.setQuery('&_sort=id:DESC');

    axios
      .get(
        `/orders/count?&${
          props.roleId === roles.ADMIN_ROLE_ID
            ? ``
            : `${
                props.roleId === roles.DISTRICT_ROLE_ID
                  ? `&DistrictUserId.id=${props.userId}&`
                  : props.roleId === roles.SCHOOL_ROLE_ID
                    ? `&SchoolUserId.id=${props.userId}&`
                    : ''
              }`
        }`,
      )
      .then((res) => setCount(res.data))
      .catch((e) => console.log(e));

    axios
      .get(
        `/orders?${
          props.roleId === roles.ADMIN_ROLE_ID
            ? ``
            : `${
                props.roleId === roles.DISTRICT_ROLE_ID
                  ? `&DistrictUserId.id=${props.userId}&`
                  : props.roleId === roles.SCHOOL_ROLE_ID
                    ? `&SchoolUserId.id=${props.userId}&`
                    : ''
              }`
        }&_sort=id:DESC`,
      )
      .then((res) => {
        setExportData(
          res?.data?.map((el) => ({
            'Order Number': el?.id || 'N/A',
            'PO number': el?.PO || 'N/A',
            'Date Submitted': el?.created_at
              ? moment(el?.created_at).format('MM-DD-YYYY')
              : 'N/A',
            'Delivery Date': el?.DeliveryDate
              ? moment(el?.DeliveryDate).format('MM-DD-YYYY')
              : 'N/A',
            Status:
              orderStatusLabels[el?.OrderStatus] || el?.OrderStatus || 'N/A',
            'District Name': el?.DistrictUserId?.DistrictOrSchoolName || 'N/A',
            'School Name': el?.SchoolUserId?.DistrictOrSchoolName || 'N/A',
            'Total Commercial Cost': el?.TotalRegularPrice || 'N/A',
            'Total Commodity Price': el?.TotalCommodityPrice || 'N/A',
          })),
        );
      })
      .catch((e) => console.log(e));
    dispatch(getData());
  }, [dispatch, props]);

  useEffect(() => {
    let schoolArray = [];
    let districtArray = [];
    if (props.roleId === roles.ADMIN_ROLE_ID) {
      axios
        .get(`/users?&_sort=DistrictOrSchoolName:ASC`)
        .then((res) => {
          res.data.length &&
            res.data.map((u) => {
              if (u.role.id === roles.DISTRICT_ROLE_ID) {
                districtArray.push({
                  text: u.DistrictOrSchoolName,
                  value: u.id,
                });
              } else if (u.role.id === roles.SCHOOL_ROLE_ID) {
                schoolArray.push({ text: u.DistrictOrSchoolName, value: u.id });
              }
              return null;
            });

          setDistricts(districtArray);
          setSchools(schoolArray);
        })
        .catch((e) => console.log(e));
    }
    if (props.roleId === roles.DISTRICT_ROLE_ID) {
      axios
        .get(`/users?DistrictId=${props.userId}&_sort=DistrictOrSchoolName:ASC`)
        .then((res) => {
          res.data.length &&
            res.data.map((u) => {
              if (u.role.id === roles.SCHOOL_ROLE_ID) {
                schoolArray.push({ text: u.DistrictOrSchoolName, value: u.id });
              }
              return null;
            });

          setSchools(schoolArray);
        })
        .catch((e) => console.log(e));
      axios
        .get(`/users?id=${props.userId}&_sort=DistrictOrSchoolName:ASC`)
        .then((res) => {
          res.data.length &&
            res.data.map((u) => {
              if (u.role.id === roles.DISTRICT_ROLE_ID) {
                districtArray.push({
                  text: u.DistrictOrSchoolName,
                  value: u.id,
                });
              }
              return null;
            });
          setDistricts(districtArray);
        })
        .catch((e) => console.log(e));
    }
    if (props.roleId === roles.SCHOOL_ROLE_ID) {
      axios
        .get(`/users?id=${props.userId}&_sort=DistrictOrSchoolName:ASC`)
        .then((res) => {
          res.data.length &&
            res.data.map((u) => {
              if (u.role.id === roles.SCHOOL_ROLE_ID) {
                schoolArray.push({ text: u.DistrictOrSchoolName, value: u.id });
                districtArray.push({
                  text: u.DistrictId?.DistrictOrSchoolName,
                  value: u.DistrictId?.id,
                });
              }
              return null;
            });
          setSchools(schoolArray);
          setDistricts(districtArray);
        })
        .catch((e) => console.log(e));
    }
  }, []);

  const handleAddOrder = () => {
    if (
      !(props.me?.shippingAddress || '').trim() &&
      props.roleId !== roles.ADMIN_ROLE_ID
    ) {
      notification.warning({
        message:
          'Please add your shipping address in your profile first, then proceed to place the order.',
      });
      return;
    }
    navigate('/orders/new');
  };

  const columns = [
    {
      title: 'Order Number',
      dataIndex: 'id',
      key: 'id',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
    },
    {
      title: 'PO number',
      dataIndex: 'PO',
      key: 'PO',
      render: (text) => (text ? text : 'N/A'),
      searchable: true,
      sorter: {},
    },
    {
      title: 'Date Submitted',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) =>
        text
          ? moment(text.slice(0, 10), 'YYYY-MM-DD').format('MM-DD-YYYY')
          : 'N/A',
      // searchable: true,
      sorter: {},
    },
    {
      title: 'Delivery Date',
      dataIndex: 'DeliveryDate',
      key: 'DeliveryDate',
      render: (text) =>
        text ? moment(text, 'YYYY-MM-DD').format('MM-DD-YYYY') : 'N/A',
      // searchable: true,
      sorter: {},
    },
    {
      title: 'Status',
      dataIndex: 'OrderStatus',
      key: 'OrderStatus',
      render: (text) =>
        text ? (
          <div style={{ textAlign: 'center' }}>
            {orderStatusLabels[text] || text || 'N/A'}
          </div>
        ) : (
          'N/A'
        ),

      sorter: {},
      searchable: true,
    },
    {
      title: 'District Name',
      dataIndex: 'DistrictUserId',
      key: 'DistrictUserId',
      render: (text) =>
        text && text.DistrictOrSchoolName ? text.DistrictOrSchoolName : 'N/A',
      sorter: {},
      location: 'DistrictUserId_in',
      sortLocation: 'DistrictUserId.DistrictOrSchoolName',
      searchable: true,
      filters: districts,
      // filterMultiple: false,
    },
    {
      title: 'School Name',
      dataIndex: 'SchoolUserId',
      key: 'SchoolUserId',
      render: (text) =>
        text && text.DistrictOrSchoolName ? text.DistrictOrSchoolName : 'N/A',
      sorter: {},
      location: 'SchoolUserId_in',
      sortLocation: 'SchoolUserId.DistrictOrSchoolName',
      searchable: true,
      filters: schools,
      // filterMultiple: false,
    },
    {
      title: 'Total Commercial Cost',
      dataIndex: 'TotalRegularPrice',
      key: 'TotalRegularPrice',
      render: (text) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
    },
    {
      title: 'Total Commodity Price',
      dataIndex: 'TotalCommodityPrice',
      key: 'TotalCommodityPrice',
      render: (text) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'OrderStatus',
      key: 'link',
      render: (text, rec) => (
        <Tooltip title="View">
          <Link to={`/orders/${rec.id}`}>
            <img src="/vectors/eye.png" alt="" />
          </Link>
        </Tooltip>
      ),
      width: 50,
    },
  ];

  return (
    <div className="orders-page">
      <h2 className="title">Orders</h2>
      <Spin spinning={count === null}>
        {count !== 0 ? (
          <>
            <div className="btn" onClick={handleAddOrder}>
              Add Order
            </div>
            <CSVLink data={exportData} filename="Orders">
              <div className="btn">Download Orders</div>
            </CSVLink>
            <Table
              columns={columns}
              title="Order"
              link="new"
              scroll={{ x: 1300 }}
            />
          </>
        ) : (
          <>
            <div className="empty-table">
              <div className="wrapper">
                <h3>— Your orders is empty —</h3>
                <div className="btn" onClick={handleAddOrder}>
                  Add orders
                </div>
              </div>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (url) => dispatch(setUrl(url)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

function mapStateToProps(state) {
  return {
    userId: state.me.id,
    roleId: state.me.roleId,
    me: state.me.data,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);
