import React from 'react';

function DeleteModal({ mainMessage, message, deleteFunc, show, setShow }) {
  return (
    <>
      {show && (
        <div className="delete-modal">
          <div className="bg">
            <div className="modal">
              <h3>{mainMessage || 'Are you sure ?'}</h3>
              <p>{message || 'Are you sure to delete this Item ?'}</p>
              <div className="buttons">
                <div
                  className="clickable btn"
                  onClick={() => {
                    deleteFunc();
                    setShow(false);
                  }}
                >
                  Yes
                </div>
                <div
                  className="clickable btn"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  No
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DeleteModal;
