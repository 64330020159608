import {
  Button,
  Col,
  Form,
  Row,
  Spin,
  Select,
  Upload,
  notification,
  Input,
} from 'antd';
import {
  DeleteOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as roles from '../../services/constants/roles';
import { setQuery, setSelect, setUrl } from '../../store/reducers/dataSlice';
import axios from 'axios';
import { ADMIN_ROLE_ID } from '../../services/constants/roles';
import DeleteModal from '../../components/DeleteModal';
import { setSelectedMenu } from '../../store/reducers/uiSlice';
import { addUploadApi } from '../../services/uploads';
import TextArea from 'antd/lib/input/TextArea';
import NotFoundPage from '../messages/NotFoundPage';

const { Option } = Select;

function CreateInvoice(props) {
  const { id } = useParams();
  const state = localStorage.getItem('state');
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const user = JSON.parse(state)?.user;

  useEffect(() => {
    setSelectedMenu('invoices');
    if (props.functionality === 'update') {
      props.setUrl('/invoices');
      props.setSelect('');
      props.setQuery(`&id=${id}`);
    }
  }, [id, props]);

  useEffect(() => {
    if (props.data && props.functionality === 'update') {
      setUploadedFile(props.data?.file);
      setSelectedUsers(props?.data?.userId?.id);
      form.setFieldsValue({
        ...props.data,
        userId: props?.data?.userId?.id,
        orderId: props?.data?.orderId?.id,
      });
    }
  }, [form, props.data, props.functionality]);

  useEffect(() => {
    const selectedUserData = users.find((el) => el?.id === selectedUsers);
    if (selectedUserData) {
      axios
        .get(
          `/orders?_sort=id${
            selectedUserData?.role?.id === roles.DISTRICT_ROLE_ID
              ? `&DistrictUserId.id=${selectedUserData?.id}&`
              : selectedUserData?.role?.id === roles.SCHOOL_ROLE_ID
              ? `&SchoolUserId.id=${selectedUserData?.id}&`
              : ''
          }`,
        )
        .then((res) => {
          setOrderData(res?.data);
        })
        .catch(() => {});
    }
  }, [selectedUsers, users]);

  useEffect(() => {
    axios
      .get(
        `/users?_sort=FirstName,LastName&role.id=${roles.DISTRICT_ROLE_ID}&role.id=${roles.SCHOOL_ROLE_ID}`,
      )
      .then((res) => {
        setUsers(res?.data);
      })
      .catch(() => {});

    // if(props.functionality === "create"){
    //   axios.get('/invoices?_sort=number&_start=0&_limit=1').then((res)=>{
    //     form.setFieldsValue({number:(res?.data?.[0]?.number?Number(res?.data?.[0]?.number):0)+1})
    //   }).catch(()=>{})
    // }
  }, []);

  const onFinish = (values) => {
    let data = {
      ...values,
      number: String(values?.number || 0),
      file: uploadedFile?.id ? uploadedFile?.id : null,
    };

    if (props.functionality === 'create') {
      axios
        .post('/invoices', data)
        .then((res) => {
          notification.success({
            message: `Success`,
            description: 'Invoice Successfully created!',
            placement: 'bottomRight',
          });
          navigate(-1);
        })
        .catch((e) => {
          if (
            e?.response &&
            e?.response?.data?.message?.[0]?.messages?.[0]?.message
          )
            throw e.response.data.message[0].messages[0].message;
          else
            throw 'An error has occurred please contact system administrator';
        });
    } else {
      axios
        .put(`/invoices/${id}`, data)
        .then((res) => {
          notification.success({
            message: `Success`,
            description: 'Invoice Successfully updated!',
            placement: 'bottomRight',
          });
          navigate(-1);
        })
        .catch((e) => {
          if (e.response && e.response.data.message[0].messages[0].message)
            throw e.response.data.message[0].messages[0].message;
          else
            throw 'An error has occurred please contact system administrator';
        });
    }
  };

  const uploadFile = async (file) => {
    setUploadLoading(true);
    const callback = await addUploadApi(file);

    setUploadLoading(false);
    if (callback) {
      const uploadedData = callback?.data ? callback?.data[0] : null;
      setUploadedFile({
        ...uploadedData,
        url: uploadedData?.url?.includes('https://')
          ? uploadedData?.url
          : `https://${uploadedData?.url}`,
      });
    }
    return false;
  };

  const ThisFormInput = () => (
    <>
      <Row>
        <Col md={12} span={24}>
          <Form.Item
            label="Invoice #"
            name="number"
            key="number"
            rules={[
              {
                required: true,
                message: 'Please input your Invoice #',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <Spin spinning={uploadLoading}>
            <Form.Item
              label="Invoice File"
              name="file"
              key="file"
              rules={[
                {
                  required: !uploadedFile,
                  message: 'Invoice File is required!',
                },
              ]}
            >
              {props.roleId !== ADMIN_ROLE_ID ? (
                uploadedFile?.url ? (
                  <a target="_blank" href={uploadedFile?.url} rel="noreferrer">
                    <PaperClipOutlined style={{ fontSize: '18px' }} />
                  </a>
                ) : (
                  'N/A'
                )
              ) : uploadedFile?.url ? (
                <Button
                  icon={
                    <DeleteOutlined onClick={() => setUploadedFile(null)} />
                  }
                  className="edit_profile_button height-40"
                >
                  <a
                    target="_blank"
                    href={uploadedFile?.url}
                    rel="noreferrer"
                    style={{
                      marginLeft: 10,
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {uploadedFile?.name}
                  </a>
                </Button>
              ) : (
                <Upload
                  listType="text"
                  beforeUpload={uploadFile}
                  maxCount={1}
                  onRemove={() => setUploadedFile(null)}
                  fileList={uploadedFile ? [uploadedFile] : []}
                  customRequest={() => {}}
                  accept=".jpeg,.jpg,.png,.tiff,.pdf,.zip,.doc,.docx"
                  showUploadList={false}
                  className="edit_profile_upload height-40"
                >
                  <Button
                    icon={<UploadOutlined />}
                    className="edit_profile_upload height-40"
                  >
                    Click to Upload
                  </Button>
                </Upload>
              )}
            </Form.Item>
          </Spin>
          <Form.Item
            label="User"
            name="userId"
            key="userId"
            rules={[
              {
                required: true,
                message: 'Please Select user!',
              },
            ]}
          >
            <Select
              allowClear={true}
              size={'large'}
              onChange={(id) => {
                setSelectedUsers(id);
                form.setFieldsValue({ orderId: null });
              }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {users.map((el) => (
                <Option value={el.id} key={el.id}>
                  {el.DistrictOrSchoolName ||
                    (el?.FirstName || '') + ' ' + (el?.LastName || '')}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Order"
            name="orderId"
            key="orderId"
          >
            <Select
              allowClear={true}
              size={'large'}
              showSearch
              filterOption={(input, option) =>
                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={!selectedUsers || props.roleId !== ADMIN_ROLE_ID}
            >
              {orderData.map((el) => (
                <Option value={el.id} key={el.id}>
                  {el.id}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={12} span={24}>
          <Form.Item label="Invoice Note" name="note" key="note">
            <TextArea
              autoSize={{ minRows: 12, maxRows: 13 }}
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  if (
    props.data?.userId &&
    props.roleId !== ADMIN_ROLE_ID &&
    user?.id !== props.data?.userId?.id
  ) {
    return <NotFoundPage />;
  }

  return (
    <>
      <DeleteModal
        show={modalShow}
        setShow={setModalShow}
        deleteFunc={() => {
          axios
            .delete(`/invoices/${props.data?.id}`)
            .then((res) => {
              notification.success({
                message: `Success`,
                description: 'Invoice Successfully deleted!',
                placement: 'bottomRight',
              });
              navigate(-1);
            })
            .catch((e) => {
              if (e.response && e.response.data.message[0].messages[0].message)
                throw e.response.data.message[0].messages[0].message;
              else
                throw 'An error has occurred please contact system administrator';
            });
        }}
      />
      <div className="product-info-page info-page">
        <div className="container">
          <Link to={'/invoices'}>
            <h2 className="title  go-back">
              <img src="/vectors/arrow-left.png" alt="back" />
              Go Back
            </h2>
          </Link>

          <h2 className="title">Invoice information</h2>

          <Spin
            spinning={props.loading && props.functionality !== 'create'}
            tip="Loading..."
          >
            <Form
              className="product-info"
              layout="vertical"
              form={form}
              name="basic"
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
                size: 'large',
              }}
              disabled={props.roleId !== ADMIN_ROLE_ID}
              onFinish={onFinish}
              autoComplete="off"
            >
              <ThisFormInput />

              <div className="btn-gp">
                {props.functionality === 'create' ? (
                  <Form.Item
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                  >
                    <Button className="btn" type="primary" htmlType="submit">
                      Confirm
                    </Button>
                  </Form.Item>
                ) : (
                  <>
                    {props.roleId === ADMIN_ROLE_ID ? (
                      <Form.Item
                        wrapperCol={{
                          offset: 0,
                          span: 24,
                        }}
                      >
                        <Button
                          className="btn"
                          type="primary"
                          htmlType="submit"
                        >
                          Update
                        </Button>

                        <Button
                          className="btn"
                          onClick={(e) => {
                            setModalShow(true);
                          }}
                        >
                          Delete
                        </Button>
                      </Form.Item>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </Form>
            <div style={{ height: '120px' }}></div>
          </Spin>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    data:
      state.data && state.data.data && state.data.data[0]
        ? state.data.data[0]
        : null,
    loading: state.data.isLoading,

    isCoop: state.me.data.IsCoOp,
    roleId: state.me.roleId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (q) => dispatch(setUrl(q)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
