import { notification } from "antd";
import axios from "axios";

export const addUploadApi = async (data) => {
  const formData = new FormData();
  formData.append("files", data);

  return axios
    .post("/upload", formData, {
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => res)
    .catch(() => {
      notification.error({ description: "Something went wrong!" });
      return null;
    });
};
