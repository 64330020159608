/* eslint-disable no-throw-literal */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';
import * as roles from './../../services/constants/roles';

function fetchById(id, array) {
  let result = array.filter((item) => item.id === id)[0];
  return result;
}

//#region updateStatus
export const updateStatus = createAsyncThunk(
  'order/updateStatus',
  async (args, thunkAPI) => {
    const orderData = thunkAPI.getState().order.data;
    const data = {
      ...orderData,
      SchoolUserId: +orderData.SchoolUserId?.id
        ? orderData.SchoolUserId?.id
        : null,
      OrderStatus: args.status,
      cancellationReason: args.cancellationReason,
    };
    const res = await axios.put(`/orders/${args.id}`, data).catch((e) => {
      if (e.response && e.response.data.message[0].messages[0].message)
        throw e.response.data.message[0].messages[0].message;
      else throw 'An error has occurred please contact system administrator';
    });
    return res;
  },
);

export const updateStatusPending = (state, action) => {
  state.isLoading = true;
};
export const updateStatusFuilfilled = (state, action) => {
  notification.success({
    message: `Success`,
    description: 'Order info updated',
    placement: 'bottomRight',
  });
  state.data = { ...action.payload.data };
  state.isLoading = false;
};
export const updateStatusRejected = (state, action) => {
  // console.error("updateStatusRejected", action.error);
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion
//#region updateIsFulfilled
export const updateIsFulfilled = createAsyncThunk(
  'order/IsFulfilled',
  async (args, thunkAPI) => {
    const res = await axios
      .put(`/orders-products/${args.id}`, { ...args })
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        else throw 'An error has occurred please contact system administrator';
      });
    return res;
  },
);
export const updateIsFulfilledPending = (state, action) => {
  state.isLoading = true;
};
export const updateIsFulfilledFuilfilled = (state, action) => {
  state.data = { ...action.payload.data };
  state.isLoading = false;
};
export const updateIsFulfilledRejected = (state, action) => {
  // console.error("updateStatusRejected", action.error);
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion
//#region  getDistricts
export const getDistricts = createAsyncThunk(
  'order/getDistricts',
  async (args, thunkAPI) => {
    const res = await axios.get(
      '/users?&role.id=3&_sort=DistrictOrSchoolName:ASC',
    );

    let isSetChosen = false;
    let chosenId;
    const role = thunkAPI.getState().me.roleId;

    if (roles.ADMIN_ROLE_ID !== role) {
      isSetChosen = true;
      if (role === roles.DISTRICT_ROLE_ID) chosenId = thunkAPI.getState().me.id;
      else chosenId = thunkAPI.getState().me.data.DistrictId;
    }

    return { data: res.data, isSetChosen, chosenId };
  },
);

export const getDistrictsPending = (state, action) => {};
export const getDistrictsFulfilled = (state, action) => {
  state.districts = action.payload.data;
  if (action.payload.isSetChosen)
    state.chosenDistrictID = action.payload.chosenId;
};
export const getDistrictsRejected = (state, action) => {};
//#endregion

//#region getSchools
export const getSchools = createAsyncThunk(
  'order/getSchools',
  async (args, thunkAPI) => {
    const id = thunkAPI.getState().order.chosenDistrictID;
    const res = await axios
      .get(
        `/users?&role.id=1&DistrictId.id=${id}&_sort=DistrictOrSchoolName:ASC`,
      )
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        else throw 'An error has occurred please contact system administrator';
      });

    let isSetChosen = false;
    let chosenId;
    const role = thunkAPI.getState().me.roleId;

    if (roles.SCHOOL_ROLE_ID === role) {
      isSetChosen = true;
      chosenId = thunkAPI.getState().me.id;
    }

    return { data: res.data, isSetChosen, chosenId };
  },
);

export const getSchoolsPending = (state, action) => {};
export const getSchoolsFulfilled = (state, action) => {
  state.schools = action.payload.data;
  if (action.payload.isSetChosen)
    state.chosenSchoolID = action.payload.chosenId;
};
export const getSchoolsRejected = (state, action) => {
  // console.error("getSchoolsRejected...", action.payload);
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion

//#region getOrderGuides
export const getOrderGuides = createAsyncThunk(
  'order/getOrderGuides',
  async (args, thunkAPI) => {
    const schoolID = thunkAPI.getState().order.chosenSchoolID;
    try {
      const res = await axios.get(
        `/order-guides?Active=true&SchoolUserIds.id=${schoolID}&_sort=Name:ASC`,
      );
      return res;
    } catch (e) {
      // console.error(e);
      notification.error({
        message: `Error`,
        description: `An error has occurred please contact system administrator`,
        placement: 'bottomRight',
      });
    }
  },
);

export const getOrderGuidesPending = (state, action) => {};
export const getOrderGuidesFulfilled = (state, action) => {
  state.orderGuides = action?.payload?.data;
};
export const getOrderGuidesRejected = (state, action) => {
  // console.error("getOrderGuidesRejected : ", action.payload.action);
};

//#endregion

//#region postOrders

export const postOrders = createAsyncThunk(
  'orders/postOrders',
  async (args, thunkAPI) => {
    let total = 0;
    let TotalCommodityPrice = 0;
    let TotalRegularPrice = 0;

    const IsCoOp = fetchById(
      thunkAPI.getState().order.chosenDistrictID,
      thunkAPI.getState().order.districts,
    ).IsCoOp;

    thunkAPI.getState().order.orders.forEach((order) => {
      if (IsCoOp) {
        total +=
          (order.CoOPReqularPrice + order.CoOPCommidityPrice) * order.quantity;
        TotalCommodityPrice += order.CoOPCommidityPrice * order.quantity;
        TotalRegularPrice += order.CoOPReqularPrice * order.quantity;
      } else {
        total += (order.ReqularPrice + order.CommidityPrice) * order.quantity;
        TotalCommodityPrice += order.CommidityPrice * order.quantity;
        TotalRegularPrice += order.ReqularPrice * order.quantity;
      }
    });

    try {
      var orderId = await axios
        .post('/orders', {
          DistrictUserId: thunkAPI.getState().order.chosenDistrictID,
          SchoolUserId: thunkAPI.getState().order.chosenSchoolID,
          orderGuide: thunkAPI.getState().order.chosenOrderGuidID,
          Total: total,
          CreatedByUserEmail: thunkAPI.getState().me.data.email,
          CreatedByUserId: thunkAPI.getState().me.id,
          OrderStatus: 'new',
          DeliveryDate: thunkAPI.getState().order.deliveryDate,
          shippingAddress: thunkAPI.getState().order.shippingAddress,
          shippingSiteName: thunkAPI.getState().order.shippingSiteName,
          TotalCommodityPrice,
          TotalRegularPrice,
          PO: args,
        })
        .then((res) => res.data.id);

      thunkAPI.getState().order.orders.forEach(async (order) => {
        const payload = {
          OrderId: orderId,
          ProductId: order.id,
          IsCoOp: IsCoOp,
          RegularPrice: IsCoOp ? order.CoOPReqularPrice : order.ReqularPrice,
          CommodityPrice: IsCoOp
            ? order.CoOPCommidityPrice
            : order.CommidityPrice,
          Quantity: order.quantity,
          cancellationReason: order.cancellationReason,
          RegularTotal: IsCoOp
            ? order.CoOPReqularPrice * order.quantity
            : order.ReqularPrice * order.quantity,
          CommodityTotal: IsCoOp
            ? order.CoOPCommidityPrice * order.quantity
            : order.CommidityPrice * order.quantity,
        };

        await axios.post('/orders-products', payload);
      });
    } catch (e) {
      if (e.response && e.response.data.message[0].messages[0].message)
        throw e.response.data.message[0].messages[0].message;
      else throw 'An error has occurred please contact system administrator';
    }

    return orderId;
  },
);

export const postOrdersPending = (state, action) => {
  state.isLoading = true;
};
export const postOrdersFulfilled = (state, action) => {
  state.orderId = action.payload;
  state.isLoading = false;
};
export const postOrdersRejected = (state, action) => {
  // console.error("postOrdersRejected");
  // console.error(action.payload);
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};

//#endregion

//#region editOrder

export const editOrder = createAsyncThunk(
  'orders/editOrder',
  async (args, thunkAPI) => {
    let total = 0;
    let TotalCommodityPrice = 0;
    let TotalRegularPrice = 0;

    const IsCoOp = fetchById(
      thunkAPI.getState().order.chosenDistrictID,
      thunkAPI.getState().order.districts,
    ).IsCoOp;

    thunkAPI.getState().order.orders.forEach((order) => {
      if (IsCoOp) {
        total +=
          (order.CoOPReqularPrice + order.CoOPCommidityPrice) * order.quantity;
        TotalCommodityPrice += order.CoOPCommidityPrice * order.quantity;
        TotalRegularPrice += order.CoOPReqularPrice * order.quantity;
      } else {
        total += (order.ReqularPrice + order.CommidityPrice) * order.quantity;
        TotalCommodityPrice += order.CommidityPrice * order.quantity;
        TotalRegularPrice += order.ReqularPrice * order.quantity;
      }
    });

    let orderId =
      thunkAPI.getState().order.existingOrder?.id ||
      thunkAPI.getState().order.data.id;

    try {
      await axios.put(`/orders/${orderId}`, {
        DistrictUserId: thunkAPI.getState().order.chosenDistrictID,
        SchoolUserId: thunkAPI.getState().order.chosenSchoolID,
        orderGuide: thunkAPI.getState().order.chosenOrderGuidID,
        Total: total,
        LastUpdatedByUserId: thunkAPI.getState().me.id,
        DeliveryDate: thunkAPI.getState().order.deliveryDate,
        TotalCommodityPrice,
        TotalRegularPrice,
        PO: args,
      });

      thunkAPI.getState().order.previousOrders.forEach(async (po) => {
        await axios.delete(`/orders-products/${po.orderProductId}`);
      });

      thunkAPI.getState().order.orders.forEach(async (order) => {
        const payload = {
          OrderId: orderId,
          ProductId: order.id,
          IsCoOp: IsCoOp,
          RegularPrice: IsCoOp ? order.CoOPReqularPrice : order.ReqularPrice,
          CommodityPrice: IsCoOp
            ? order.CoOPCommidityPrice
            : order.CommodityPrice,
          Quantity: order.quantity,
          cancellationReason: order.cancellationReason,
          RegularTotal: IsCoOp
            ? order.CoOPReqularPrice * order.quantity
            : order.ReqularPrice * order.quantity,
          CommodityTotal: IsCoOp
            ? order.CoOPCommidityPrice * order.quantity
            : order.CommidityPrice * order.quantity,
        };

        await axios.post('/orders-products', payload);
      });
    } catch (e) {
      if (e.response && e.response.data.message[0].messages[0].message)
        throw e.response.data.message[0].messages[0].message;
      else throw 'An error has occurred please contact system administrator';
    }
  },
);

export const editOrderPending = (state, action) => {
  state.isLoading = true;
};
export const editOrderFulfilled = (state, action) => {
  state.isLoading = false;
};
export const editOrderRejected = (state, action) => {
  // console.error("editOrderRejected");
  // console.error(action.payload);
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};

//#endregion
