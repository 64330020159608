import { combineReducers } from "redux";

import me from "./reducers/meSlice";
import data from "./reducers/dataSlice";
import order from "./reducers/orderSlice";
import orderGuid from "./reducers/orderGuidSlice";
import error from "./reducers/errorSlice";
import ui from "./reducers/uiSlice";
import settingSlice from "./reducers/settingSlice";

const reducers = combineReducers({
  me,
  data,
  order,
  orderGuid,
  error,
  ui,
  setting: settingSlice,
});

export default reducers;
