import { createSlice } from "@reduxjs/toolkit";

const initialState = { selectedMenu: null };

const uiSlice = createSlice({
	name: "ui",
	initialState,
	reducers: {
		setSelectedMenu: (state, action) => {
			state.selectedMenu = action.payload;
		},
	},
});

export const { setSelectedMenu } = uiSlice.actions;
export default uiSlice.reducer;
