import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NoAuthSider from '../../components/NoAuthSider';
import AuthError from '../../components/atoms/AuthError';
import { connect } from 'react-redux';
import { setError } from '../../store/reducers/errorSlice';

function SchoolRegistrationComplation(props) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [params] = useSearchParams();
  const [isFormReady, setIsFormReady] = useState(false);
  const [IsPasswordValid, setIsPasswordValid] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });

  const passwordValidation = (val) => {
    const regex = new RegExp(
      '^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&)(_=+-/*])(?=.{6,})',
    );

    if (regex.test(val)) {
      return { validateStatus: 'success', errorMsg: null };
    }

    return {
      validateStatus: 'error',
      errorMsg:
        'password should contain at least 1 uppercase, 1 number and 1 special character',
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let isReady =
        form.isFieldTouched('password') &&
        form.isFieldTouched('confirm') &&
        !form.getFieldsError().some((item) => item.errors.length > 0) &&
        !IsPasswordValid.errorMsg &&
        form.getFieldValue('password') === form.getFieldValue('confirm');

      setIsFormReady(isReady);
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [IsPasswordValid.errorMsg, form]);

  const [confirmValidation, setConfirmValidation] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });

  const confirm = (val) => {
    if (val === form.getFieldValue('password')) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    return {
      validateStatus: 'error',
      errorMsg: 'password and confirm are not the same!',
    };
  };

  const onFinish = (values) => {
    const id = params.get('code');

    const data = { ...values, blocked: false, confirmed: true, role: 1 };

    axios
      .put(`/users/${id}`, data)
      .then((res) => {
        props.setError({
          show: true,
          message:
            'You have completed your registration. You can login once your district approves your account. If you have questions, please contact us at info@kbfoodsdistribution.com',
          color: '#97BE11',
        });
        navigate('/');
      })
      .catch(({ response }) => {
        props.setError({
          show: true,
          message: response.data.data[0].messages[0].message,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div id="forgot-password" className="no-auth-page flex">
      <div className="sider">
        <NoAuthSider image="/images/retail-delivery-Adore-Me-Caviar-coronavirus-DoorDash.jpg" />
      </div>
      <div className="other">
        <AuthError />
        <div className="content flex">
          <div className="wrapper">
            <h1 className="title">Sign up</h1>
            <Form
              layout="vertical"
              form={form}
              name="basic"
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
                size: 'large',
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Billing Email"
                    name="billingEmail"
                    key="billingEmail"
                    rules={[
                      {
                        required: true,
                        message: 'Enter your billing email',
                      },
                      {
                        type: 'email',
                        message: 'Please input a valid email address!',
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter your billing email"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <>
                        <span style={{ color: 'red', marginRight: 5 }}>*</span>
                        Billing Address
                      </>
                    }
                    name="billingAddress"
                    key="billingAddress"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value.trim()) {
                            return Promise.reject(
                              new Error('Please input your billing address!'),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter your billing address"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <>
                        <span style={{ color: 'red', marginRight: 5 }}>*</span>
                        Shipping Address
                      </>
                    }
                    name="shippingAddress"
                    key="shippingAddress"
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value.trim()) {
                            return Promise.reject(
                              new Error('Please input your shipping address!'),
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Please input your shipping address!"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Password"
                    name="password"
                    key="password"
                    validateStatus={IsPasswordValid.validateStatus}
                    help={IsPasswordValid.errorMsg}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="Enter your password, 6+ characters"
                      onChange={(e) => {
                        setIsPasswordValid({
                          ...passwordValidation(e.target.value),
                          value: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Confirm"
                    name="confirm"
                    validateStatus={confirmValidation.validateStatus}
                    help={confirmValidation.errorMsg}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="Enter your confirmation password, 6+ characters"
                      onChange={(e) => {
                        setConfirmValidation({
                          ...confirm(e.target.value),
                          value: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!isFormReady}
                    >
                      Complete Registration
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setError: (obj) => dispatch(setError(obj)),
  };
}

export default connect(null, mapDispatchToProps)(SchoolRegistrationComplation);
