import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Spin, Tooltip } from 'antd';

import { setQuery, setSelect, setUrl } from '../../store/reducers/dataSlice';
import Table from '../../components/Table';
import { setSelectedMenu } from '../../store/reducers/uiSlice';

function VendorsPage(props) {
  const [count, setCount] = useState(null);

  useEffect(() => {
    props.setSelectedMenu('42');

    // props.setUrl("/vendors");
    props.setSelect(`&Deleted=0&`);
    props.setUrl('/vendors');

    axios
      .get(`/vendors/count?&Deleted=0`)
      .then((res) => setCount(res.data))
      .catch((e) => console.log(e));
  }, [props]);

  const columns = [
    {
      title: 'Vendor ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
      width: 150,
    },
    {
      title: 'Vendor Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
      width: 250,
    },

    {
      title: 'Website',
      dataIndex: 'WebSite',
      key: 'WebSite',
      render: (text) => (text ? text : 'N/A'),
      width: 250,
    },
    {
      title: 'Vendor Address',
      dataIndex: 'Address',
      key: 'Address',
      render: (text) => (text ? text : 'N/A'),
    },
    {
      title: 'Phone',
      dataIndex: 'Phone',
      key: 'Phone',
      render: (text) => (text ? text : 'N/A'),
      width: 250,
    },
    {
      title: '',
      dataIndex: 'Phone',
      key: 'link',
      render: (text, rec) => (
        <Tooltip title="View">
          <Link to={`/products/vendors/${rec.id}`}>
            <img src="/vectors/eye.png" alt="" />
          </Link>
        </Tooltip>
      ),
      width: 50,
    },
  ];

  return (
    <div className="vendors-page">
      <h2 className="title">Vendors</h2>
      <Spin spinning={count === null}>
        {count ? (
          <>
            <Link to={'/products/vendors/new'}>
              <div className="btn">Add Vendor</div>
            </Link>
            <Table
              columns={columns}
              title="Vendor"
              Link="Vendor"
              scroll={{ x: 1200 }}
            />
          </>
        ) : (
          <div className="empty-table">
            <div className="wrapper">
              <h3>— Your vendors is empty —</h3>
              <Link to={`/products/vendors/new`} style={{ color: 'white' }}>
                <div className="btn">Add vendors</div>
              </Link>
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (url) => dispatch(setUrl(url)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}
export default connect(null, mapDispatchToProps)(VendorsPage);
