/* eslint-disable no-throw-literal */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';

//#region create
export const createVendor = createAsyncThunk(
  'data/createVendor',
  async (arg) => {
    const data = arg;
    return await axios
      .post('/vendors', data)
      .then((res) => res)
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        else throw 'An error has occurred please contact system administrator';
      });
  },
);

export const createVendorPending = (state, action) => {
  state.isLoading = true;
};
export const createVendorFulfilled = (state, action) => {
  state.isLoading = false;
  notification.success({
    message: `Success`,
    description: 'Vendor created',
    placement: 'bottomRight',
  });
};
export const createVendorRejected = (state, action) => {
  // console.error(action);
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion

//#region update
export const updateVendor = createAsyncThunk(
  'data/updateVendor',
  async (arg) => {
    const data = { ...arg };
    return await axios
      .put(`/vendors/${arg.id}`, data)
      .then((res) => res)
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        else throw 'An error has occurred please contact system administrator';
      });
  },
);

export const updateVendorPending = (state, action) => {
  state.isLoading = true;
};

export const updateVendorFulfilled = (state, action) => {
  notification.success({
    message: `Success`,
    description: 'Vendor info updated',
    placement: 'bottomRight',
  });

  let newData = state.data.filter((vendor) => vendor.id === action.payload.id);
  newData.push(action.payload);
  state.data = newData;
  state.isLoading = false;
};

export const updateVendorRejected = (state, action) => {
  // console.error(action);
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion
