import React, { useEffect, useState } from 'react';
import { Steps, Button, Col, Row, Form, Select, Input } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';

import Table from './../../components/Table';
import { setSelect, setUrl, setQuery } from '../../store/reducers/dataSlice';
import * as roles from './../../services/constants/roles';
import {
  createOrderGuid,
  editOrderGuid,
  getDistricts,
  getSchools,
  getTypes,
} from '../../store/thunks/orderGuid';
import {
  addProduct,
  removeProduct,
  reset,
  setChosenDistrictID,
  setChosenSchoolID,
  setChosenTypeID,
  setDescription,
  setName,
} from '../../store/reducers/orderGuidSlice';
import { setSelectedMenu } from '../../store/reducers/uiSlice';

const { Step } = Steps;
const { Option } = Select;

function CreateOrderGuidPage(props) {
  //#region general
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const myDistrict =
    props.chosenDistrictID && props.districts.length
      ? fetchById(props.chosenDistrictID, props.districts)
      : { IsCoOp: false };

  useEffect(() => {
    props.setSelectedMenu('3');

    return function () {
      props.setQuery('&#');
      props.reset();
    };
  }, []);
  //#endregion

  //#region first step

  useEffect(() => {
    if (props.functionality === 'edit') {
      props.setChosenDistrictID(props.data.DistrictUserId.id);
      dispatch(getSchools());
      form.setFieldsValue({
        Name: props.data.Name,
        Description: props.data.Description,
        Type: props.data.OrderGuideTypeId.id,
        DistrictName: props.data.DistrictUserId.id,
        SchoolName: props.data.SchoolUserIds.map((item) => item.id),
      });
      props.setChosenSchoolID(props.data.SchoolUserIds.map((item) => item.id));
      props.setChosenTypeID(props.data.OrderGuideTypeId.id);
      props.setName(props.data.Name);
      props.setDescription(props.data.Description);
    }
  }, []);

  useEffect(() => {
    dispatch(getTypes());
    dispatch(getDistricts());
    if (props.roleId === roles.DISTRICT_ROLE_ID) {
      props.setChosenDistrictID(props.myData.id);
      form.setFieldsValue({ DistrictName: props.myData.id });
      dispatch(getSchools());
    }
  }, []);

  useEffect(() => {
    if (props.chosenDistrictID) dispatch(getSchools());
  }, [dispatch, props.chosenDistrictID]);

  //#endregion

  //#region second step
  function fetchById(id, array) {
    let result = array.filter((item) => {
      return item.id === id;
    })[0];
    return result;
  }

  useEffect(() => {
    if (props.functionality === 'edit') {
      props.data.OrderGuideProducts.map((ogp) => {
        props.addProduct(ogp);
        return ogp;
      });
      return function () {
        props.data.OrderGuideProducts.map((ogp) => {
          props.removeProduct(ogp);
          return ogp;
        });
      };
    }
  }, []);

  useEffect(() => {
    props.setUrl('/products');
    props.setSelect('&Deleted=0&');
    props.setQuery('&_sort=id:DESC&');
  }, []);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
      width: 50,
    },
    {
      title: 'Products Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
      // width: 50,
    },
    {
      title: 'Commercial Cost',
      dataIndex:
        myDistrict && myDistrict.IsCoOp ? 'CoOPReqularPrice' : 'ReqularPrice',
      key: 'ReqularPrice',
      render: (text) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
      sorter: {},
      width: 150,
    },
    {
      title: 'Commodity Price',
      dataIndex:
        myDistrict && myDistrict.IsCoOp
          ? 'CoOPCommidityPrice'
          : 'CommidityPrice',
      key: 'CommidityPrice',
      render: (text) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
      sorter: {},
      width: 150,
    },
    {
      title: 'Add to cart',
      dataIndex: 'Code',
      key: 'Code',
      render: (text, rec) => (
        <div style={{ textAlign: 'center' }}>
          {props.products && fetchById(rec.id, props.products) ? (
            <button className="btn" onClick={(e) => props.removeProduct(rec)}>
              Remove
            </button>
          ) : (
            <button
              className="btn"
              onClick={(e) => {
                props.addProduct(rec);
              }}
            >
              Add
            </button>
          )}
        </div>
      ),
      sorter: {},
      width: 150,
    },
  ];

  //#endregion

  return (
    <div className="create-orderguide-page flex">
      <div className="main">
        <div className="go-back">
          {current === 0 ? (
            <Link to={'/order-guides'}>
              <h2 className="title go-back">
                <img src="/vectors/arrow-left.png" alt="" />
                Go Back
              </h2>
            </Link>
          ) : (
            <h2
              className="title go-back"
              onClick={(e) => {
                setCurrent((s) => s - 1);
              }}
            >
              <img src="/vectors/arrow-left.png" alt="" />
              Go Back
            </h2>
          )}
        </div>

        <div className="steps">
          <Row>
            <Col span={8} offset={8}>
              <Steps current={current}>
                <Step />
                <Step />
                <Step />
              </Steps>
            </Col>
          </Row>
        </div>

        <div className="content">
          {current === 0 && (
            <div className="step1">
              <Form
                // className="district-info"
                layout="vertical"
                name="basic"
                onFinish={(values) => {
                  props.setName(values.Name);
                  props.setDescription(values.Description);
                  setCurrent(current + 1);
                }}
                onFinishFailed={(e) => {}}
                form={form}
                autoComplete="off"
              >
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Order Guide Type "
                      name="Type"
                      key="Type"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your type!',
                        },
                      ]}
                    >
                      <Select
                        allowClear={true}
                        size={'large'}
                        onChange={(id) => {
                          props.setChosenTypeID(id);
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {props.types ? (
                          props.types.map((option) => (
                            <Option value={option.id} key={option.id}>
                              {option.Name}
                            </Option>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="District Name"
                      name="DistrictName"
                      key="DistrictName"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your district!',
                        },
                      ]}
                    >
                      <Select
                        allowClear={true}
                        size={'large'}
                        onChange={(id) => {
                          props.setChosenDistrictID(id);
                        }}
                        showSearch
                        optionFilterProp="children"
                        disabled={props.roleId !== roles.ADMIN_ROLE_ID}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {props.districts ? (
                          props.districts.map((option) => (
                            <Option value={option.id} key={option.id}>
                              {option.DistrictOrSchoolName}
                            </Option>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="School Name"
                      name="SchoolName"
                      key="SchoolName"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your school!',
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        size={'large'}
                        allowClear
                        onChange={(val) => {
                          props.setChosenSchoolID(val);
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {props.schools ? (
                          props.schools.map((option) => (
                            <Option value={option.id} key={option.id}>
                              {option.DistrictOrSchoolName}
                            </Option>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name="Name"
                      key="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your name!',
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Name" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Description"
                      name="Description"
                      key="Description"
                    >
                      <Input.TextArea
                        placeholder="Description"
                        rows={4}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="btn-gp">
                  <Form.Item
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                  >
                    <Button
                      className="btn"
                      type="primary"
                      disabled={
                        !(props.chosenSchoolID && props.chosenDistrictID)
                      }
                      htmlType="submit"
                    >
                      Next
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}

          {current !== 0 && (
            <div className="step2">
              <Table columns={columns} />
            </div>
          )}

          {current === 2 && !props.isLoading ? (
            <div className="step4">
              <div className="bg flex">
                <div className="modal">
                  <div>
                    <img src="/vectors/package.png" alt="" />
                  </div>
                  <p>Thank you! </p>
                  <p>Your order guide has been updated!</p>
                  <Link to={'/order-guides'}>
                    <span className="btn">Go to Order Guides</span>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      {current !== 0 && (
        <div className="product-list">
          <Row className="title">Selected Products</Row>
          <Row className="products">
            {props.products.map((order) => (
              <div className="product card" key={order.id}>
                <Row>
                  <Col span={12} className="title">
                    Product code:
                  </Col>
                  <Col span={9} className="value">
                    {order.Code}
                  </Col>
                  <div
                    className="remove"
                    onClick={(e) => {
                      props.deleteOrder(order.id);
                    }}
                  >
                    x
                  </div>
                </Row>

                <Row>
                  <Col span={12} className="title">
                    Product name:
                  </Col>
                  <Col span={9} className="value">
                    {order.Name}
                  </Col>
                  <div
                    className="remove"
                    onClick={(e) => {
                      props.removeProduct(order);
                    }}
                  >
                    x
                  </div>
                </Row>

                <Row>
                  <Col span={12} className="title">
                    Commercial Cost:
                  </Col>
                  <Col span={12} className="value">
                    {myDistrict.IsCoOp
                      ? order.CoOPReqularPrice
                        ? '$' + order.CoOPReqularPrice?.toFixed(2)
                        : 'N/A'
                      : order.ReqularPrice
                      ? '$' + order.ReqularPrice?.toFixed(2)
                      : 'N/A'}
                  </Col>
                </Row>

                <Row>
                  <Col span={12} className="title">
                    Commodity Price:
                  </Col>
                  <Col span={12} className="value">
                    {myDistrict.IsCoOp
                      ? order.CoOPCommidityPrice
                        ? '$' + order.CoOPCommidityPrice?.toFixed(2)
                        : 'N/A'
                      : order.CommidityPrice
                      ? '$' + order.CommidityPrice.toFixed(2)
                      : 'N/A'}
                  </Col>
                </Row>
              </div>
            ))}
          </Row>
          <Row className="bottom">
            <button
              className="btn"
              disabled={!props.products.length || current === 2}
              onClick={() => {
                if (props.functionality === 'create')
                  dispatch(createOrderGuid());
                else {
                  dispatch(editOrderGuid(props.data.id));
                }
                setCurrent(2);
              }}
            >
              Save
            </button>
          </Row>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  let schools = [...state?.orderGuid?.schools];
  if (state.orderGuid.chosenDistrictID) {
    const selectedDistrict = state.orderGuid.districts?.filter(
      (item) => item.id === state.orderGuid.chosenDistrictID,
    );
    selectedDistrict?.length > 0 && schools.push(selectedDistrict[0]);
  }

  return {
    products: state.orderGuid.products,
    schools,
    districts: state.orderGuid.districts,
    types: state.orderGuid.types,
    chosenDistrictID: state.orderGuid.chosenDistrictID,
    chosenSchoolID: state.orderGuid.chosenSchoolID,
    chosenTypeID: state.orderGuid.chosenTypeID,
    isLoading: state.orderGuid.isLoading,
    data: state.orderGuid.data,

    roleId: state.me.roleId,
    myData: state.me.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setChosenTypeID: (id) => dispatch(setChosenTypeID(id)),
    setChosenSchoolID: (id) => dispatch(setChosenSchoolID(id)),
    setChosenDistrictID: (id) => dispatch(setChosenDistrictID(id)),
    setName: (name) => dispatch(setName(name)),
    setDescription: (des) => dispatch(setDescription(des)),
    addProduct: (product) => dispatch(addProduct(product)),
    removeProduct: (product) => dispatch(removeProduct(product)),
    reset: () => dispatch(reset),

    setUrl: (url) => dispatch(setUrl(url)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateOrderGuidPage);
