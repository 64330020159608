import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Spin, Tooltip } from 'antd';

import { setQuery, setSelect, setUrl } from '../../store/reducers/dataSlice';
import Table from '../../components/Table';
import { setSelectedMenu } from '../../store/reducers/uiSlice';

function OrderGuideTypesPage(props) {
  const [count, setCount] = useState(null);

  useEffect(() => {
    props.setSelectedMenu('43');
    props.setUrl('/order-guide-types');
    props.setSelect(`Deleted=0&`);
    props.setQuery('&_sort=id:DESC&');

    axios
      .get(`/order-guide-types/count?&Deleted=0&`)
      .then((res) => setCount(res.data))
      .catch((e) => console.log(e));
  }, [props]);

  const columns = [
    {
      title: 'Type Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
      width: 350,
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
    },
    {
      title: '',
      dataIndex: 'Name',
      key: 'Name',
      render: (text, rec) => (
        <Tooltip title="View">
          <Link to={`/products/order-guide-types/${rec.id}`}>
            <img src="/vectors/eye.png" alt="" />
          </Link>
        </Tooltip>
      ),
      width: 50,
    },
  ];

  return (
    <div className="types-page">
      <h2 className="title">Order Guide Types</h2>
      <Spin spinning={count === null}>
        {count ? (
          <>
            <Link to={'/products/order-guide-types/new'}>
              <div className="btn" style={{ marginBottom: '20px' }}>
                Add Order Guide Type
              </div>
            </Link>

            <Table columns={columns} scroll={{ x: 700 }} />
          </>
        ) : (
          <>
            <div className="empty-table">
              <div className="wrapper">
                <h3>— Your order guide types is empty —</h3>

                <Link
                  to={`/products/order-guide-types/new`}
                  style={{ color: 'white' }}
                >
                  <div className="btn">Add order guide types</div>
                </Link>
              </div>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (url) => dispatch(setUrl(url)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}
export default connect(null, mapDispatchToProps)(OrderGuideTypesPage);
