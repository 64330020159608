import React,{useState} from "react";
import {Col, Row } from "antd";
import { useEffect } from "react";


const Quantity = ({ text, max, disabled, change })=>{
   const [textValue,setTextValue] = useState('');

   useEffect(()=>{
    change(textValue);
   },[textValue]);

   useEffect(()=>{
    setTextValue(text)
   },[text]);

   return (<Row>
         <Col span={16} offset={4}>
           <Row style={{ textAlign: "center" }}>
             <Col
               span={6}
               className={!disabled && "clickable"}
               onClick={() => {
               !disabled && setTextValue(Number(textValue) !==0 ? Number(textValue) - 1 : 0);
               }}
             >
               {"<"}
             </Col>
             <Col span={12} className="quantity">
               <input
                 type="number"
                 disabled={disabled}
                 value={String(textValue).replace(/^0+(?!$)/g,'')}
                 onChange={(e) => {
                    setTextValue((e.target.value>=max && max)||(e.target.value<=0 && 0)|| Number(e.target.value))
                 }}
                 onWheel={(e) => e.target.blur()}
               />
             </Col>
             <Col
               span={6}
               className={!disabled&&"clickable"}
               onClick={() => {
                !disabled && setTextValue(Number(textValue)!==max ? Number(textValue) + 1 :max);
               }}
             >
               {">"}
             </Col>
           </Row>
         </Col>
       </Row>)
};

export default Quantity