import React from "react";

function NoAuthSider(props) {
  const style = {
    wrapper: {
      backgroundColor: "#97BE11",
      height: "100%",
      display: "flex",
      justifyContent: "space-around",
      backgroundSize: "100%",
    },
    image: {
      objectFit: "cover",
    },
  };

  return (
    <div className="no-auth-sider" style={style.wrapper}>
      <img src={`${props.image}`} alt="" style={style.image} />
    </div>
  );
}

export default NoAuthSider;
