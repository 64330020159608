import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import MaskedInput from 'antd-mask-input';

import { editData } from '../store/reducers/meSlice';
// import { updateUser } from "../store/thunks/user";
import {
  ADMIN_ROLE_ID,
  DISTRICT_ROLE_ID,
  SCHOOL_ROLE_ID,
} from './../services/constants/roles';
import axios from 'axios';
import { setSelectedMenu } from '../store/reducers/uiSlice';

function Profile(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { Option } = Select;

  useEffect(() => {
    let interval;
    if (form.isFieldTouched('confirmNewPassword')) {
      interval = setInterval(() => {
        setConfirmValidation({
          ...confirm(form.getFieldValue('confirmNewPassword')),
          value: form.getFieldValue('confirmNewPassword'),
        });
      }, 300);
    }

    return function () {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    props.setSelectedMenu('0');

    // const address = props.data && props.data.UserAddresses ? props.data.UserAddresses[0] : null;
    form.setFieldsValue({
      ...props.data,
      Password: '',
      UserDeliveryDays: props.data.UserDeliveryDays
        ? props.data.UserDeliveryDays.map((dd) => dd.DayId)
        : [],
      District:
        props.data.myDistrict && props.data.myDistrict.DistrictOrSchoolName
          ? props.data.myDistrict.DistrictOrSchoolName
          : '----',
      ccEmails: props?.data?.ccEmails || [],
    });
  }, [props.data]);

  const onFinish = (values) => {
    const days = [
      { DayId: 'saturday' },
      { DayId: 'sunday' },
      { DayId: 'monday' },
      { DayId: 'tuesday' },
      { DayId: 'wednesday' },
      { DayId: 'thursday' },
      { DayId: 'friday' },
    ];

    let UserDeliveryDays = days.filter(
      (day) =>
        values.UserDeliveryDays && values.UserDeliveryDays.includes(day.DayId),
    );

    let data = {
      ...values,
      UserDeliveryDays,
      id: props.id,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      ccEmails: values?.ccEmails || [],
    };

    if (
      IsPhoneValid.validateStatus === 'success' &&
      !form.getFieldsError().some((item) => item.errors.length > 0)
    )
      dispatch(editData(data));

    if (
      values.currentPassword &&
      values.newPassword &&
      values.confirmNewPassword &&
      IsPasswordValid.validateStatus === 'success' &&
      confirmValidation.validateStatus === 'success'
    ) {
      if (values.newPassword !== values.confirmNewPassword)
        notification.error({
          message: `Can't change password`,
          description: `new password and its confirm are not match!`,
          placement: 'bottomRight',
        });
      else
        axios
          .post('/custom/change-password', {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
            confirmNewPassword: values.confirmNewPassword,
          })
          .then((res) => {
            notification.success({
              message: `Success`,
              description: 'password changed',
              placement: 'bottomRight',
            });
          })
          .catch((e) => {
            notification.error({
              message: `Can't change password`,
              description: e.response.data.message,
              placement: 'bottomRight',
            });
          });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const AdminFormInput = () => (
    <>
      <Row>
        <Col md={12} span={24}>
          <Form.Item
            label="First Name"
            name="FirstName"
            key="FirstName"
            rules={[
              {
                required: true,
                message: 'Please input your first name!',
              },
            ]}
          >
            <Input size="large" placeholder="Enter your first name" />
          </Form.Item>
        </Col>

        <Col md={12} span={24}>
          <Form.Item
            label="Last Name*"
            name="LastName"
            key="LastName"
            rules={[
              {
                required: true,
                message: 'Please input your last name!',
              },
            ]}
          >
            <Input size="large" placeholder="Enter your last name" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const [IsPhoneValid, setIsPhoneValid] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });
  const phoneValidation = (val) => {
    const regex = /^(?=(?:.*?\d){10})/gm;
    if (regex.test(val)) {
      return { validateStatus: 'success', errorMsg: null };
    }

    return { validateStatus: 'error', errorMsg: 'Phone is not correct' };
  };

  const [IsPasswordValid, setIsPasswordValid] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });
  const passwordValidation = (val) => {
    const regex = new RegExp(
      '^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&)(_=+-/*])(?=.{6,})',
    );

    if (regex.test(val)) {
      return { validateStatus: 'success', errorMsg: null };
    }

    return {
      validateStatus: 'error',
      errorMsg:
        'password should contain at least 1 uppercase, 1 number and 1 special character',
    };
  };

  const [confirmValidation, setConfirmValidation] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });

  const confirm = (val) => {
    if (val === form.getFieldValue('newPassword')) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    return {
      validateStatus: 'error',
      errorMsg: 'password and confirm are not the same!',
    };
  };

  return (
    <>
      <div className="type-info-page info-page">
        <div className="container">
          <h2 className="title go-back" onClick={() => navigate(-1)}>
            <img src="/vectors/arrow-left.png" alt="" />
            Go Back
          </h2>

          <h2 className="title"> Profile</h2>

          <Spin spinning={props.loading} tip="Loading...">
            <Form
              className="school-info"
              autoComplete="off"
              layout="vertical"
              form={form}
              name="basic"
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
                size: 'large',
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              {props.role === ADMIN_ROLE_ID && <AdminFormInput />}
              {props.role === DISTRICT_ROLE_ID && (
                <>
                  <Row>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="First name"
                        name="FirstName"
                        key="FirstName"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your first name!',
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter your first name"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="Last name"
                        name="LastName"
                        key="LastName"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your last name!',
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter your last name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="Phone number"
                        name="Phone"
                        key="Phone"
                        validateStatus={IsPhoneValid.validateStatus}
                        help={IsPhoneValid.errorMsg}
                        rules={[
                          {
                            required: true,
                            message: 'Please input your phone!',
                          },
                        ]}
                      >
                        <MaskedInput
                          mask="(000) 000-0000"
                          size="large"
                          placeholder="Enter Your phone number"
                          onChange={(e) => {
                            setIsPhoneValid({
                              ...phoneValidation(e.target.value),
                              value: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="Billing Email"
                        name="billingEmail"
                        key="billingEmail"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your billing email!',
                          },
                          {
                            type: 'email',
                            message: 'Please input a valid email address!',
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter your billing email"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="Director name"
                        name="DirectorName"
                        key="DirectorName"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your director name!',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Director Name" />
                      </Form.Item>
                    </Col>
                    <Col md={12} span={24}>
                      <Form.Item
                        label={
                          <>
                            <span style={{ color: 'red', marginRight: 5 }}>
                              *
                            </span>
                            Billing Address
                          </>
                        }
                        name="billingAddress"
                        key="billingAddress"
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (!value.trim()) {
                                return Promise.reject(
                                  new Error(
                                    'Please input your billing address!',
                                  ),
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input size="large" placeholder="billing Address" />
                      </Form.Item>
                    </Col>
                    <Col md={12} span={24}>
                      <Form.Item
                        label={
                          <>
                            <span style={{ color: 'red', marginRight: 5 }}>
                              *
                            </span>
                            Shipping Address
                          </>
                        }
                        name="shippingAddress"
                        key="shippingAddress"
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (!value.trim()) {
                                return Promise.reject(
                                  new Error(
                                    'Please input your shipping address!',
                                  ),
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Your shipping address"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="Select Delivery Days"
                        name="UserDeliveryDays"
                        key="UserDeliveryDays"
                        rules={[
                          {
                            required: true,
                            message: 'Please select delivery days!',
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          allowClear
                          size={'large'}
                          placeholder="Please select"
                          // defaultValue={props.data.UserDeliveryDays}
                        >
                          <Option value={'sunday'}>Sunday</Option>
                          <Option value={'monday'}>Monday</Option>
                          <Option value={'tuesday'}>Tuesday</Option>
                          <Option value={'wednesday'}>Wednesday</Option>
                          <Option value={'thursday'}>Thursday</Option>
                          <Option value={'friday'}>Friday</Option>
                          <Option value={'saturday'}>Saturday</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="CC Emails"
                        name="ccEmails"
                        key="ccEmails"
                      >
                        <Select
                          mode="tags"
                          allowClear={true}
                          size={'large'}
                          placeholder="Please select"
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                     <Col md={12} span={24}>
                      <Form.Item label="Is Super Co op?" name="IsCoOp" key="IsCoOp">
                        <Switch
                          checkedChildren="yes"
                          unCheckedChildren="no"
                          // checked={props.data.IsCoOp}
                          disabled={props.roleId !== roles.ADMIN_ROLE_ID}
                          defaultChecked={props.data ? props.data.IsCoOp : false}
                        />
                      </Form.Item>
                    </Col> 
                  </Row>*/}
                </>
              )}
              {props.role === SCHOOL_ROLE_ID && (
                <>
                  <Row>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="First Name"
                        name="FirstName"
                        key="FirstName"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your first name!',
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter your first name"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="School Name"
                        name="DistrictOrSchoolName"
                        key="DistrictOrSchoolName"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your school name!',
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter your last name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="Last Name"
                        name="LastName"
                        key="LastName"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your last name!',
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter your last name"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="District"
                        name="District"
                        key="District"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your District!',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="District" disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="Billing Email"
                        name="billingEmail"
                        key="billingEmail"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your billing email!',
                          },
                          {
                            type: 'email',
                            message: 'Please input a valid email address!',
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter your email" />
                      </Form.Item>
                    </Col>
                    <Col md={12} span={24}>
                      <Form.Item
                        label={
                          <>
                            <span style={{ color: 'red', marginRight: 5 }}>
                              *
                            </span>
                            Billing Address
                          </>
                        }
                        name="billingAddress"
                        key="billingAddress"
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (!value.trim()) {
                                return Promise.reject(
                                  new Error(
                                    'Please input your billing address!',
                                  ),
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Your billing address"
                        />
                      </Form.Item>
                    </Col>

                    <Col md={12} span={24}>
                      <Form.Item
                        label={
                          <>
                            <span style={{ color: 'red', marginRight: 5 }}>
                              *
                            </span>
                            Shipping Address
                          </>
                        }
                        name="shippingAddress"
                        key="shippingAddress"
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (!value.trim()) {
                                return Promise.reject(
                                  new Error(
                                    'Please input your shipping address!',
                                  ),
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Your shipping address"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} span={24}>
                      <Form.Item
                        label="Phone"
                        name="Phone"
                        key="Phone"
                        validateStatus={IsPhoneValid.validateStatus}
                        help={IsPhoneValid.errorMsg}
                        rules={[
                          {
                            required: true,
                            message: 'Please input your phone!',
                          },
                        ]}
                      >
                        <MaskedInput
                          mask="(000) 000-0000"
                          size="large"
                          placeholder="Enter Your phone number"
                          onChange={(e) => {
                            setIsPhoneValid({
                              ...phoneValidation(e.target.value),
                              value: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12} span={24}></Col>
                  </Row>
                </>
              )}

              <h2 className="title"> Account</h2>

              <Row>
                <Col md={12} span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    key="email"
                    rules={[
                      {
                        type: 'email',
                        message: 'Please input a valid email address!',
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter your email"
                      disabled={!(props.role === ADMIN_ROLE_ID)}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} span={24}>
                  <Form.Item
                    label="Current Password"
                    name="currentPassword"
                    key="currentPassword"
                  >
                    <Input.Password
                      size="large"
                      placeholder="Enter your password"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md={12} span={24}>
                  <Form.Item
                    label="New Password"
                    name="newPassword"
                    key="newPassword"
                    validateStatus={IsPasswordValid.validateStatus}
                    help={IsPasswordValid.errorMsg}
                    rules={[
                      {
                        // required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="Enter your password"
                      onChange={(e) => {
                        setIsPasswordValid({
                          ...passwordValidation(e.target.value),
                          value: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={12} span={24}>
                  <Form.Item
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    key="confirmNewPassword"
                    validateStatus={confirmValidation.validateStatus}
                    help={confirmValidation.errorMsg}
                    rules={[
                      {
                        // required: form.isFieldTouched("newPassword"),
                        message: 'Please input your confirm!',
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="Enter your confirm"
                      onChange={(e) => {
                        setConfirmValidation({
                          ...confirm(e.target.value),
                          value: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="btn-gp">
                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  <Button className="btn" type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </div>
            </Form>
            <div style={{ height: '120px' }}></div>
          </Spin>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    data: state.me.data,
    loading: state.me.isLoading,
    id: state.me.id,
    role: state.me.roleId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // setUrl: (q) => dispatch(setUrl(q)),
    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
