/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { setQuery, setSelect, setUrl } from '../../store/reducers/dataSlice';
import Table from '../../components/Table';
import * as roles from './../../services/constants/roles';
import { Spin, Tooltip } from 'antd';
import { setSelectedMenu } from '../../store/reducers/uiSlice';

function UsersPage(props) {
  const [schoolCount, setSchoolCount] = useState(0);
  const [districtCount, setDistrictCount] = useState(0);
  const [userType, setUserType] = useState(
    props.forSchool
      ? 'school'
      : props.role === roles.ADMIN_ROLE_ID
      ? 'district'
      : 'school',
  );
  const [count, setCount] = useState(null);

  useEffect(() => {
    props.setSelectedMenu('5');

    axios
      .get('/users/count?&role.id=3')
      .then((res) => {
        setDistrictCount(res.data);
      })
      .catch((e) => {});

    axios
      .get('/users/count?&role.id=1')
      .then((res) => {
        setSchoolCount(res.data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    props.setUrl('/users');
    if (!props.role || !props.id) return;
    props.setSelect(
      `&role.id=${
        userType === 'district' ? roles.DISTRICT_ROLE_ID : roles.SCHOOL_ROLE_ID
      }&${
        props.role === roles.ADMIN_ROLE_ID ? '' : `DistrictId.id=${props.id}&`
      }`,
    );
    props.setQuery('&_sort=id:DESC');

    axios
      .get(
        `/users/count?&role.id=${
          userType === 'district'
            ? roles.DISTRICT_ROLE_ID
            : roles.SCHOOL_ROLE_ID
        }&${
          props.role === roles.ADMIN_ROLE_ID ? '' : `DistrictId.id=${props.id}&`
        }`,
      )
      .then((res) => {
        setCount(res.data);
      });
  }, [props, userType]);

  // function activateUser(rec) {
  //   dispatch(updateUser({ ...rec, UserStatus: "active" }));
  // }

  const columns = [
    {
      title: userType === 'district' ? 'District Name' : 'School Name',
      dataIndex: 'DistrictOrSchoolName',
      key: 'DistrictOrSchoolName',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
    },
    {
      title: 'First Name',
      dataIndex: 'FirstName',
      key: 'FirstName',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'LastName',
      key: 'LastName',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => (text ? text : 'N/A'),
    },
    {
      title: 'Phone',
      dataIndex: 'Phone',
      key: 'phone',
      render: (text) => (text ? text : 'N/A'),
    },
    // {
    // 	title: "Super Co op",
    // 	dataIndex: "IsCoOp",
    // 	key: "IsCoOp",
    // 	render: (elem, rec, index) =>
    // 		elem ? (
    // 			<div style={{ width: "17px" }}>
    // 				<img src="/vectors/green-check.png" alt="" />
    // 			</div>
    // 		) : (
    // 			<div style={{ color: "red" }}>X</div>
    // 		),
    // },
    {
      title: 'Status',
      dataIndex: 'UserStatus',
      key: 'UserStatus',
      width: 100,
      render: (text, rec) =>
        text === 'pendingReview' ? (
          <div
            className="status-inactive"
            style={{
              padding: '3px 15px',
              background: '#C1C1C1',
              color: 'white',
              // cursor: "pointer",
              borderRadius: '6px',
              width: '88px',
              textAlign: 'center',
            }}
            // onClick={(e) => {
            // 	props.role === roles.ADMIN_ROLE_ID && activateUser(rec);
            // }}
          >
            Pending Review
          </div>
        ) : text === 'active' ? (
          <div
            className="status-active"
            style={{
              padding: '3px 15px',
              background: '#EB7120',
              color: 'white',
              borderRadius: '6px',
              width: '77px',
              textAlign: 'center',
              // cursor: "pointer",
            }}
            // onClick={(e) => {
            // 	props.role === roles.ADMIN_ROLE_ID && dispatch(updateUser({ ...rec, UserStatus: "inActive" }));
            // }}
          >
            Active
          </div>
        ) : (
          <div
            className="status-inactive"
            style={{
              padding: '3px 15px',
              background: '#C1C1C1',
              color: 'white',
              // cursor: "pointer",
              borderRadius: '6px',
              width: '88px',
              textAlign: 'center',
            }}
            // onClick={(e) => {
            // 	props.role === roles.ADMIN_ROLE_ID && activateUser(rec);
            // }}
          >
            Inactive
          </div>
        ),
      sorter: {},
      searchable: true,
    },
    {
      title: '',
      dataIndex: 'UserStatus',
      key: 'link',
      render: (text, rec) => (
        <Tooltip title="View">
          <Link to={`/users/${rec.id}`}>
            <img src="/vectors/eye.png" alt="" />
          </Link>
        </Tooltip>
      ),
      width: 50,
    },
  ];

  return (
    <div id="users-page">
      <h2 className="title">Users</h2>

      {props.role === roles.ADMIN_ROLE_ID ? (
        <div className="not_this">
          <Spin spinning={count === null}>
            <div className="cards flex">
              <div
                className={`user-type-card flex districts ${
                  userType === 'district' ? 'active' : ''
                }`}
                onClick={(e) => {
                  setUserType('district');
                }}
              >
                <div className="text">
                  <div className="number">
                    {districtCount ? districtCount : 'loading...'}
                  </div>
                  <div className="type">Districts</div>
                </div>
                <div className="icon">
                  <img src="/vectors/district.svg" alt="" />
                </div>
              </div>

              <div
                className={`user-type-card flex schools ${
                  userType === 'district' ? '' : 'active'
                }`}
                onClick={(e) => {
                  setUserType('school');
                }}
              >
                <div className="text">
                  <div className="number">
                    {schoolCount ? schoolCount : 'N/A'}
                  </div>
                  <div className="type">Schools</div>
                </div>
                <div className="icon">
                  <img src="/vectors/district.svg" alt="" />
                </div>
              </div>
            </div>
          </Spin>
        </div>
      ) : (
        <></>
      )}

      <Spin spinning={count === null}>
        {count ? (
          <>
            {userType === 'school' ? (
              <Link to={'/users/new'}>
                <div className="btn"> Add School</div>
              </Link>
            ) : (
              ''
            )}

            <Table
              columns={columns}
              title="users"
              link="users"
              scroll={{ x: 1200 }}
            />
          </>
        ) : (
          <div className="empty-table">
            <div className="wrapper">
              <h3>— Your schools is empty —</h3>
              <Link to={`/users/new`} style={{ color: 'white' }}>
                <div className="btn">Add schools</div>
              </Link>
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (url) => dispatch(setUrl(url)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

function mapStateToProps(state) {
  return {
    role: state.me.roleId,
    id: state.me.id,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
