import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NoAuthSider from '../../components/NoAuthSider';
import AuthError from '../../components/atoms/AuthError';
import { connect } from 'react-redux';
import { setError } from '../../store/reducers/errorSlice';

function ResetPassword(props) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [params] = useSearchParams();

  const [confirmValidation, setConfirmValidation] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });
  const [IsPasswordValid, setIsPasswordValid] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });

  // const [error, setError] = useState({ show: false, message: "" });
  const setError = props.setError;
  const [isFormReady, setIsFormReady] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      let isReady =
        form.isFieldTouched('password') &&
        form.isFieldTouched('confirm') &&
        !form.getFieldsError().some((item) => item.errors.length > 0) &&
        !IsPasswordValid.errorMsg &&
        form.getFieldValue('password') === form.getFieldValue('confirm');

      setIsFormReady(isReady);
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [IsPasswordValid.errorMsg, form]);

  const confirm = (val) => {
    if (val === form.getFieldValue('password')) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    return {
      validateStatus: 'error',
      errorMsg: 'password and confirm are not the same!',
    };
  };

  const passwordValidation = (val) => {
    const regex = new RegExp(
      '^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&)(_=+-/*])(?=.{6,})',
    );

    if (regex.test(val)) {
      return { validateStatus: 'success', errorMsg: null };
    }

    return {
      validateStatus: 'error',
      errorMsg:
        'password should contain at least 1 uppercase, 1 number and 1 special character',
    };
  };

  const onFinish = (values) => {
    if (values.password === values.confirm)
      axios
        .post('/auth/reset-password', {
          code: params.get('code'),
          password: values.password,
          passwordConfirmation: values.confirm,
        })
        .then((res) => {
          navigate('/');
        });
    else
      setError({
        show: true,
        message: 'Password and Confirm password not matching',
      });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div id="forgot-password" className="no-auth-page flex">
      <div className="sider">
        <NoAuthSider image="/images/retail-delivery-Adore-Me-Caviar-coronavirus-DoorDash.jpg" />
      </div>
      <div className="other">
        <AuthError />
        <div className="content flex">
          <div className="wrapper">
            <h1 className="title">New password</h1>
            <p className="description">Please enter your new password below.</p>
            <Form
              layout="vertical"
              form={form}
              name="basic"
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
                size: 'large',
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Password"
                    name="password"
                    key="password"
                    validateStatus={IsPasswordValid.validateStatus}
                    help={IsPasswordValid.errorMsg}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="Enter your password, 6+ characters"
                      onChange={(e) => {
                        setIsPasswordValid({
                          ...passwordValidation(e.target.value),
                          value: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Confirm"
                    name="confirm"
                    validateStatus={confirmValidation.validateStatus}
                    help={confirmValidation.errorMsg}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="Enter your confirmation password, 6+ characters"
                      onChange={(e) => {
                        setConfirmValidation({
                          ...confirm(e.target.value),
                          value: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!isFormReady}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setError: (obj) => dispatch(setError(obj)),
  };
}

export default connect(null, mapDispatchToProps)(ResetPassword);
