import { Button, Col, Form, Input, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { setQuery, setSelect, setUrl } from '../../store/reducers/dataSlice';
import { useDispatch } from 'react-redux';
import { createType, updateType } from '../../store/thunks/type';
import DeleteModal from '../../components/DeleteModal';
import { setSelectedMenu } from '../../store/reducers/uiSlice';

function TypeInfoPage(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [modalShow, setModalShow] = useState(false);

  const navigateTo = useNavigate();

  useEffect(() => {
    props.setSelectedMenu('41');

    props.setUrl('/product-types');
    props.setSelect('');
    if (props.functionality === 'update') props.setQuery(`&id=${id}`);
    else props.setQuery('&');
  }, [id, props]);

  useEffect(() => {
    if (props.functionality === 'update')
      form.setFieldsValue({
        ...props.data,
      });
  }, [props.data]);

  const onFinish = (values) => {
    let data = {
      ...values,
      id,
    };
    if (props.functionality === 'create') {
      dispatch(createType(data)).then(() => {
        navigateTo('/products/types');
      });
    } else {
      dispatch(updateType(data)).then(() => {
        navigateTo('/products/types');
      });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const ThisFormInput = () => (
    <>
      <Row>
        <Col md={12} span={24}>
          <Form.Item
            label="Product Types Name"
            name="Name"
            key="Name"
            rules={[
              {
                required: true,
                message: 'Please input a name!',
              },
            ]}
          >
            <Input size="large" placeholder="Enter your type name" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col md={12} span={24}>
          <Form.Item label="Description" name="Description" key="Description">
            <Input size="large" placeholder="Description" />
          </Form.Item>

          {/* <Form.Item label="Description" name="Description" key="Description">
						<Input.TextArea
							placeholder="Enter your description"
							rows={4}
							size="large"
							style={{ width: "546px", maxWidth: "unset" }}
						/>
					</Form.Item> */}
        </Col>
      </Row>
    </>
  );

  return (
    <div className="type-info-page info-page">
      <DeleteModal
        show={modalShow}
        setShow={setModalShow}
        deleteFunc={() => {
          dispatch(
            updateType({
              ...props.data,
              Deleted: props.data.Deleted ? false : true,
            }),
          );
        }}
      />
      <div className="container">
        <Link to={'/products/types'}>
          <h2 className="title">
            <img src="/vectors/arrow-left.png" alt="" />
            Go Back
          </h2>
        </Link>

        <h2 className="title">Product Type information</h2>

        <Spin
          spinning={props.loading && props.functionality === 'update'}
          tip="Loading..."
        >
          <Form
            // onChange={(e) => {
            // 	setRefresh((prev) => !prev);
            // }}
            className="school-info"
            layout="vertical"
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            initialValues={{
              remember: true,
              size: 'large',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <ThisFormInput />

            <div className="btn-gp">
              {props.functionality === 'create' ? (
                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  <Button className="btn" type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              ) : (
                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  <Button
                    className="btn outline"
                    onClick={(e) => {
                      setModalShow(true);
                    }}
                  >
                    Delete
                  </Button>

                  <Button className="btn" type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              )}
            </div>
          </Form>
          <div style={{ height: '120px' }}></div>
        </Spin>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state.data.data[0],
    loading: state.data.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (q) => dispatch(setUrl(q)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TypeInfoPage);
