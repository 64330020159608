import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';

//#region create
export const createType = createAsyncThunk('data/createType', async (arg) => {
  const data = arg;
  return await axios
    .post('/product-types', data)
    .then((res) => res)
    .catch((e) => {
      if (e.response && e.response.data.message[0].messages[0].message)
        throw e.response.data.message[0].messages[0].message;
      // eslint-disable-next-line no-throw-literal
      else throw 'An error has occurred please contact system administrator';
    });
});

export const createTypePending = (state, action) => {
  state.isLoading = true;
};
export const createTypeFulfilled = (state, action) => {
  state.isLoading = false;
  notification.success({
    message: `Success`,
    description: 'Type created',
    placement: 'bottomRight',
  });
};
export const createTypeRejected = (state, action) => {
  // console.error(action);
  state.isLoading = false;
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion

//#region update
export const updateType = createAsyncThunk('data/updateType', async (arg) => {
  const data = arg;
  return await axios
    .put(`/product-types/${arg.id}`, data)
    .then((res) => res)
    .catch((e) => {
      if (e.response && e.response.data.message[0].messages[0].message)
        throw e.response.data.message[0].messages[0].message;
      // eslint-disable-next-line no-throw-literal
      else throw 'An error has occurred please contact system administrator';
    });
});

export const updateTypePending = (state, action) => {
  state.isLoading = true;
};
export const updateTypeFulfilled = (state, action) => {
  state.isLoading = false;
  notification.success({
    message: `Success`,
    description: 'Type has been updated',
    placement: 'bottomRight',
  });
};
export const updateTypeRejected = (state, action) => {
  // console.error(action);
  state.isLoading = false;
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion
