/* eslint-disable no-throw-literal */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';
import * as orderThunk from './../thunks/order';

export const getData = createAsyncThunk('order/getData', async (arg) => {
  let res = await axios.get(`/orders/${arg}`).catch((e) => {
    return { error: e, data: {} };
  });
  return { data: res.data };
});

function fetchById(id, array) {
  let result = array.filter((item) => item.id === id)[0];
  return result;
}

let initialState = {
  isLoading: true,
  data: {},
  // ---------------
  deliveryDate: null,
  districts: [],
  chosenDistrictID: null,
  schools: [],
  chosenSchoolID: null,
  orderGuides: [],
  chosenOrderGuidID: null,
  products: [],

  orderId: null,
  orders: [],
  previousOrders: [],
  shippingAddress: null,
  shippingSiteName: null,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setDeliveryDate(state, action) {
      state.deliveryDate = action.payload;
    },
    setChosenDistrictID(state, action) {
      state.chosenDistrictID = action.payload;
    },
    setChosenSchoolID(state, action) {
      state.chosenSchoolID = action.payload;
    },
    setChosenOrderGuid(state, action) {
      state.chosenOrderGuidID = action.payload;
    },
    setShippingSiteName(state, action) {
      state.shippingSiteName = action.payload;
    },
    setShippingAddress(state, action) {
      state.shippingAddress = action.payload;
    },
    addOrder(state, action) {
      let data = action.payload;
      let newOrders = state.orders;
      if (fetchById(data.id, state.orders)) {
        newOrders = state.orders.map((item) => {
          if (item.id === data.id)
            return { ...item, quantity: item.quantity + 1 };
          else return item;
        });
      } else {
        newOrders = [...state.orders, { ...data, quantity: 1 }];
      }
      state.orders = [...newOrders];
    },

    removeOrder(state, action) {
      if (fetchById(action.payload.id, state.orders)) {
        let order = fetchById(action.payload.id, state.orders);
        state.orders = state.orders
          .map((item) => {
            if (order.id === item.id)
              return { ...item, quantity: item.quantity - 1 };
            else return item;
          })
          .filter((item) => item.quantity > 0);
      }
    },

    deleteOrder(state, action) {
      state.orders = state.orders.filter((o) => o.id !== action.payload);
    },

    setQuantity(state, action) {
      let data = action.payload;
      const quantity = parseInt(data.quantity);
      let newOrders = state.orders;
      if (fetchById(data.id, state.orders)) {
        newOrders = state.orders.map((item) => {
          if (item.id === data.id) return { ...item, quantity: quantity };
          else return item;
        });
      } else {
        newOrders = [...state.orders, { ...data, quantity: quantity }];
      }

      state.orders = [...newOrders];
    },

    setOrders(state, action) {
      state.orders = [...action.payload];
      state.previousOrders = action.payload;
    },
    setExistingOrder(state, action) {
      state.existingOrder = action.payload;
    },

    reset: () => initialState,

    resetOrders: (state, action) => {
      state.orders = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getData.rejected, (state, action) => {
      notification.error({
        message: `Error`,
        description: action.error.message,
        placement: 'bottomRight',
      });
    });

    builder.addCase(
      orderThunk.getDistricts.pending,
      orderThunk.getDistrictsPending,
    );
    builder.addCase(
      orderThunk.getDistricts.fulfilled,
      orderThunk.getDistrictsFulfilled,
    );
    builder.addCase(
      orderThunk.getDistricts.rejected,
      orderThunk.getDistrictsRejected,
    );

    builder.addCase(
      orderThunk.getSchools.pending,
      orderThunk.getSchoolsPending,
    );
    builder.addCase(
      orderThunk.getSchools.fulfilled,
      orderThunk.getSchoolsFulfilled,
    );
    builder.addCase(
      orderThunk.getSchools.rejected,
      orderThunk.getSchoolsRejected,
    );

    builder.addCase(
      orderThunk.getOrderGuides.pending,
      orderThunk.getOrderGuidesPending,
    );
    builder.addCase(
      orderThunk.getOrderGuides.fulfilled,
      orderThunk.getOrderGuidesFulfilled,
    );
    builder.addCase(
      orderThunk.getOrderGuides.rejected,
      orderThunk.getOrderGuidesRejected,
    );

    builder.addCase(
      orderThunk.postOrders.pending,
      orderThunk.postOrdersPending,
    );
    builder.addCase(
      orderThunk.postOrders.fulfilled,
      orderThunk.postOrdersFulfilled,
    );
    builder.addCase(
      orderThunk.postOrders.rejected,
      orderThunk.postOrdersRejected,
    );

    builder.addCase(
      orderThunk.updateStatus.pending,
      orderThunk.updateStatusPending,
    );
    builder.addCase(
      orderThunk.updateStatus.fulfilled,
      orderThunk.updateStatusFuilfilled,
    );
    builder.addCase(
      orderThunk.updateStatus.rejected,
      orderThunk.updateStatusRejected,
    );

    builder.addCase(orderThunk.editOrder.pending, orderThunk.editOrderPending);
    builder.addCase(
      orderThunk.editOrder.fulfilled,
      orderThunk.editOrderFulfilled,
    );
    builder.addCase(
      orderThunk.editOrder.rejected,
      orderThunk.editOrderRejected,
    );
  },
});

export const {
  setDeliveryDate,
  setChosenDistrictID,
  deleteOrder,
  setChosenSchoolID,
  setQuantity,
  setOrders,
  setChosenOrderGuid,
  addOrder,
  removeOrder,
  reset,
  resetOrders,
  shippingAddress,
  shippingSiteName,
  setShippingAddress,
  setShippingSiteName,
  setExistingOrder,
} = orderSlice.actions;
export default orderSlice.reducer;
