import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import axios from 'axios';
import NoAuthSider from '../../components/NoAuthSider';
import AuthError from '../../components/atoms/AuthError';
import { connect } from 'react-redux';
import { setError } from '../../store/reducers/errorSlice';

function SignIn(props) {
  const [form] = Form.useForm();
  // const [error, setError] = useState({ show: false, message: "" });
  const setError = props.setError;
  const [isFormReady, setIsFormReady] = useState(false);

  useEffect(() => {
    setError({
      show: true,
      message:
        'Your email has already been confirmed.You can now login. Thank you!',
      color: '#97be11',
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // form.validateFields();
      setIsFormReady(
        form.isFieldTouched('email') &&
          form.isFieldTouched('password') &&
          !form.getFieldsError().some((item) => item.errors.length > 0),
        // form.error
      );
    }, 200);

    return () => {
      clearInterval(interval);
    };
  }, [form]);

  const onFinish = (values) => {
    axios
      .post('/auth/local', {
        identifier: values.email,
        password: values.password,
      })
      .then((res) => {
        if (!res.data.user.confirmed) {
          setError({
            show: true,
            message: 'Please check your email and confirm your account ',
          });
        } else if (res.data.user.UserStatus !== 'active') {
          setError({
            show: true,
            message:
              'Your account is pending or inactive. Please contact the Admin for assistance.',
          });
        } else {
          localStorage.setItem('jwt', res.data.jwt);
          // window.location.href = "/";
          window.location.reload();
        }
      })
      .catch((e) => {
        setError({
          show: true,
          message: e.response.data.data[0].messages[0].message,
        });
      });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div id="sign-in" className="no-auth-page flex">
      <div className="sider">
        <NoAuthSider image="/images/retail-delivery-Adore-Me-Caviar-coronavirus-DoorDash.jpg" />
      </div>
      <div className="other">
        <AuthError />
        <div className="header">
          <span className="text">Don't have an Account ? </span>
          <span className="link">
            <Link to="/register">Register</Link>
          </span>
        </div>
        <div className="content flex">
          <div className="wrapper">
            <h1 className="title">Sign in </h1>
            <p className="description"></p>
            <Form
              layout="vertical"
              name="basic"
              form={form}
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
                size: 'large',
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    key="email"
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: 'Please input your email!',
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter your email" />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Password"
                    name="password"
                    key="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="Enter your password"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="flex forget">
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  wrapperCol={{
                    offset: 0,
                    span: 16,
                  }}
                >
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <div>
                  <Link to="/forgot-password">Forgot Password ?</Link>
                </div>
              </div>

              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isFormReady}
                >
                  Sign in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setError: (obj) => dispatch(setError(obj)),
  };
}

export default connect(null, mapDispatchToProps)(SignIn);
