import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Modal, Row, Col, Select } from 'antd';
import axios from 'axios';
import MaskedInput from 'antd-mask-input';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import NoAuthSider from '../../components/NoAuthSider';
import AuthError from '../../components/atoms/AuthError';
import { setError } from './../../store/reducers/errorSlice';
const { Option } = Select;

function SignUp(props) {
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [confirmValidation, setConfirmValidation] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });
  const [IsPasswordValid, setIsPasswordValid] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });

  const navigateTo = useNavigate();

  const [form] = Form.useForm();

  const [isFirstFormReady, setIsFirstFormReady] = useState(false);
  const [isSecondFormReady, setIsSecondFormReady] = useState(false);

  //Intervals
  useEffect(() => {
    let firstInterval = setInterval(() => {
      let isReady =
        form.getFieldValue('FirstName') &&
        form.getFieldValue('LastName') &&
        form.getFieldValue('email') &&
        form.getFieldValue('password') &&
        form.getFieldValue('confirm') &&
        !form.getFieldsError().some((item) => item.errors.length > 0) &&
        !IsPasswordValid.errorMsg &&
        form.getFieldValue('password') === form.getFieldValue('confirm');

      setIsFirstFormReady(isReady);
    }, 500);

    let secondInterval = setInterval(() => {
      let isReady =
        form.getFieldValue('billingAddress') &&
        form.getFieldValue('billingEmail') &&
        form.getFieldValue('shippingAddress') &&
        form.getFieldValue('distract') &&
        form.getFieldValue('phone') &&
        form.getFieldValue('director') &&
        form.getFieldValue('conditions') &&
        !form.getFieldsError().some((item) => item.errors.length > 0);
      setIsSecondFormReady(isReady);
    }, 500);

    return () => {
      clearInterval(firstInterval);
      clearInterval(secondInterval);
    };
  }, [IsPasswordValid.errorMsg, form, isFirstPage]);

  const onFinish = (values) => {
    if (isFirstPage) {
      if (form.getFieldValue('password') === form.getFieldValue('confirm')) {
        setIsFirstPage(false);
      } else
        props.setError({
          show: true,
          message: 'Password and Confirm password not matching',
        });
    } else {
      const days = [
        { DayId: 'saturday' },
        { DayId: 'sunday' },
        { DayId: 'monday' },
        { DayId: 'tuesday' },
        { DayId: 'wednesday' },
        { DayId: 'thursday' },
        { DayId: 'friday' },
      ];
      let UserDeliveryDays = days.filter(
        (day) =>
          values.UserDeliveryDays &&
          values.UserDeliveryDays.includes(day.DayId),
      );
      let req = {
        // UserStatus: "pendingReview",
        username: form.getFieldValue('email'),
        confirmed: false,
        UserDeliveryDays,
        blocked: false,
        email: form.getFieldValue('email'),
        password: form.getFieldValue('password'),
        FirstName: form.getFieldValue('FirstName'),
        LastName: form.getFieldValue('LastName'),
        billingAddress: form.getFieldValue('billingAddress'),
        billingEmail: form.getFieldValue('billingEmail'),
        shippingAddress: form.getFieldValue('shippingAddress'),
        DirectorName: form.getFieldValue('director'),
        Phone: values.phone,
        DistrictOrSchoolName: values.distract,
        IsCoOp: values.coop ? values.coop : false,
      };
      document.getElementById('the-btn').disabled = true;
      axios
        .post('/auth/local/register', req)
        .then((res) => {
          navigateTo('/');
          props.setError({
            show: true,
            message:
              // eslint-disable-next-line max-len
              'We have emailed you a confirmation link, please verify your email by clicking on the verification link. Once your email is verified, we will review and approve your account, so you can log in. If you have questions, please contact us at info@kbfoodsdistribution.com',
            color: '#97BE11',
          });
        })
        .catch(({ response }) => {
          props.setError({
            show: true,
            message: response.data.data[0].messages[0].message,
          });
        });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const confirm = (val) => {
    if (val === form.getFieldValue('password')) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    return {
      validateStatus: 'error',
      errorMsg: 'password and confirm are not the same!',
    };
  };

  const [IsPhoneValid, setIsPhoneValid] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });
  const phoneValidation = (val) => {
    // const regex = /\b\d{10}\b/g;
    const regex = /^(?=(?:.*?\d){10})/gm;
    if (regex.test(val)) {
      return { validateStatus: 'success', errorMsg: null };
    }

    return { validateStatus: 'error', errorMsg: 'Phone is not correct' };
  };

  const passwordValidation = (val) => {
    const regex = new RegExp(
      '^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&)(_=+-/*])(?=.{6,})',
    );

    if (regex.test(val)) {
      return { validateStatus: 'success', errorMsg: null };
    }

    return {
      validateStatus: 'error',
      errorMsg:
        'password should contain at least 1 uppercase, 1 number and 1 special character',
    };
  };

  const firstPage = (
    <div className="content flex">
      <div className="wrapper">
        <h1 className="title">Sign up </h1>

        <Form
          form={form}
          layout="vertical"
          name="basic"
          labelCol={{
            span: 8,
          }}
          initialValues={{
            remember: true,
            size: 'large',
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="First Name"
                name="FirstName"
                key="FirstName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your first name!',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter your first name" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label="Last Name"
                name="LastName"
                key="LastName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your last name!',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Your last name" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label="Email"
                name="email"
                key="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                  {
                    type: 'email',
                    message: 'Please input a valid email address!',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter your email" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label="Password"
                name="password"
                key="password"
                validateStatus={IsPasswordValid.validateStatus}
                help={IsPasswordValid.errorMsg}
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter your password, 6+ characters"
                  onChange={(e) => {
                    setIsPasswordValid({
                      ...passwordValidation(e.target.value),
                      value: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label="Confirm"
                name="confirm"
                key="confirm"
                validateStatus={confirmValidation.validateStatus}
                help={confirmValidation.errorMsg}
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Enter your confirmation password, 6+ characters"
                  onChange={(e) => {
                    setConfirmValidation({
                      ...confirm(e.target.value),
                      value: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                wrapperCol={{
                  offset: 20,
                  span: 4,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isFirstFormReady}
                >
                  <img src="/vectors/right.png" alt="" />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );

  const secondPage = (
    <div className="content flex">
      <h2
        className="title  go-back"
        style={{
          position: 'absolute',
          top: props.hasError ? '90px' : '0px',
          left: '20px',
        }}
        onClick={() => {
          setIsFirstPage(true);
        }}
      >
        <img
          src="/vectors/arrow-left.png"
          alt="back"
          style={{ width: '24px' }}
        />{' '}
        Go Back
      </h2>
      <div className="wrapper">
        <h1 className="title">Sign up</h1>

        <Form
          form={form}
          layout="vertical"
          name="basic"
          labelCol={{
            span: 8,
          }}
          initialValues={{
            remember: true,
            size: 'large',
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="ِDistrict"
                name="distract"
                key="distract"
                rules={[
                  {
                    required: true,
                    message: 'Please input your district name!',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter your district name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Phone Number"
                name="phone"
                key="phone"
                validateStatus={IsPhoneValid.validateStatus}
                help={IsPhoneValid.errorMsg}
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                  },
                ]}
              >
                <MaskedInput
                  mask="(000) 000-0000"
                  size="large"
                  placeholder="Enter Your phone number"
                  onChange={(e) => {
                    setIsPhoneValid({
                      ...phoneValidation(e.target.value),
                      value: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Director Name"
                name="director"
                key="director"
                rules={[
                  {
                    required: true,
                    message: 'Please input your director name!',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter your director name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Billing Email"
                name="billingEmail"
                key="billingEmail"
                rules={[
                  {
                    required: true,
                    message: 'Enter your billing email',
                  },
                  {
                    type: 'email',
                    message: 'Please input a valid email address!',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter your billing email" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <>
                    <span style={{ color: 'red', marginRight: 5 }}>*</span>
                    Billing Address
                  </>
                }
                name="billingAddress"
                key="billingAddress"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value.trim()) {
                        return Promise.reject(
                          new Error('Please input your billing address!'),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input size="large" placeholder="Enter your billing address" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <>
                    <span style={{ color: 'red', marginRight: 5 }}>*</span>
                    Shipping Address
                  </>
                }
                name="shippingAddress"
                key="shippingAddress"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value.trim()) {
                        return Promise.reject(
                          new Error('Please input your shipping address!'),
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  size="large"
                  placeholder="Please input your shipping address!"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Select Delivery Days"
                name="UserDeliveryDays"
                key="UserDeliveryDays"
                rules={[
                  {
                    required: true,
                    message: 'Please select delivery days!',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  size={'large'}
                  placeholder="Please select"
                >
                  <Option value={'sunday'}>Sunday</Option>
                  <Option value={'monday'}>Monday</Option>
                  <Option value={'tuesday'}>Tuesday</Option>
                  <Option value={'wednesday'}>Wednesday</Option>
                  <Option value={'thursday'}>Thursday</Option>
                  <Option value={'friday'}>Friday</Option>
                  <Option value={'saturday'}>Saturday</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col span={24}>
              <Form.Item
                name="coop"
                key="coop"
                valuePropName="checked"
                wrapperCol={{
                  offset: 0,
                  span: 16,
                }}
              >
                <Checkbox>Super Co op</Checkbox>
              </Form.Item>
            </Col>
          </Row> */}

          <Row>
            <Col span={24}>
              <Form.Item
                name="conditions"
                key="conditions"
                valuePropName="checked"
                wrapperCol={{
                  offset: 0,
                  span: 16,
                }}
                rules={[
                  {
                    required: true,
                    message: 'Please read and accept terms and conditions!',
                  },
                ]}
              >
                <Checkbox>
                  <span style={{ fontSize: 'larger', color: 'red' }}>* </span>I
                  have read the{' '}
                  <a
                    target="_blank"
                    href="/terms-and-conditions"
                    style={{ textDecoration: 'underline', color: 'black' }}
                  >
                    Terms and Conditions
                  </a>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 24,
                }}
              >
                <Button
                  // type="primary"
                  className="btn"
                  htmlType="submit"
                  disabled={!isSecondFormReady}
                  id="the-btn"
                >
                  Sign up
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );

  return (
    <div id="sign-up" className="no-auth-page flex">
      <div className="sider">
        <NoAuthSider image="/images/retail-delivery-Adore-Me-Caviar-coronavirus-DoorDash.jpg" />
      </div>

      <div className="other">
        <AuthError />

        <div className="header">
          <span className="text">Already have an account ? </span>
          <span className="link">
            <Link to="/">Sign in</Link>
          </span>
        </div>
        {isFirstPage ? firstPage : secondPage}
      </div>

      <Modal
        title="Terms and Conditions"
        visible={showModal}
        onOk={() => {
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Return
          </Button>,
        ]}
      >
        <></>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return { hasError: state.error.show };
}

function mapDispatchToProps(dispatch) {
  return {
    setError: (obj) => dispatch(setError(obj)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
