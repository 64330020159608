import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import * as userThunk from './../thunks/user';
import * as vendorThunk from './../thunks/vendor';
import * as typeThunk from './../thunks/type';
import * as productThunk from './../thunks/products';
import * as OGT from './../thunks/orderGuideType';

export const getData = createAsyncThunk(
  'data/getData',
  async (args, { getState }) => {
    const state = getState().data;
    let res;
    let count;
    const query = state.query?.split('&')?.filter(Boolean)?.join('&');
    if (state.baseUrl) {
      res = await axios
        .get(
          `${state.baseUrl}/?&${query.includes('_limit') ? '' : '_limit=15'}&${
            state.select
          }&${query}`,
        )
        .then((res) => res)
        .catch((err) => console.log('/dataSlice/getData/res :', err));
      count = await axios
        .get(`${state.baseUrl}/count/?${state.select}${query}`)
        .then((res) => res)
        .catch((err) => err);
    }
    return { data: res.data, status: res.status, count: count.data };
  },
);

const initialState = {
  baseUrl: '',
  isLoading: true,
  data: [],
  query: '',
  count: 0,
  status: 0,
  select: '',
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setUrl(state, action) {
      state.baseUrl = action.payload;
    },
    setSelect(state, action) {
      state.select = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getData.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = action.payload.status;
        state.data = action.payload.data;
        state.count = action.payload.count;
        state.isLoading = false;
      }
    });
    builder.addCase(getData.rejected, (state, action) => {});

    //#region User cases
    builder.addCase(userThunk.createUser.pending, userThunk.createUserPending);
    builder.addCase(
      userThunk.createUser.fulfilled,
      userThunk.createUserFulfilled,
    );
    builder.addCase(
      userThunk.createUser.rejected,
      userThunk.createUserRejected,
    );

    builder.addCase(userThunk.updateUser.pending, userThunk.updateUserPending);
    builder.addCase(
      userThunk.updateUser.fulfilled,
      userThunk.updateUserFulfilled,
    );
    builder.addCase(
      userThunk.updateUser.rejected,
      userThunk.updateUserRejected,
    );
    // #endregion

    //#region Vendor cases
    builder.addCase(
      vendorThunk.createVendor.pending,
      vendorThunk.createVendorPending,
    );
    builder.addCase(
      vendorThunk.createVendor.fulfilled,
      vendorThunk.createVendorFulfilled,
    );
    builder.addCase(
      vendorThunk.createVendor.rejected,
      vendorThunk.createVendorRejected,
    );

    builder.addCase(
      vendorThunk.updateVendor.pending,
      vendorThunk.updateVendorPending,
    );
    builder.addCase(
      vendorThunk.updateVendor.fulfilled,
      vendorThunk.updateVendorFulfilled,
    );
    builder.addCase(
      vendorThunk.updateVendor.rejected,
      vendorThunk.updateVendorRejected,
    );
    //#endregion

    //#region  Type cases
    builder.addCase(typeThunk.createType.pending, typeThunk.createTypePending);
    builder.addCase(
      typeThunk.createType.fulfilled,
      typeThunk.createTypeFulfilled,
    );
    builder.addCase(
      typeThunk.createType.rejected,
      typeThunk.createTypeRejected,
    );

    builder.addCase(typeThunk.updateType.pending, typeThunk.updateTypePending);
    builder.addCase(
      typeThunk.updateType.fulfilled,
      typeThunk.updateTypeFulfilled,
    );
    builder.addCase(
      typeThunk.updateType.rejected,
      typeThunk.updateTypeRejected,
    );
    //#endregion

    //#region  Order guideType cases
    builder.addCase(
      OGT.createOrderGuideType.pending,
      OGT.createOrderGuideTypePending,
    );
    builder.addCase(
      OGT.createOrderGuideType.fulfilled,
      OGT.createOrderGuideTypeFulfilled,
    );
    builder.addCase(
      OGT.createOrderGuideType.rejected,
      OGT.createOrderGuideTypeRejected,
    );

    builder.addCase(
      OGT.updateOrderGuideType.pending,
      OGT.updateOrderGuideTypePending,
    );
    builder.addCase(
      OGT.updateOrderGuideType.fulfilled,
      OGT.updateOrderGuideTypeFulfilled,
    );
    builder.addCase(
      OGT.updateOrderGuideType.rejected,
      OGT.updateOrderGuideTypeRejected,
    );
    //#endregion

    //#region  Product cases
    builder.addCase(
      productThunk.createProduct.pending,
      productThunk.createProductPending,
    );
    builder.addCase(
      productThunk.createProduct.fulfilled,
      productThunk.createProductFulfilled,
    );
    builder.addCase(
      productThunk.createProduct.rejected,
      productThunk.createProductRejected,
    );

    builder.addCase(
      productThunk.updateProduct.pending,
      productThunk.updateProductPending,
    );
    builder.addCase(
      productThunk.updateProduct.fulfilled,
      productThunk.updateProductFulfilled,
    );
    builder.addCase(
      productThunk.updateProduct.rejected,
      productThunk.updateProductRejected,
    );

    builder.addCase(
      productThunk.deleteProduct.pending,
      productThunk.deleteProductPending,
    );
    builder.addCase(
      productThunk.deleteProduct.fulfilled,
      productThunk.deleteProductFulfilled,
    );
    builder.addCase(
      productThunk.deleteProduct.rejected,
      productThunk.deleteProductRejected,
    );
    //#endregion
  },
});

export const { changeUrl, setUrl, setQuery, setSelect, reset } =
  dataSlice.actions;
export default dataSlice.reducer;
