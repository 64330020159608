import { configureStore } from "@reduxjs/toolkit";

import reducer from "./combiner";

export default configureStore({
	reducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
