/* eslint-disable no-throw-literal */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';

import * as orderGuidThunk from './../thunks/orderGuid';

export const getData = createAsyncThunk('orderGuid/getData', async (arg) => {
  let res = await axios.get(`/order-guides/${arg}`).catch((e) => {
    return { error: e, data: {} };
  });
  return { data: res.data };
});

let initialState = {
  isLoading: true,
  data: {},

  types: [],
  chosenTypeID: null,
  districts: [],
  chosenDistrictID: null,
  schools: [],
  chosenSchoolID: [],
  products: [],

  name: '',
  description: '',
};

const orderGuidSlice = createSlice({
  name: 'orderGuid',
  initialState,
  reducers: {
    setChosenTypeID: (state, action) => {
      state.chosenTypeID = action.payload;
    },
    setChosenDistrictID: (state, action) => {
      state.chosenDistrictID = action.payload;
    },
    setChosenSchoolID: (state, action) => {
      state.chosenSchoolID = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    addProduct: (state, action) => {
      state.products.push(action.payload);
    },
    removeProduct: (state, action) => {
      state.products = state.products.filter((p) => p.id !== action.payload.id);
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getData.rejected, (state, action) => {
      notification.error({
        message: `Error`,
        description: action.error.message,
        placement: 'bottomRight',
      });
    });

    builder.addCase(
      orderGuidThunk.createOrderGuid.pending,
      orderGuidThunk.createOrderGuidPending,
    );
    builder.addCase(
      orderGuidThunk.createOrderGuid.fulfilled,
      orderGuidThunk.createOrderGuidFulfilled,
    );
    builder.addCase(
      orderGuidThunk.createOrderGuid.rejected,
      orderGuidThunk.createOrderGuidRejected,
    );

    builder.addCase(
      orderGuidThunk.updateOrderGuid.pending,
      orderGuidThunk.updateOrderGuidPending,
    );
    builder.addCase(
      orderGuidThunk.updateOrderGuid.fulfilled,
      orderGuidThunk.updateOrderGuidFulfilled,
    );
    builder.addCase(
      orderGuidThunk.updateOrderGuid.rejected,
      orderGuidThunk.updateOrderGuidRejected,
    );

    builder.addCase(
      orderGuidThunk.editOrderGuid.pending,
      orderGuidThunk.editOrderGuidPending,
    );
    builder.addCase(
      orderGuidThunk.editOrderGuid.fulfilled,
      orderGuidThunk.editOrderGuidFulfilled,
    );
    builder.addCase(
      orderGuidThunk.editOrderGuid.rejected,
      orderGuidThunk.editOrderGuidRejected,
    );

    builder.addCase(
      orderGuidThunk.getTypes.pending,
      orderGuidThunk.getTypesPending,
    );
    builder.addCase(
      orderGuidThunk.getTypes.fulfilled,
      orderGuidThunk.getTypesFulfilled,
    );
    builder.addCase(
      orderGuidThunk.getTypes.rejected,
      orderGuidThunk.getTypesRejected,
    );

    builder.addCase(
      orderGuidThunk.getDistricts.pending,
      orderGuidThunk.getDistrictsPending,
    );
    builder.addCase(
      orderGuidThunk.getDistricts.fulfilled,
      orderGuidThunk.getDistrictsFulfilled,
    );
    builder.addCase(
      orderGuidThunk.getDistricts.rejected,
      orderGuidThunk.getDistrictsRejected,
    );

    builder.addCase(
      orderGuidThunk.getSchools.pending,
      orderGuidThunk.getSchoolsPending,
    );
    builder.addCase(
      orderGuidThunk.getSchools.fulfilled,
      orderGuidThunk.getSchoolsFulfilled,
    );
    builder.addCase(
      orderGuidThunk.getSchools.rejected,
      orderGuidThunk.getSchoolsRejected,
    );
  },
});

export default orderGuidSlice.reducer;
export const {
  setChosenDistrictID,
  setChosenTypeID,
  addProduct,
  removeProduct,
  setDescription,
  setName,
  setChosenSchoolID,
  reset,
} = orderGuidSlice.actions;
