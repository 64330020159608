import React from "react";
import { connect } from "react-redux";

import { setError } from "./../../store/reducers/errorSlice";

function AuthError(props) {
  const { message, show, color } = props;

  const style = {
    background: color ? color : "#E01D11",
    padding: "30px",
    position: "absolute",
    width: "100%",
    height: "auto",
    maxHeight: "130px",
    top: "0",
    left: "0",
    zIndex: "10",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontSize: "20px",
    color: "white",
  };

  return (
    <>
      {show && (
        <div className="auth-error flex" style={style}>
          <p style={{ margin: "0" }}>{message}</p>
          <div
            onClick={() => {
              props.setError({ show: false, message: "" });
            }}
          >
            <img src="/vectors/x.png" style={{ width: "16px" }} alt="" />
          </div>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    message: state.error.message,
    show: state.error.show,
    color: state.error.color,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setError: (obj) => dispatch(setError(obj)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthError);
