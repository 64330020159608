import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";

export const settingSlice = createSlice({
  name: "setting",
  initialState: {
    value: {},
    settings: [],
  },
  reducers: {
    getSetting: (state, action) => {
      state.value = _.find(state.settings, (item) => item.Name === action.payload);
    },
    setSettings: (state, { payload }) => {
      state.settings = payload;
    },
  },
});
export function fetchSettings() {
  return async (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_ROOT}settings`)
      .then((response) => {
        dispatch(setSettings(response.data));
      })
      .catch((er) => {});
  };
}
export function saveSettings(payload) {
  return async (dispatch) => {
    if (payload.oldValue?.id)
      axios
        .put(`${process.env.REACT_APP_API_ROOT}settings/${payload.oldValue?.id}`, payload.newValue)
        .then((response) => {
          dispatch(fetchSettings());
        })
        .catch((er) => {});
    else
      axios
        .post(`${process.env.REACT_APP_API_ROOT}settings`, payload.newValue)
        .then((response) => {
          dispatch(fetchSettings());
        })
        .catch((er) => {});
  };
}
// Action creators are generated for each case reducer function
export const { getSetting, setSettings } = settingSlice.actions;

export default settingSlice.reducer;
