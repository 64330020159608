import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';
import { DISTRICT_ROLE_ID } from '../../services/constants/roles';

//#region create
export const createOrderGuid = createAsyncThunk(
  'orderGuid/createOrderGuid',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState().orderGuid;
    const data = {
      Name: state.name,
      Description: state.description,
      OrderGuideProducts: state.products.map((p) => p.id),
      Active: true,
      OrderGuideTypeId: state.chosenTypeID,
      SchoolUserIds: state.chosenSchoolID,
      DistrictUserId: state.chosenDistrictID,
    };

    return await axios
      .post('/order-guides', data)
      .then((res) => res)
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        // eslint-disable-next-line no-throw-literal
        else throw 'An error has occurred please contact system administrator';
      });
  },
);

export const createOrderGuidPending = (state, action) => {
  state.isLoading = true;
};
export const createOrderGuidFulfilled = (state, action) => {
  state.isLoading = false;
  state.products = [];
  state.chosenDistrictID = null;
  state.chosenSchoolID = [];
  state.chosenTypeID = null;
  state.name = '';
  state.description = '';
};
export const createOrderGuidRejected = (state, action) => {
  // console.error(action);
  // state.isLoading = false;
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion

//#region update
export const updateOrderGuid = createAsyncThunk(
  'orderGuid/updateOrderGuid',
  async (arg) => {
    const data = arg;
    return await axios
      .put(`/order-guides/${arg.id}`, data)
      .then((res) => res)
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        // eslint-disable-next-line no-throw-literal
        else throw 'An error has occurred please contact system administrator';
      });
  },
);

export const updateOrderGuidPending = (state, action) => {
  state.isLoading = true;
};
export const updateOrderGuidFulfilled = (state, action) => {
  state.isLoading = false;
  notification.success({
    message: `Success`,
    description: 'Order guid has been updated',
    placement: 'bottomRight',
  });
};
export const updateOrderGuidRejected = (state, action) => {
  // console.error(action.payload);
  state.isLoading = false;
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion

//#region edit
export const editOrderGuid = createAsyncThunk(
  'orderGuid/editOrderGuid',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState().orderGuid;
    const data = {
      Name: state.name,
      Description: state.description,
      OrderGuideProducts: state.products.map((p) => p.id),
      Active: true,
      OrderGuideTypeId: state.chosenTypeID,
      SchoolUserIds: state.chosenSchoolID,
      DistrictUserId: state.chosenDistrictID,
    };
    return await axios
      .put(`/order-guides/${arg}`, data)
      .then((res) => res)
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        // eslint-disable-next-line no-throw-literal
        else throw 'An error has occurred please contact system administrator';
      });
  },
);

export const editOrderGuidPending = (state, action) => {
  state.isLoading = true;
};
export const editOrderGuidFulfilled = (state, action) => {
  state.isLoading = false;
  state.products = [];
  state.chosenDistrictID = null;
  state.chosenSchoolID = [];
  state.chosenTypeID = null;
  state.name = '';
  state.description = '';
};
export const editOrderGuidRejected = (state, action) => {
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion

//#region getTypes
export const getTypes = createAsyncThunk('orderGuid/getTypes', async (arg) => {
  return await axios
    .get('/order-guide-types?&Deleted=0')
    .then((res) => res.data);
});

export const getTypesPending = (state, action) => {};
export const getTypesFulfilled = (state, action) => {
  state.types = action.payload;
};
export const getTypesRejected = (state, action) => {};
//#endregion

//#region getDistricts
export const getDistricts = createAsyncThunk(
  'orderGuid/getDistricts',
  async (arg) => {
    return await axios
      .get(`/users?&role.id=${DISTRICT_ROLE_ID}&blocked=false&confirmed=true`)
      .then((res) => res.data);
  },
);

export const getDistrictsPending = (state, action) => {};
export const getDistrictsFulfilled = (state, action) => {
  state.districts = action.payload;
};
export const getDistrictsRejected = (state, action) => {};
//#endregion

//#region getSchools
export const getSchools = createAsyncThunk(
  'orderGuid/getSchools',
  async (args, thunkAPI) => {
    const id = thunkAPI.getState().orderGuid.chosenDistrictID;
    const res = await axios.get(
      `/users?&role.id=1&DistrictId.id=${id}&_sort=DistrictOrSchoolName:ASC`,
    );

    return { data: res.data };
  },
);

export const getSchoolsPending = (state, action) => {};
export const getSchoolsFulfilled = (state, action) => {
  state.schools = action.payload.data;
};
export const getSchoolsRejected = (state, action) => {
  notification.error({
    message: `Error`,
    description: `An error has occurred please contact system administrator`,
    placement: 'bottomRight',
  });
};
//#endregion
