import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Spin,
  Switch,
  notification,
} from 'antd';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Table from './../../components/Table';
import { getData } from './../../store/reducers/orderSlice';
import { setQuery, setSelect, setUrl } from '../../store/reducers/dataSlice';
import { updateIsFulfilled, updateStatus } from './../../store/thunks/order';
import moment from 'moment';
import {
  ADMIN_ROLE_ID,
  DISTRICT_ROLE_ID,
} from '../../services/constants/roles';
import { setSelectedMenu } from '../../store/reducers/uiSlice';
import TextArea from 'antd/lib/input/TextArea';
import Quantity from '../../components/Quantity';
import { useCallback } from 'react';
import _ from 'lodash';
import DeleteModal from '../../components/DeleteModal';

function OrderInfoPage(props) {
  const { Option } = Select;
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isFormReady, setIsFormReady] = useState(false);
  const [permit, setPermit] = useState(true);
  const [visibleCancellationReason, setVisibleCancellationReason] =
    useState(false);
  const [fulfilledCommodity, setFulfilledCommodity] = useState(0);
  const [fulfilledRegular, setFulfilledRegular] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [canCancel, setCanCancel] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setPermit(props.data?.created_at ? true : false);
    props.setSelectedMenu('2');
    dispatch(getData(id));
    calculateFullFilledSum(id);
    if (props.roleId === ADMIN_ROLE_ID) {
      setCanCancel(props.data?.OrderStatus !== 'cancelled');
      setCanEdit(true);
    } else {
      setCanEdit(
        props.data.OrderStatus === 'new' &&
          props.data?.passedInMinutes < 24 * 60,
      );
      setCanCancel(
        props.data?.OrderStatus !== 'cancelled' &&
          props.data?.passedInMinutes < 24 * 60,
      );
    }
  }, [props.roleId, props.data.created_at]);

  useEffect(() => {
    setDataSource(props.tableData);
  }, [props.tableData]);

  const calculateFullFilledSum = (id) => {
    axios
      .get(`/custom/GetQ?command=select * from "getFullFilledSum"(${id})`)
      .then((res) => {
        const { results } = res.data;
        setFulfilledRegular(results?.[0]?.FulfilledRegular);
        setFulfilledCommodity(results?.[0]?.FulfilledCommodity);
      });
  };

  useLayoutEffect(() => {
    props.setUrl('/orders-products');
    props.setSelect(`&OrderId.id=${id}&`);
    props.setQuery('&_sort=ProductId.Code&_limit=9000');
  }, [id, dispatch, props]);

  const OnFulfilledChange = (value, rec) => {
    new Promise((resolve) => {
      dispatch(
        updateIsFulfilled({
          id: rec.id,
          IsFulfilled: value,
          FulfilledQuantity: value === false ? 0 : rec?.FulfilledQuantity,
        }),
      );

      resolve();
    }).then(() => {
      setDataSource(
        dataSource.map((obj) =>
          obj.id === rec.id
            ? {
                ...obj,
                IsFulfilled: value,
                FulfilledQuantity: value === false ? 0 : rec?.FulfilledQuantity,
              }
            : obj,
        ),
      );
      setTimeout(() => calculateFullFilledSum(id), 1000);
    });
  };

  const debouncedChangeHandler = useCallback(
    _.debounce((req) => {
      new Promise((resolve) => {
        dispatch(
          updateIsFulfilled({ id: req?.id, FulfilledQuantity: req?.value }),
        );
        resolve();
      }).then(() => {
        setTimeout(() => calculateFullFilledSum(id), 1000);
      });
    }, 500),
    [],
  );

  const columns = [
    {
      title: 'Code',
      dataIndex: 'ProductId',
      key: 'Code',
      render: (text, rec) =>
        text && text.Code && text.id ? (
          <a
            target="_blank"
            href={`/products/product/${text.id}`}
            style={{ color: 'black' }}
            rel="noreferrer"
          >
            {text.Code}
          </a>
        ) : (
          'N/A'
        ),
      sorter: {},
      searchable: true,
      location: 'ProductId.Code',
      width: 100,
    },
    {
      title: 'Product Name',
      dataIndex: 'ProductId',
      key: 'ProductId',
      // width: 200,
      ellipsis: {
        tooltip: true,
      },
      render: (text) => (text && text.Name ? text.Name : 'N/A'),
      sorter: {},
      searchable: true,
      location: 'ProductId.Name',
    },
    {
      title: 'Date Submitted',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 120,
      render: (text) =>
        text ? (
          <div style={{ textAlign: 'center' }}>
            {moment(text).format('MM-DD-YYYY')}
          </div>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
      render: (text) =>
        text ? <div style={{ textAlign: 'center' }}>{text}</div> : 'N/A',
      width: 80,
    },
    {
      title: 'Time Lead',
      dataIndex: 'ProductId',
      key: 'TimeLead',
      render: (text) =>
        text &&
        (text.TimeLead ? (
          <div style={{ textAlign: 'center' }}>{text.TimeLead} days</div>
        ) : (
          'N/A'
        )),
      width: 100,
    },
    {
      title: 'Commercial Cost',
      dataIndex: 'ProductId',
      key: 'RegularPrice',
      render: (text) =>
        text && text.ReqularPrice ? (
          <div style={{ textAlign: 'right' }}>{`$ ${
            text.ReqularPrice && text.ReqularPrice.toFixed(2)
          }`}</div>
        ) : (
          'N/A'
        ),
      sorter: {},
      location: 'ProductId.ReqularPrice',
      width: 160,
    },
    {
      title: 'Commodity Price',
      dataIndex: 'ProductId',
      key: 'CommodityPrice',
      render: (text) =>
        text && text.CommidityPrice ? (
          <div style={{ textAlign: 'right' }}>{`$${
            text.CommidityPrice && text.CommidityPrice.toFixed(2)
          }`}</div>
        ) : (
          'N/A'
        ),
      sorter: {},
      width: 160,
      location: 'ProductId.CommidityPrice',
    },
    {
      title: 'Fulfilled',
      dataIndex: 'IsFulfilled',
      key: 'IsFulfilled',
      width: 100,
      render: (value, rec) => (
        <Switch
          onChange={(value) => OnFulfilledChange(value, rec)}
          checkedChildren="yes"
          unCheckedChildren="no"
          defaultChecked={value}
          disabled={visibleCancellationReason || props.roleId !== ADMIN_ROLE_ID}
        />
      ),
      location: 'ProductId.IsFulfilled',
    },
    {
      title: 'Fulfilled Quantity',
      dataIndex: 'FulfilledQuantity',
      key: 'FulfilledQuantity',
      render: (text, product) =>
        props.roleId === ADMIN_ROLE_ID ? (
          <Quantity
            text={text}
            max={product?.Quantity}
            disabled={!product?.IsFulfilled}
            change={(value) =>
              debouncedChangeHandler({ id: product?.id, value })
            }
          />
        ) : (
          text || 0
        ),
      width: 140,
    },
    {
      title: 'Commercial Total',
      dataIndex: 'ProductId',
      key: 'ProductId',
      render: (text, rec) => (
        <>
          {text?.ReqularPrice ? (
            <div style={{ textAlign: 'right' }}>{`$${(
              text.ReqularPrice * rec.Quantity
            ).toFixed(2)}`}</div>
          ) : (
            'N/A'
          )}
        </>
      ),
      sorter: {},
      width: 160,
    },
    {
      title: 'Commodity Total',
      dataIndex: 'Commodity',
      key: 'Commodity',
      render: (text, rec) => (
        <div style={{ textAlign: 'right' }}>
          {rec?.ProductId?.CommidityPrice
            ? `$${Number.parseFloat(
                Number(rec?.ProductId.CommidityPrice || 0) * rec.Quantity,
              ).toFixed(2)}`
            : 'N/A'}
        </div>
      ),
      sorter: {},
      width: 160,
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
      status: props.data.OrderStatus,
      cancellationReason: props.data.cancellationReason,
    });
    setVisibleCancellationReason(form.getFieldValue('status') === 'cancelled');
  }, [props.data]);

  const onFinish = (values) => {
    let data = {
      ...values,
      DeliveryDate: moment(values.DeliveryDate, 'MM-DD-YYYY').toDate,
      cancellationReason: values.cancellationReason,
      id,
    };
    dispatch(updateStatus(data));
  };
  const onCancel = (values) => {
    const payload = {
      id: props.data.id,
      status: 'cancelled',
      cancellationReason: `Cancelled By ${
        props.roleId === ADMIN_ROLE_ID
          ? 'Admin'
          : props.roleId === DISTRICT_ROLE_ID
          ? 'District'
          : 'School'
      }`,
    };
    if (props.data?.passedInMinutes < 24 * 60) {
      dispatch(updateStatus(payload));
    } else {
      notification.error({
        message: 'Changes to orders are only allowed within 24 hours',
        placement: 'bottomRight',
      });
    }
  };
  const onFinishFailed = () => {};

  const EveryInfo = ({ title, text }) => (
    <div className="every-info">
      <div className="title">{title}</div>
      <div className="text">{text}</div>
    </div>
  );

  return (
    <>
      <DeleteModal
        show={modalShow}
        setShow={setModalShow}
        deleteFunc={onCancel}
        mainMessage="Are you sure you want to cancel this order?"
        message=" "
      />
      <div className="order-info-page info-page">
        {permit && (
          <div className="container">
            <h2
              className="title  go-back remove-onPrint"
              onClick={() => navigate(-1)}
            >
              <img src="/vectors/arrow-left.png" alt="" />
              Go Back
            </h2>
            <h2 className="title"> Placed Order</h2>
            <Spin
              spinning={props.loading && props.tableLoading}
              tip="Loading..."
            >
              <Row>
                <Col md={13} span={24}>
                  <Row>
                    <Col span={8}>
                      <EveryInfo title="Order Number" text={props.data.id} />
                      <EveryInfo
                        title="District Name"
                        text={
                          props.data.DistrictUserId &&
                          props.data.DistrictUserId.DistrictOrSchoolName
                        }
                      />
                      <EveryInfo
                        title="Shipping Site Name"
                        text={
                          props.data.shippingSiteName
                            ? props.data.shippingSiteName
                            : 'N/A'
                        }
                      />
                      <EveryInfo
                        title="Total Commercial Cost"
                        text={`$${
                          props.data.TotalRegularPrice &&
                          props.data.TotalRegularPrice.toFixed(2)
                        }`}
                      />

                      <EveryInfo
                        title="Total Fulfilled Commercial"
                        text={
                          fulfilledRegular
                            ? `$${fulfilledRegular.toFixed(2)}`
                            : 'N/A'
                        }
                      />
                    </Col>

                    <Col span={8}>
                      {props.data.SchoolUserId && (
                        <EveryInfo
                          title="School Name"
                          text={
                            props.data.SchoolUserId?.DistrictOrSchoolName
                              ? props.data.SchoolUserId.DistrictOrSchoolName
                              : 'N/A'
                          }
                        />
                      )}
                      <EveryInfo
                        title="Shipping Address"
                        text={
                          props?.myData?.shippingAddress ||
                          props?.shippingAddress ||
                          'N/A'
                        }
                      />

                      <EveryInfo
                        title="Total Commodity Price"
                        text={`$${
                          props.data.TotalCommodityPrice &&
                          props.data.TotalCommodityPrice.toFixed(2)
                        }`}
                      />
                      <EveryInfo
                        title="Total Fulfilled Commodity"
                        text={
                          fulfilledCommodity
                            ? `$${fulfilledCommodity.toFixed(2)}`
                            : 'N/A'
                        }
                      />
                      <EveryInfo
                        title="Delivery Date"
                        text={moment(
                          props.data.DeliveryDate,
                          'YYYY-MM-DD',
                        ).format('MM-DD-YYYY')}
                      />
                    </Col>

                    <Col span={8}>
                      {props.data.OrderStatus === 'fullFilled' && (
                        <EveryInfo
                          title="Updated Date"
                          text={`${moment(
                            new Date(props.data.updated_at),
                          ).format(
                            'MM-DD-YYYY',
                          )}  ${props.data.updated_at.slice(11, 16)}`}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md={11} span={24}>
                  <Col style={{ textAlign: 'end' }} span={24}>
                    <Button
                      className="btn remove-onPrint"
                      type="primary"
                      onClick={() => window.print()}
                    >
                      Print
                    </Button>
                  </Col>
                  {props.roleId !== ADMIN_ROLE_ID && canCancel && (
                    <Form
                      className="order-info"
                      layout="vertical"
                      form={form}
                      name="basic"
                      labelCol={{
                        span: 8,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      initialValues={{
                        remember: true,
                        size: 'large',
                        status: props.data.OrderStatus,
                      }}
                      onFinish={() => setModalShow(true)}
                      autoComplete="off"
                    >
                      <Form.Item
                        wrapperCol={{
                          offset: 0,
                          span: 24,
                        }}
                        style={{ textAlign: 'right' }}
                      >
                        <Button
                          className="btn"
                          type="primary"
                          htmlType="submit"
                          disabled={props.data?.passedInMinutes >= 24 * 60}
                        >
                          Cancel Order
                        </Button>
                      </Form.Item>
                    </Form>
                  )}
                  {props.roleId === ADMIN_ROLE_ID ? (
                    props.data.OrderStatus ? (
                      <Form
                        className="order-info"
                        layout="vertical"
                        form={form}
                        name="basic"
                        labelCol={{
                          span: 8,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        initialValues={{
                          remember: true,
                          size: 'large',
                          status: props.data.OrderStatus,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                      >
                        <Row>
                          <Col span={24}>
                            <div className="card">
                              <Form.Item
                                label="Status"
                                name="status"
                                key="status"
                              >
                                <Select
                                  size={'large'}
                                  allowClear={true}
                                  style={{ width: '100' }}
                                  onChange={() => {
                                    setIsFormReady(
                                      form.isFieldTouched('status') &&
                                        ((form.getFieldValue('status') ===
                                          'cancelled' &&
                                          form.getFieldValue(
                                            'cancellationReason',
                                          )) ||
                                          form.getFieldValue('status') !==
                                            'cancelled'),
                                    );
                                    setVisibleCancellationReason(
                                      form.getFieldValue('status') ===
                                        'cancelled',
                                    );
                                  }}
                                >
                                  <Option value="new" key="0">
                                    New
                                  </Option>
                                  <Option value="fullFilled" key="1">
                                    FullFilled
                                  </Option>
                                  <Option value="paid" key="2">
                                    Paid
                                  </Option>
                                  <Option value="cancelled" key="3">
                                    Cancelled
                                  </Option>
                                </Select>
                              </Form.Item>
                              {visibleCancellationReason && (
                                <Form.Item
                                  label="Cancellation Reason"
                                  name="cancellationReason"
                                  key="cancellationReason"
                                  onChange={() => {
                                    setIsFormReady(
                                      form.isFieldTouched('status') &&
                                        ((form.getFieldValue('status') ===
                                          'cancelled' &&
                                          form.getFieldValue(
                                            'cancellationReason',
                                          )) ||
                                          form.getFieldValue('status') !==
                                            'cancelled'),
                                    );
                                  }}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        'Please input the cancellation reason!',
                                    },
                                  ]}
                                >
                                  <TextArea
                                    size="large"
                                    placeholder="Enter the cancellation reason"
                                  />
                                </Form.Item>
                              )}
                              <Form.Item
                                wrapperCol={{
                                  offset: 0,
                                  span: 24,
                                }}
                                style={{ textAlign: 'right' }}
                              >
                                <Button
                                  className="btn"
                                  type="primary"
                                  htmlType="submit"
                                  disabled={!isFormReady}
                                >
                                  Update Status
                                </Button>
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    ) : (
                      'Loading ...'
                    )
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
            </Spin>
            <Table
              columns={columns}
              dataSource={dataSource}
              title="product"
              link="/product"
              scroll={{ x: 1450 }}
              hidePagination={true}
            />
            <div className="btn-gp">
              {canEdit ? (
                <Link className="btn" to={`/orders/edit/${props.data?.id}`}>
                  Edit
                </Link>
              ) : (
                <Row>
                  <Col
                    span={24}
                    style={{
                      textAlign: 'center',
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '120%',
                      color: '#4D5E55',
                    }}
                  >
                    Changes to orders are only allowed within 24 hours
                  </Col>
                </Row>
              )}
            </div>
            <div style={{ height: '120px' }}></div>
          </div>
        )}
        {!permit && (
          <div style={{ marginTop: '100px' }}>
            <Row>
              <Col
                span={24}
                style={{
                  textAlign: 'center',
                  margin: '16px 0',
                  fontWeight: 700,
                  fontSize: '56px',
                  lineHeight: '110%',
                  color: '#4D5E55',
                }}
              >
                OOPS!
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                style={{
                  textAlign: 'center',
                  fontWeight: 400,
                  fontSize: '32px',
                  lineHeight: '120%',
                  color: '#4D5E55',
                }}
              >
                Order not found
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'center', margin: '70px 0' }}>
                <Link
                  to={'/orders'}
                  className="btn"
                  style={{ margin: 0, minWidth: '400px' }}
                >
                  {'Go to Orders '}
                </Link>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    data: state.order.data,
    loading: state.order.isLoading,

    tableData: state.data.data,
    tableLoading: state.data.isLoading,
    myData: state.me.data,
    roleId: state.me.roleId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (q) => dispatch(setUrl(q)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),
    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfoPage);
