import { connect, useDispatch } from 'react-redux';
import {
  setSelect,
  setUrl,
  setQuery,
  getData,
} from '../../store/reducers/dataSlice';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import * as roles from '../../services/constants/roles';
import { Col, Row, Spin, Tooltip } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import axios from 'axios';
import { setSelectedMenu } from '../../store/reducers/uiSlice';
import { CSVLink } from 'react-csv';
import DeleteModal from '../../components/DeleteModal';
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';

function InvoicesPage(props) {
  const dispatch = useDispatch();
  const [count, setCount] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const isAdmin = props.roleId === roles.ADMIN_ROLE_ID;

  useEffect(() => {
    props.setUrl('/invoices');
    props.setSelectedMenu('invoices');
    props.setSelect(
      `${
        isAdmin
          ? ``
          : `${
              props.roleId === roles.DISTRICT_ROLE_ID
                ? `&userId.id=${props.userId}&`
                : props.roleId === roles.SCHOOL_ROLE_ID
                ? `&userId.id=${props.userId}&`
                : ''
            }`
      }`,
    );
    props.setQuery('&_sort=id:DESC');
    axios
      .get(
        `/invoices?&_sort=created_at${
          isAdmin
            ? ``
            : `${
                props.roleId === roles.DISTRICT_ROLE_ID
                  ? `&userId.id=${props.userId}&`
                  : props.roleId === roles.SCHOOL_ROLE_ID
                  ? `&userId.id=${props.userId}&`
                  : ''
              }`
        }`,
      )
      .then((res) => {
        //   setVendorFilter(res.data.map(el=>({ text: el?.ProductId.Name, value: el?.ProductId.id })).filter((value, index, self) =>
        //   index === self.findIndex((t) => (
        //     t.id === value.id && t.text === value?.text
        //   ))
        // )
        // )
        setCount(res.data);
        setExportData(
          res.data.map((el) => ({
            'Invoice #': el?.number || 'N/A',
            'Created Date': el?.created_at
              ? moment(el?.created_at.slice(0, 10), 'YYYY-MM-DD').format(
                  'MM-DD-YYYY',
                )
              : 'N/A',
            'Order #': el?.orderId?.id || 'N/A',
            File: el?.file?.url || 'N/A',
            Notes: el?.note || 'N/A',
          })),
        );
      })
      .catch((e) => console.log(e));
    dispatch(getData());
  }, [dispatch, props, trigger]);

  const columns = [
    {
      title: 'Invoice #',
      dataIndex: 'number',
      key: 'number',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
      width: 150,
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) =>
        text
          ? moment(text.slice(0, 10), 'YYYY-MM-DD').format('MM-DD-YYYY')
          : 'N/A',
      sorter: {},
      width: 150,
    },
    ...(!isAdmin
      ? []
      : [
          {
            title: 'User Name',
            dataIndex: 'userId.searchRef',
            key: 'userId.searchRef',
            render: (_, item) => {
              return item?.userId?.DistrictOrSchoolName ? (
                <Link
                  to={`/users/${item?.userId?.id || item?.userId}`}
                  style={{ textDecoration: 'underline' }}
                >
                  {item?.userId?.DistrictOrSchoolName}{' '}
                </Link>
              ) : (
                'N/A'
              );
            },
            searchable: true,
            sorter: {},
            width: 150,
          },
        ]),
    {
      title: 'Order #',
      dataIndex: 'orderId.id',
      key: 'orderId.id',
      render: (text, rec) =>
        rec?.orderId?.id ? (
          <Link
            to={`/orders/${rec?.orderId?.id}`}
            style={{ textDecoration: 'underline' }}
          >
            {rec?.orderId?.id}
          </Link>
        ) : (
          'N/A'
        ),
      sorter: {},
      searchable: true,
      width: 110,
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      align: 'center',
      render: (text) =>
        text?.url ? (
          <a target="_blank" href={text?.url} rel="noreferrer">
            <PaperClipOutlined />
          </a>
        ) : (
          'N/A'
        ),
      width: 150,
    },
    {
      title: 'Notes',
      dataIndex: 'note',
      key: 'note',
      ellipsis: {
        showTitle: true,
      },
      render: (text) => text || 'N/A',
      searchable: true,
      sorter: {},
    },
    {
      title: isAdmin ? 'Action' : '',
      dataIndex: 'link',
      key: 'link',
      render: (text, rec) => (
        <Row
          align="middle"
          justify={isAdmin ? 'space-between' : 'center'}
          gutter={10}
        >
          <Col span={12}>
            <Tooltip title={isAdmin ? 'Edit' : 'View'}>
              <Link to={`/invoices/${rec.id}`}>
                <img src="/vectors/eye.png" alt="" />
              </Link>
            </Tooltip>
          </Col>
          {isAdmin && (
            <Col span={12}>
              <Tooltip title="Delete">
                <DeleteOutlined
                  style={{ color: '#eb7a2c', fontSize: 22 }}
                  onClick={() => setModalShow(rec)}
                />
              </Tooltip>
            </Col>
          )}
        </Row>
      ),
      width: 80,
    },
  ];

  return (
    <div className="orders-page">
      <DeleteModal
        show={modalShow}
        setShow={setModalShow}
        deleteFunc={() => {
          axios
            .delete(`/invoices/${modalShow?.id}`)
            .then(() => {
              setTrigger(true);
            })
            .catch((e) => {
              if (e.response && e.response.data.message[0].messages[0].message)
                throw e.response.data.message[0].messages[0].message;
              else
                throw 'An error has occurred please contact system administrator';
            });
        }}
      />
      <h2 className="title">Invoices</h2>
      <Spin spinning={count === null}>
        {count !== 0 ? (
          <>
            {isAdmin && (
              <Link to="/invoices/new" disabled={!isAdmin}>
                <div className="btn" disabled={!isAdmin}>
                  Add Invoice
                </div>
              </Link>
            )}

            {!isAdmin &&
              (exportData?.length ? (
                <CSVLink data={exportData} filename="invoices">
                  <div className="btn">Download Invoices</div>
                </CSVLink>
              ) : (
                <div className="btn" disabled={true}>
                  Download Invoices
                </div>
              ))}

            <Table
              columns={columns}
              title="Invoice"
              link="new"
              scroll={{ x: 900 }}
            />
          </>
        ) : (
          <>
            <div className="empty-table">
              <div className="wrapper">
                <h3>— Your invoices is empty —</h3>
                {!isAdmin ? (
                  <div className="btn" disabled={!isAdmin}>
                    Add Invoice
                  </div>
                ) : (
                  <Link to={`/invoices/new`} style={{ color: 'white' }}>
                    <div className="btn" disabled={!isAdmin}>
                      Add Invoice
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (url) => dispatch(setUrl(url)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

function mapStateToProps(state) {
  return {
    userId: state.me.id,
    roleId: state.me.roleId,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPage);
