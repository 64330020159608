/* eslint-disable max-len */
import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { TINY_MCE_PATH } from "../config/env-vars";

const TextEditor = ({ data, editorRef }) => {
  return (
    <Editor
      tinymceScriptSrc={TINY_MCE_PATH}
      initialValue={data}
      onInit={(evt, editor) => {
        // eslint-disable-next-line no-param-reassign
        editorRef.current = editor;
      }}
      init={{
        branding: false,
        selector: "textarea#full-featured-non-premium",
        plugins:
          "preview importcss searchreplace autolink autosave directionality visualblocks fullscreen image link media template table charmap nonbreaking insertdatetime advlist lists wordcount help charmap quickbars emoticons",
        imagetools_cors_hosts: ["picsum.photos"],
        menubar: "file edit view insert format tools table help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | | charmap emoticons | fullscreen  preview | insertfile image media template link | ltr rtl",
        toolbar_sticky: true,
        fontsize_formats: "8px 9px 10px 11px 12px 14px 18px 24px 30px 36px 48px 60px 72px 96px",
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [],
        image_list: [],
        image_class_list: [],
        importcss_append: true,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: "image",
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: "30vw",
        image_caption: true,
        quickbars_selection_toolbar: "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        contextmenu: "link table",
        content_style: "body {font-size:14px }",
      }}
    />
  );
};

export default TextEditor;
