import { connect, useDispatch } from 'react-redux';
import {
  setSelect,
  setUrl,
  setQuery,
  getData,
} from '../../store/reducers/dataSlice';
import Table from '../../components/Table';
import React, { useEffect, useLayoutEffect } from 'react';
import * as roles from '../../services/constants/roles';
import { DatePicker, Spin } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import axios from 'axios';
import { setSelectedMenu } from '../../store/reducers/uiSlice';
import { CSVLink } from 'react-csv';
import { orderStatusLabels } from '../../utils/helpers';
import { Link } from 'react-router-dom';

const { RangePicker } = DatePicker;

function OrderProducts(props) {
  const dispatch = useDispatch();
  const [count, setCount] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [vendorFilter, setVendorFilter] = useState([]);
  const [filterData, setFilterData] = useState([]);

  useLayoutEffect(() => {
    props.setQuery('&_sort=OrderId.created_at:DESC');
  }, []);

  useEffect(() => {
    props.setUrl('/orders-products');
    props.setSelect(
      `${
        filterData?.created_at_gte
          ? `&created_at_gte=${filterData?.created_at_gte}&created_at_lte=${filterData?.created_at_lte}&`
          : `&created_at_gte=${moment()
              .startOf('year')
              .format('YYYY-MM-DD')}&created_at_lte=${moment().format(
              'YYYY-MM-DD',
            )}&`
      }${
        props.roleId === roles.ADMIN_ROLE_ID
          ? ``
          : `${
              props.roleId === roles.DISTRICT_ROLE_ID
                ? `&OrderId.DistrictUserId.id=${props.userId}&`
                : props.roleId === roles.SCHOOL_ROLE_ID
                ? `&OrderId.SchoolUserId.id=${props.userId}&`
                : ''
            }`
      }`,
    );
    props.setQuery('&_sort=OrderId.created_at:DESC');

    axios
      .get(
        `/orders-products?&_sort=OrderId.created_at:DESC${
          filterData?.created_at_gte
            ? `&created_at_gte=${filterData?.created_at_gte}&created_at_lte=${filterData?.created_at_lte}&`
            : `&created_at_gte=${moment()
                .startOf('year')
                .format('YYYY-MM-DD')}&created_at_lte=${moment().format(
                'YYYY-MM-DD',
              )}&`
        }${
          props.roleId === roles.ADMIN_ROLE_ID
            ? ``
            : `${
                props.roleId === roles.DISTRICT_ROLE_ID
                  ? `&OrderId.DistrictUserId.id=${props.userId}&`
                  : props.roleId === roles.SCHOOL_ROLE_ID
                  ? `&OrderId.SchoolUserId.id=${props.userId}&`
                  : ''
              }`
        }`,
      )
      .then((res) => {
        setExportData(
          res.data.map((el) => ({
            Date: el?.OrderId?.created_at
              ? moment(el?.OrderId?.created_at).format('MM-DD-YYYY')
              : 'N/A',
            'PO #': el?.OrderId?.PO || 'N/A',
            'Order #': el?.OrderId?.id || 'N/A',
            'Order Status':
              orderStatusLabels?.[el?.OrderId?.OrderStatus] || 'N/A',
            'Product Code': el?.ProductId?.Code || 'N/A',
            'Product Name': el?.ProductId?.Name || 'N/A',
            Quantity: el?.Quantity || 'N/A',
            'Commodity Price': el?.OrderId?.TotalCommodityPrice
              ? `$${el?.OrderId?.TotalCommodityPrice.toFixed(2)}`
              : 'N/A',
            'Commercial Cost': el?.OrderId?.TotalRegularPrice
              ? `$${el?.OrderId?.TotalRegularPrice.toFixed(2)}`
              : 'N/A',
            'Fulfilled Quantity': el?.FulfilledQuantity || 'N/A',
            Vendor: el?.ProductId?.VendorId?.Name || 'N/A',
            ...(props.roleId === roles.ADMIN_ROLE_ID
              ? {
                  'District/School Name':
                    el?.OrderId?.CreatedByUserId?.DistrictOrSchoolName || 'N/A',
                }
              : {}),
            'Delivery Date': el?.OrderId?.DeliveryDate
              ? moment(el?.OrderId?.DeliveryDate).format('MM-DD-YYYY')
              : 'N/A',
            'Order Guide': el?.OrderId?.orderGuide?.Name || 'N/A',
            'Shipping Site Name': el?.OrderId?.shippingSiteName || 'N/A',
            'Shipping Address': el?.OrderId?.shippingAddress || 'N/A',
          })),
        );
        setCount(res.data);
      })
      .catch((e) => console.log(e));
    axios
      .get('/vendors?&_sort=Name:ASC')
      .then((res) => {
        setVendorFilter(
          res.data.map((type) => {
            return { text: type.Name, value: type.id };
          }),
        );
      })
      .catch(() => {});
    dispatch(getData());
  }, [dispatch, props, filterData]);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'OrderId.created_at',
      key: 'OrderId.created_at',
      render: (text, data) =>
        data?.OrderId?.created_at
          ? moment(data?.OrderId?.created_at).format('MM-DD-YYYY')
          : 'N/A',
      sorter: {},
    },
    {
      title: 'PO #',
      dataIndex: 'OrderId.PO',
      key: 'OrderId.PO',
      render: (text, rec) => rec?.OrderId?.PO || 'N/A',
      sorter: {},
      searchable: true,
    },
    {
      title: 'Order #',
      dataIndex: 'OrderId.id',
      key: 'OrderId.id',
      render: (text, rec) =>
        rec?.OrderId?.id ? (
          <Link
            to={`/orders/${rec?.OrderId?.id}`}
            style={{ textDecoration: 'underline' }}
          >
            {rec?.OrderId?.id}
          </Link>
        ) : (
          'N/A'
        ),
      sorter: {},
      searchable: true,
      location: 'OrderId',
    },
    {
      title: 'Order Status',
      dataIndex: 'OrderId.OrderStatus',
      key: 'OrderId.OrderStatus',
      render: (text, rec) =>
        orderStatusLabels?.[rec?.OrderId?.OrderStatus] || 'N/A',
      sorter: {},
      searchable: true,
    },
    {
      title: 'Product Code',
      dataIndex: 'ProductId.Code',
      key: 'ProductId.Code',
      render: (_, item) => item?.ProductId?.Code || 'N/A',
      sorter: {},
      searchable: true,
    },
    {
      title: 'Product Name',
      dataIndex: 'ProductId.Name',
      key: 'ProductId.Name',
      render: (_, item) => item?.ProductId?.Name || 'N/A',
      sorter: {},
      searchable: true,
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
      align: 'end',
      render: (text, rec) => rec?.Quantity || 'N/A',
      sorter: {},
      searchable: true,
    },
    {
      title: 'Commodity Price',
      dataIndex: 'OrderId.TotalCommodityPrice',
      key: 'OrderId.TotalCommodityPrice',
      align: 'end',
      render: (text, data) => (
        <div style={{ textAlign: 'right' }}>
          {data?.OrderId?.TotalCommodityPrice
            ? `$${data?.OrderId?.TotalCommodityPrice.toFixed(2)}`
            : 'N/A'}
        </div>
      ),
      sorter: {},
      searchable: true,
    },
    {
      title: 'Commercial Cost',
      dataIndex: 'OrderId.TotalRegularPrice',
      key: 'OrderId.TotalRegularPrice',
      align: 'end',
      render: (text, data) => (
        <div style={{ textAlign: 'right' }}>
          {data?.OrderId?.TotalRegularPrice
            ? `$${data?.OrderId?.TotalRegularPrice.toFixed(2)}`
            : 'N/A'}
        </div>
      ),
      sorter: {},
      searchable: true,
    },
    {
      title: 'Fulfilled Quantity',
      dataIndex: 'FulfilledQuantity',
      key: 'FulfilledQuantity',
      align: 'end',
      render: (text, rec) => rec?.FulfilledQuantity || 'N/A',
      sorter: {},
      searchable: true,
    },
    {
      title: 'Vendor',
      dataIndex: 'ProductId.VendorId.Name',
      key: 'ProductId.VendorId.Name',
      render: (text, rec) => rec?.ProductId?.VendorId?.Name || 'N/A',
      searchable: true,
      location: 'ProductId.VendorId_in',
      filters: vendorFilter,
      sortLocation: 'ProductId.VendorId.Name',
    },
    ...(props.roleId === roles.ADMIN_ROLE_ID
      ? [
          {
            title: 'District/School Name',
            dataIndex: 'OrderId.CreatedByUserId.DistrictOrSchoolName',
            key: 'OrderId.CreatedByUserId.DistrictOrSchoolName',
            render: (text, rec) =>
              rec?.OrderId?.CreatedByUserId?.DistrictOrSchoolName || 'N/A',
            searchable: true,
          },
        ]
      : []),
    {
      title: 'Delivery Date',
      dataIndex: 'OrderId.DeliveryDate',
      key: 'OrderId.DeliveryDate',
      render: (text, data) =>
        data?.OrderId?.DeliveryDate
          ? moment(data?.OrderId?.DeliveryDate).format('MM-DD-YYYY')
          : 'N/A',
      sorter: {},
    },
    {
      title: 'Order Guide',
      dataIndex: 'OrderId.orderGuide.Name',
      key: 'OrderId.orderGuide.Name',
      render: (text, data) => data?.OrderId?.orderGuide?.Name || 'N/A',
      searchable: true,
    },
    {
      title: 'Shipping Site Name',
      dataIndex: 'OrderId.shippingSiteName',
      key: 'OrderId.shippingSiteName',
      render: (text, data) => data?.OrderId?.shippingSiteName || 'N/A',
      searchable: true,
      sorter: {},
    },
    {
      title: 'Shipping Address',
      dataIndex: 'OrderId.shippingAddress',
      key: 'OrderId.shippingAddress',
      render: (text, data) => data?.OrderId?.shippingAddress || 'N/A',
      searchable: true,
      sorter: {},
    },
  ];

  return (
    <div className="orders-page">
      <h2 className="title">Order Products</h2>
      <RangePicker
        className="height-40 mr-10"
        defaultValue={[moment().startOf('year'), moment()]}
        onChange={(data) => {
          setFilterData(
            data?.[0]
              ? {
                  created_at_gte: moment(data[0]).format('YYYY-MM-DD'),
                  created_at_lte: moment(data[1]).format('YYYY-MM-DD'),
                }
              : {},
          );
        }}
        format="MM-DD-YYYY"
      />
      <CSVLink data={exportData} filename="Order Products">
        <div className="btn">Download Order Products</div>
      </CSVLink>
      <Spin spinning={count === null}>
        {count !== 0 ? (
          <>
            <Table
              columns={columns}
              title="Order"
              link="new"
              scroll={{ x: 2600 }}
              defSortKey="OrderId.created_at:DESC"
            />
          </>
        ) : (
          <>
            <div className="empty-table">
              <div className="wrapper">
                <h3>— Your order Product is empty —</h3>
              </div>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (url) => dispatch(setUrl(url)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

function mapStateToProps(state) {
  return {
    userId: state.me.id,
    roleId: state.me.roleId,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderProducts);
