import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';

//#region create
export const createOrderGuideType = createAsyncThunk(
  'data/createOrderGuideType',
  async (arg) => {
    const data = arg;
    return await axios
      .post('/order-guide-types', data)
      .then((res) => res)
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        // eslint-disable-next-line no-throw-literal
        else throw 'An error has occurred please contact system administrator';
      });
  },
);

export const createOrderGuideTypePending = (state, action) => {
  state.isLoading = true;
};
export const createOrderGuideTypeFulfilled = (state, action) => {
  state.isLoading = false;
  notification.success({
    message: `Success`,
    description: 'Order guide type created',
    placement: 'bottomRight',
  });
};
export const createOrderGuideTypeRejected = (state, action) => {
  // console.error(action);
  state.isLoading = false;
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion

//#region update
export const updateOrderGuideType = createAsyncThunk(
  'data/updateOrderGuideType',
  async (arg) => {
    const data = arg;
    return await axios
      .put(`/order-guide-types/${arg.id}`, data)
      .then((res) => res)
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        // eslint-disable-next-line no-throw-literal
        else throw 'An error has occurred please contact system administrator';
      });
  },
);

export const updateOrderGuideTypePending = (state, action) => {
  state.isLoading = true;
};
export const updateOrderGuideTypeFulfilled = (state, action) => {
  state.isLoading = false;
  notification.success({
    message: `Success`,
    description: 'Order guide type updated',
    placement: 'bottomRight',
  });
};
export const updateOrderGuideTypeRejected = (state, action) => {
  // console.error(action);
  state.isLoading = false;
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion
