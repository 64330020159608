import { connect } from 'react-redux';
import {
  setSelect,
  setUrl,
  setQuery,
  getData,
} from '../../store/reducers/dataSlice';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Spin, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import * as roles from './../../services/constants/roles';
import axios from 'axios';
import { setSelectedMenu } from '../../store/reducers/uiSlice';

function OrderGuidPage(props) {
  const [count, setCount] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    props.setSelectedMenu('3');
    props.setUrl('/order-guides');
    props.setSelect(
      `${
        props.userType !== roles.ADMIN_ROLE_ID
          ? `${
              props.userType === roles.DISTRICT_ROLE_ID
                ? `&DistrictUserId.id=${props.userId}&`
                : `&SchoolUserIds.id=${props.userId}&`
            }`
          : ''
      }`,
    );
    props.setQuery('&_sort=id:DESC');
    dispatch(getData());

    axios
      .get(
        `/order-guides/count?&${
          props.userType !== roles.ADMIN_ROLE_ID
            ? `${
                props.userType === roles.DISTRICT_ROLE_ID
                  ? `&DistrictUserId.id=${props.userId}&`
                  : `&SchoolUserIds.id=${props.userId}&`
              }`
            : ''
        }`,
      )
      .then((res) => setCount(res.data))
      .catch((e) => console.log(e));
  }, [props]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (text) => (text ? text : 'N/A'),
      sorter: {},
      searchable: true,
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
      render: (text) => (text ? text : 'N/A'),
      searchable: true,
    },
    {
      title: 'Type',
      dataIndex: 'OrderGuideTypeId',
      key: 'OrderGuideTypeId',
      render: (text) => (text && text.Name ? text.Name : 'N/A'),
      searchable: true,
      location: 'OrderGuideTypeId.Name',
    },
    props.userType === roles.ADMIN_ROLE_ID
      ? {
          title: 'District',
          dataIndex: 'DistrictUserId',
          key: 'DistrictUserId',
          render: (text) =>
            text && text.DistrictOrSchoolName
              ? text.DistrictOrSchoolName
              : 'N/A',
          sorter: {},
          location: 'DistrictUserId.DistrictOrSchoolName',
          searchable: true,
        }
      : null,
    {
      title: 'School(s)',
      dataIndex: 'SchoolUserIds',
      key: 'SchoolUserIds',
      render: (text) =>
        text
          ? `${text
              .slice(0, 3)
              .map((s) => s.DistrictOrSchoolName)
              .join(', ')} ${text.length > 3 ? '...' : ''}`
          : 'N/A',
      sorter: {},
      searchable: true,
      location: 'SchoolUserIds.DistrictOrSchoolName',
    },
    {
      title: '',
      dataIndex: 'Name',
      key: 'link',
      render: (text, rec) => (
        <Tooltip title="View">
          <Link to={`/order-guides/${rec.id}`}>
            <img src="/vectors/eye.png" alt="" />
          </Link>
        </Tooltip>
      ),
      width: 50,
    },
  ];

  return (
    <div className="orders-page">
      <h2 className="title">Order Guides</h2>

      <Spin spinning={count === null}>
        {count !== 0 ? (
          <>
            {props.userType !== roles.SCHOOL_ROLE_ID && (
              <Link to={'/order-guides/new'}>
                <div className="btn">Add Order Guides</div>
              </Link>
            )}
            <Table
              columns={columns.filter((i) => i)}
              title="Order guides"
              link="order-guides"
              scroll={{ x: props.userType === roles.ADMIN_ROLE_ID ? 800 : 600 }}
            />
          </>
        ) : (
          <>
            <div className="empty-table">
              <div className="wrapper">
                <h3>— Your order guides is empty —</h3>
                {props.userType !== roles.SCHOOL_ROLE_ID && (
                  <Link to={`/order-guides/new`} style={{ color: 'white' }}>
                    <div className="btn">Add order guide</div>
                  </Link>
                )}
              </div>
            </div>
          </>
        )}
      </Spin>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (url) => dispatch(setUrl(url)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

function mapStateToProps(state) {
  return {
    userType: state.me.roleId,
    userId: state.me.id,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderGuidPage);
