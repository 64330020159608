import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  notification,
  Select,
  InputNumber,
  Switch,
  Upload,
} from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';

import { setQuery, setSelect, setUrl } from '../../store/reducers/dataSlice';
import axios from 'axios';
import { createProduct, updateProduct } from '../../store/thunks/products';
import { ADMIN_ROLE_ID } from '../../services/constants/roles';
import DeleteModal from '../../components/DeleteModal';
import { setSelectedMenu } from '../../store/reducers/uiSlice';
import { addUploadApi } from '../../services/uploads';

function ProductInfoPage(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { Option } = Select;
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [types, setTypes] = useState([]);

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setSelectedMenu('40');

    if (props.functionality === 'update') {
      props.setUrl('/products');
      props.setSelect('');
      props.setQuery(`&id=${id}`);
    }
  }, [id, props]);

  useEffect(() => {
    if (props.data && props.functionality === 'update') {
      let values = {
        URL: props.data.URL,
        Code: props.data.Code,
        Name: props.data.Name,
        Buyable: props.data.Buyable,
        TimeLead: props.data.TimeLead,
        VendorId: props.data.VendorId ? props.data.VendorId.id : null,
        Size: props.data.Size,
        Pack: props.data.Pack,
        Weight: props.data.Weight,
        MMA: props.data.MMA,
        Grain: props.data.Grain,
        Veg: props.data.Veg,
        Fruit: props.data.Fruit,
        productType: props.data.productType.id,
        CoOPReqularPrice: props.data.CoOPReqularPrice?.toFixed(2),
        ReqularPrice: props.data.ReqularPrice?.toFixed(2),
        CoOPCommidityPrice: props.data.CoOPCommidityPrice?.toFixed(2),
        CommidityPrice: props.data.CommidityPrice?.toFixed(2),
        PTV: props.data.PTV?.toFixed(2),
      };
      setUploadedFile(props.data?.productAttachment);
      form.setFieldsValue(values);
    }
  }, [form, props.data, props.functionality]);

  useEffect(() => {
    axios
      .get('/vendors?&Deleted=0')
      .then((res) => {
        setVendors(res.data);
      })
      .catch((e) => {
        notification.error({
          message: `Can't load vendors`,
          description: `An error has occurred please contact system administrator`,
          placement: 'bottomRight',
        });
        // console.error(e);
      });

    axios
      .get('product-types?&Deleted=0&')
      .then((res) => {
        setTypes(res.data);
      })
      .catch((e) => {
        notification.error({
          message: `Can't load types`,
          description: `An error has occurred please contact system administrator`,
          placement: 'bottomRight',
        });
        // console.error(e);
      });
  }, []);

  const onFinish = (values) => {
    let data = {
      ...values,
      Buyable: values.Buyable ? values.Buyable : false,
      productAttachment: uploadedFile,
      id,
    };

    if (props.functionality === 'create') {
      dispatch(createProduct(data));
    } else {
      dispatch(updateProduct(data));
    }
  };

  const uploadFile = async (file) => {
    setUploadLoading(true);
    const callback = await addUploadApi(file);

    setUploadLoading(false);
    if (callback) {
      const uploadedData = callback?.data ? callback?.data[0] : null;
      setUploadedFile({
        ...uploadedData,
        url: uploadedData?.url?.includes('https://')
          ? uploadedData?.url
          : `https://${uploadedData?.url}`,
      });
    }
    return false;
  };

  const ThisFormInput = () => (
    <>
      <Row>
        <Col md={12} span={24}>
          <Form.Item
            label="Product Code"
            name="Code"
            key="code"
            rules={[
              {
                required: true,
                message: 'Please input your Code!',
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter your Product code"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>

        <Col md={12} span={24}>
          <Form.Item
            label="Product name"
            name="Name"
            key="Name"
            rules={[
              {
                required: true,
                message: 'Please input your name !',
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter your Product name"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>
      </Row>

      {(props.roleId === ADMIN_ROLE_ID || props.isCoop) && (
        <></>
        // To Hide super co op price
        // <Row>
        //   <Col md={12} span=24>
        //     <Form.Item
        //       label="Super Co op Commercial Cost"
        //       name="CoOPReqularPrice"
        //       key="CoOPReqularPrice"
        //       rules={[
        //         {
        //           required: true,
        //           message: "Please input your Commercial Cost!",
        //         },
        //       ]}
        //     >
        //       <InputNumber
        //         precision={2}
        //         size="large"
        //         placeholder="Enter your Super Co op Commercial Cost"
        //         disabled={props.roleId !== ADMIN_ROLE_ID}
        //       />
        //     </Form.Item>
        //   </Col>

        //   <Col md={12} span=24>
        //     <Form.Item
        //       label="Super Co op commodity price"
        //       name="CoOPCommidityPrice"
        //       key="CoOPCommidityPrice"
        //       Super
        //       Co
        //       op
        //       commodity
        //       price
        //       rules={[
        //         {
        //           required: true,
        //           message: "Please input your Super Co op commodity price!",
        //         },
        //       ]}
        //     >
        //       <InputNumber
        //         precision={2}
        //         size="large"
        //         placeholder="Enter your Super Co op commodity price"
        //         disabled={props.roleId !== ADMIN_ROLE_ID}
        //       />
        //     </Form.Item>
        //   </Col>
        // </Row>
      )}

      {(props.roleId === ADMIN_ROLE_ID || !props.isCoop) && (
        <Row>
          <Col md={12} span={24}>
            <Form.Item
              label="Commercial Cost"
              name="ReqularPrice"
              key="ReqularPrice"
              rules={[
                {
                  required: true,
                  message: 'Please input your Commercial Cost!',
                },
              ]}
            >
              <InputNumber
                precision={2}
                size="large"
                placeholder="Enter your Commercial Cost"
                disabled={props.roleId !== ADMIN_ROLE_ID}
              />
            </Form.Item>
          </Col>

          <Col md={12} span={24}>
            <Form.Item
              label="Commodity Price"
              name="CommidityPrice"
              key="CommidityPrice"
              rules={[
                {
                  required: true,
                  message: 'Please input your Commodity Price!',
                },
              ]}
            >
              <InputNumber
                precision={2}
                size="large"
                placeholder="Enter your Commodity Price"
                disabled={props.roleId !== ADMIN_ROLE_ID}
              />
            </Form.Item>
          </Col>
          <Col md={12} span={24}>
            <Form.Item
              label="PTV"
              name="PTV"
              key="PTV"
              rules={[
                {
                  required: true,
                  message: 'Please input your PTV!',
                },
              ]}
            >
              <InputNumber
                precision={2}
                size="large"
                placeholder="Enter your PTV"
                disabled={props.roleId !== ADMIN_ROLE_ID}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row>
        <Col md={12} span={24}>
          <Form.Item
            label="Type "
            name="productType"
            key="productType"
            rules={[
              {
                required: true,
                message: 'Please select a type!',
              },
            ]}
          >
            <Select
              allowClear={true}
              size="large"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            >
              {types ? (
                types.map((option) => (
                  <Option value={option.id} key={option.id}>
                    {option.Name}
                  </Option>
                ))
              ) : (
                <></>
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col md={12} span={24}>
          <Form.Item
            label="Vendor "
            name="VendorId"
            key="VendorId"
            rules={[
              {
                required: true,
                message: 'Please select a vendor!',
              },
            ]}
          >
            <Select
              allowClear={true}
              size="large"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            >
              {vendors ? (
                vendors.map((option) => (
                  <Option value={option.id} key={option.id}>
                    {option.Name}
                  </Option>
                ))
              ) : (
                <></>
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={12} span={24}>
          <Spin spinning={uploadLoading}>
            <Form.Item label="Product Specs" key="URL">
              {props.roleId !== ADMIN_ROLE_ID ? (
                uploadedFile?.url ? (
                  <a
                    className="btn"
                    target="_blank"
                    href={uploadedFile?.url}
                    style={{ padding: '3px 24px', background: '#97BE11' }}
                    rel="noreferrer"
                  >
                    Spec
                  </a>
                ) : (
                  <span
                    className="btn"
                    style={{ padding: '3px 24px', background: '#e5e5e5' }}
                  >
                    Spec
                  </span>
                )
              ) : uploadedFile?.url ? (
                <Button
                  icon={
                    <DeleteOutlined onClick={() => setUploadedFile(null)} />
                  }
                  className="edit_profile_button"
                >
                  <a
                    target="_blank"
                    href={uploadedFile?.url}
                    rel="noreferrer"
                    style={{ marginLeft: 10 }}
                  >
                    {uploadedFile?.name}
                  </a>
                </Button>
              ) : (
                <Upload
                  listType="text"
                  beforeUpload={uploadFile}
                  maxCount={1}
                  onRemove={() => setUploadedFile(null)}
                  fileList={uploadedFile ? [uploadedFile] : []}
                  customRequest={() => {}}
                  accept=".jpeg,.jpg,.png,.tiff,.pdf,.zip,.doc,.docx"
                  showUploadList={false}
                  className="edit_profile_upload"
                >
                  <Button
                    icon={<UploadOutlined />}
                    className="edit_profile_upload"
                  >
                    Click to Upload
                  </Button>
                </Upload>
              )}
            </Form.Item>
          </Spin>
        </Col>

        <Col md={12} span={24}>
          <Form.Item
            label="Lead Time"
            name="TimeLead"
            key="TimeLead"
            rules={[
              {
                required: true,
                message: 'Please input your lead time!',
              },
            ]}
          >
            <InputNumber
              size="large"
              placeholder="Enter your Time Lead"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col md={12} span={24}>
          <Form.Item label="Size" name="Size" key="Size">
            <Input
              precision={2}
              size="large"
              placeholder="Enter your Size"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>

        <Col md={12} span={24}>
          <Form.Item label="Pack" name="Pack" key="Pack">
            <Input
              size="large"
              placeholder="Enter your Pack"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col md={12} span={24}>
          <Form.Item label="Weight" name="Weight" key="Weight">
            <Input
              size="large"
              placeholder="Enter your Weight"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>

        <Col md={12} span={24}>
          <Form.Item label="MMA" name="MMA" key="MMA">
            <Input
              size="large"
              placeholder="Enter your MMA"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col md={12} span={24}>
          <Form.Item label="Grain" name="Grain" key="Grain">
            <Input
              size="large"
              placeholder="Enter your Grain"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>

        <Col md={12} span={24}>
          <Form.Item label="Veg" name="Veg" key="Veg">
            <Input
              size="large"
              placeholder="Enter your Veg"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>
        <Col md={12} span={24}>
          <Form.Item label="Fruit" name="Fruit" key="Veg">
            <Input
              size="large"
              placeholder="Enter your Fruit"
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col md={12} span={24}>
          <Form.Item label="Available" name="Buyable" key="Buyable">
            <Switch
              checkedChildren="yes"
              unCheckedChildren="no"
              defaultChecked={
                props.functionality === 'update' && props.data
                  ? props.data.Buyable
                  : false
              }
              disabled={props.roleId !== ADMIN_ROLE_ID}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <DeleteModal
        show={modalShow}
        setShow={setModalShow}
        deleteFunc={() => {
          dispatch(
            updateProduct({
              ...props.data,
              Deleted: true,
            }),
          );
        }}
      />
      <div className="product-info-page info-page">
        <div className="container">
          <Link to={'/products'}>
            <h2 className="title  go-back">
              <img src="/vectors/arrow-left.png" alt="back" />
              Go Back
            </h2>
          </Link>

          <h2 className="title"> Product information</h2>

          <Spin
            spinning={props.loading && props.functionality !== 'create'}
            tip="Loading..."
          >
            <Form
              className="product-info"
              layout="vertical"
              form={form}
              name="basic"
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
                size: 'large',
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <ThisFormInput />

              <div className="btn-gp">
                {props.functionality === 'create' ? (
                  <Form.Item
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                  >
                    <Button className="btn" type="primary" htmlType="submit">
                      Confirm
                    </Button>
                  </Form.Item>
                ) : (
                  <>
                    {props.roleId === ADMIN_ROLE_ID ? (
                      <Form.Item
                        wrapperCol={{
                          offset: 0,
                          span: 24,
                        }}
                      >
                        <Button
                          className="btn"
                          type="primary"
                          htmlType="submit"
                        >
                          Update
                        </Button>

                        <Button
                          className="btn"
                          onClick={(e) => {
                            setModalShow(true);
                          }}
                        >
                          Delete
                        </Button>
                      </Form.Item>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </Form>
            <div style={{ height: '120px' }}></div>
          </Spin>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    data:
      state.data && state.data.data && state.data.data[0]
        ? state.data.data[0]
        : null,
    loading: state.data.isLoading,

    isCoop: state.me.data.IsCoOp,
    roleId: state.me.roleId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (q) => dispatch(setUrl(q)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfoPage);
