import { Button, Col, Form, Input, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Table } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

// import Table from "../../components/Table";
import { getData } from '../../store/reducers/orderGuidSlice';
import { setQuery, setSelect, setUrl } from '../../store/reducers/dataSlice';
import { createOrderGuid, updateOrderGuid } from '../../store/thunks/orderGuid';
import { ADMIN_ROLE_ID, SCHOOL_ROLE_ID } from '../../services/constants/roles';
import { setSelectedMenu } from '../../store/reducers/uiSlice';

function OrderGuidInfoPage(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [permit, setPermit] = useState(true);

  useEffect(() => {
    props.setSelectedMenu('3');
    dispatch(getData(id));
  }, [id]);
  useEffect(() => {
    setPermit(props.data?.created_at ? true : false);
  }, [props.data]);
  const adminColumns = [
    // {
    //   title: "Super Co op Commercial Cost",
    //   dataIndex: "CoOPReqularPrice",
    //   key: "CoOPReqularPrice",
    //   render: (text) => <div style={{ textAlign: "right" }}>{text ? `$${text.toFixed(2)}` : "N/A"}</div>,
    //   sorter: {},
    //   width: 160,
    // },
    // {
    //   title: "Super Co op Commodity Price",
    //   dataIndex: "CoOPCommidityPrice",
    //   key: "CoOPCommidityPrice",
    //   render: (text) => <div style={{ textAlign: "right" }}>{text ? `$${text.toFixed(2)}` : "N/A"}</div>,
    //   sorter: {},
    //   width: 160,
    // },
    {
      title: 'Commercial Cost',
      dataIndex: 'ReqularPrice',
      key: 'ReqularPrice',
      render: (text, rec) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
      sorter: {},
      width: 160,
    },
    {
      title: 'Commodity Price',
      dataIndex: 'CommidityPrice',
      key: 'CommidityPrice',
      render: (text) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
      sorter: {},
      width: 160,
    },
  ];

  const notAdminColumns = [
    {
      title: 'Commercial Cost',
      dataIndex: props.amCOOP ? 'CoOPReqularPrice' : 'ReqularPrice',
      key: 'ReqularPrice',
      render: (text, rec) =>
        text ? (
          <div style={{ textAlign: 'right' }}>{`$${text.toFixed(2)}`}</div>
        ) : (
          'N/A'
        ),
      sorter: {},
      width: 160,
    },
    {
      title: 'Commodity Price',
      dataIndex: props.amCOOP ? 'CoOPCommidityPrice' : 'CommidityPrice',
      key: 'CommidityPrice',
      render: (text) =>
        text ? (
          <div style={{ textAlign: 'right' }}>{`$${text.toFixed(2)}`}</div>
        ) : (
          'N/A'
        ),
      sorter: {},
      width: 160,
    },
  ];

  const columns = [
    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code',
      render: (text) => (text ? text : 'N/A'),
      sorter: (a, b) => a.Code && a.Code.localeCompare(b.Code),
      width: 130,
    },
    {
      title: 'Product Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (text) => (text ? text : 'N/A'),
      sorter: (a, b) => a.Name && a.Name.localeCompare(b.Name),
    },
    ...(props.roleId === ADMIN_ROLE_ID ? adminColumns : notAdminColumns),
    {
      title: 'Time lead',
      dataIndex: 'TimeLead',
      key: 'TimeLead',
      render: (text) =>
        text ? <div style={{ textAlign: 'center' }}>{text} days</div> : 'N/A',
      sorter: (a, b) => a.TimeLead - b.TimeLead,
      width: 120,
    },
    {
      title: 'Available',
      dataIndex: 'Buyable',
      key: 'Buyable',
      render: (text) =>
        text ? (
          <div style={{ textAlign: 'center' }}>
            <img
              src="/vectors/green-check.png"
              alt="buyable"
              style={{ width: '14px' }}
            />
          </div>
        ) : (
          <div style={{ color: 'red', textAlign: 'center' }}>
            <CloseOutlined />
          </div>
        ),
      width: 50,
    },
  ];

  useEffect(() => {
    props.setUrl('/order-guides');
    props.setSelect('');
    props.setQuery(`&&&&&&&&&&&&&`);
  }, []);

  useEffect(() => {
    props.data.Name &&
      form.setFieldsValue({
        Name: props.data.Name,
        Description: props.data.Description,
      });
  }, [form, props.data]);

  const EveryInfo = ({ title, text }) => (
    <div className="every-info">
      <div className="title">{title}</div>
      <div className="text">{text}</div>
    </div>
  );

  const onFinish = (values) => {
    let data = {
      ...props.data,
      ...values,
      id,
    };
    if (props.functionality === 'create') {
      dispatch(createOrderGuid(data));
    } else {
      dispatch(updateOrderGuid(data));
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      {permit && (
        <div className="order-info-page info-page">
          <div className="container">
            <Link to={'/order-guides'}>
              <h2 className="title">
                <img src="/vectors/arrow-left.png" alt="" />
                Go Back
              </h2>
            </Link>

            <h2 className="title">Order Guide Form</h2>

            <Spin spinning={props.loading} tip="Loading...">
              <Form
                className="school-info"
                layout="vertical"
                form={form}
                name="basic"
                labelCol={{
                  span: 8,
                }}
                initialValues={{
                  remember: true,
                  size: 'large',
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row>
                  <Col span={11}>
                    <Row>
                      <Col span={8}>
                        <EveryInfo
                          title="Order Guide Type"
                          text={
                            props.data.OrderGuideTypeId &&
                            props.data.OrderGuideTypeId.Name
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <EveryInfo
                          title="District"
                          text={
                            props.data.DistrictUserId &&
                            props.data.DistrictUserId.DistrictOrSchoolName
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <EveryInfo
                          title="School(s)"
                          text={
                            props.data.SchoolUserIds &&
                            props.data.SchoolUserIds.map(
                              (s) => s.DistrictOrSchoolName,
                            ).join(', ')
                          }
                        />
                      </Col>
                    </Row>

                    <Form.Item label="Name" name="Name" key="Name">
                      <Input size="large" placeholder="Name" />
                    </Form.Item>

                    <Form.Item
                      label="Description"
                      name="Description"
                      key="Description"
                    >
                      <Input.TextArea
                        placeholder="Description"
                        rows={4}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <div className="btn-gp">
                  <Form.Item
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                  >
                    {props.roleId !== SCHOOL_ROLE_ID && (
                      <Link to="/order-guides/edit" className="btn">
                        Update
                      </Link>
                    )}
                    {props.roleId !== SCHOOL_ROLE_ID && (
                      <Button
                        className="btn"
                        onClick={(e) => {
                          dispatch(
                            updateOrderGuid({
                              ...props.data,
                              Active: !props.data.Active,
                            }),
                          );
                        }}
                      >
                        {props.data.Active ? 'Deactivate' : 'Activate'}
                      </Button>
                    )}
                  </Form.Item>
                </div>
              </Form>
            </Spin>

            <Table
              columns={columns}
              dataSource={props.data.OrderGuideProducts}
              bordered={true}
              pagination={{
                simple: true,
                position: ['bottomCenter'],
                total: props.count,
              }}
              rowKey={'id'}
              scroll={{ x: 1000 }}
            />
            <div style={{ height: '120px' }}></div>
          </div>
        </div>
      )}
      {!permit && (
        <div style={{ marginTop: '100px' }}>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: 'center',
                margin: '16px 0',
                fontWeight: 700,
                fontSize: '56px',
                lineHeight: '110%',
                color: '#4D5E55',
              }}
            >
              OOPS!
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: 'center',
                fontWeight: 400,
                fontSize: '32px',
                lineHeight: '120%',
                color: '#4D5E55',
              }}
            >
              Order guide not found
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ textAlign: 'center', margin: '70px 0' }}>
              <Link
                to={'/order-guides'}
                className="btn"
                style={{ margin: 0, minWidth: '400px' }}
              >
                {'Go to Order Guides '}
              </Link>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    data: state.orderGuid.data,
    loading: state.orderGuid.isLoading,
    tableData: state.data.data,
    tableLoading: state.data.isLoading,

    amCOOP: state.me.data.IsCoOp,
    roleId: state.me.roleId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // getData: (q) => dispatch(getData(q)),
    setUrl: (q) => dispatch(setUrl(q)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderGuidInfoPage);
