import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { notification } from 'antd';
import { SCHOOL_ROLE_ID } from '../../services/constants/roles';

export const getData = createAsyncThunk('me/getData', async (arg) => {
  let district = {};
  let res = await axios.get(`/users/me`);
  if (res.data.role.id === SCHOOL_ROLE_ID && res.data.DistrictId)
    district = await axios.get(`/users/${res.data.DistrictId}`);
  let data = { ...res.data, myDistrict: district ? district.data : {} };
  return { data };
});

export const editData = createAsyncThunk(
  'me/editData',
  async (args, thunkAPI) => {
    let data = { ...thunkAPI.getState().me.data, ...args };
    let res = await axios
      .put(`/users/${thunkAPI.getState().me.id}`, data)
      .catch((e) => {
        if (e.response && e.response.data.message[0].messages[0].message)
          throw e.response.data.message[0].messages[0].message;
        else throw 'An error has occurred please contact system administrator';
      });
    return res.data;
  },
);

const initialState = {
  data: {},
  username: '',
  id: 0,
  roleId: '',
  firstName: '',
  isLoading: true,
};

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getData.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.data = data;
      state.id = data.id;
      state.username = data.username;
      state.roleId = data.role.id;
      state.firstName = data.FirstName;
      state.isLoading = false;
    });
    builder.addCase(getData.rejected, (state, action) => {
      localStorage.removeItem('jwt');
      localStorage.removeItem('state');
      window.location.href = '/';
    });

    builder.addCase(editData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(editData.fulfilled, (state, action) => {
      state.isLoading = false;
      notification.success({
        message: `Success`,
        description: 'Profile updated',
        placement: 'bottomRight',
      });
      state.data = { ...action.payload, myDistrict: action.payload.DistrictId };
      // state.
    });
    builder.addCase(editData.rejected, (state, action) => {
      state.isLoading = false;
      notification.error({
        message: `Error`,
        description: action.error.message,
        placement: 'bottomRight',
      });
    });
  },
});

export const { changeName, reset } = meSlice.actions;
export default meSlice.reducer;
