import { Button, Col, Form, Input, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import MaskedInput from 'antd-mask-input';
import { useDispatch } from 'react-redux';

import { setQuery, setSelect, setUrl } from '../../store/reducers/dataSlice';
import { createVendor, updateVendor } from './../../store/thunks/vendor';
import DeleteModal from '../../components/DeleteModal';
import { useState } from 'react';
import { setSelectedMenu } from '../../store/reducers/uiSlice';

function VendorInfoPage(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [modalShow, setModalShow] = useState(false);

  const [IsPhoneValid, setIsPhoneValid] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });
  const phoneValidation = (val) => {
    // const regex = /\b\d{10}\b/g;
    const regex = /^(?=(?:.*?\d){10})/gm;
    if (regex.test(val)) {
      return { validateStatus: 'success', errorMsg: null };
    }

    return { validateStatus: 'error', errorMsg: 'Phone is not correct' };
  };

  useEffect(() => {
    props.setSelectedMenu('42');

    props.setUrl('/vendors');
    props.setSelect('');
    props.setQuery(`&id=${id}`);
  }, [id, props]);

  useEffect(() => {
    if (props.functionality === 'update')
      form.setFieldsValue({
        ...props.data,
        // WebSite: props.data && props.data.WebSite,
      });
  }, [form, props.data, props.functionality]);

  const navigateTo = useNavigate();

  const onFinish = (values) => {
    let data = {
      ...values,
      id,
    };
    if (props.functionality === 'create') {
      dispatch(createVendor(data)).then(() => {
        navigateTo('/products/vendors');
      });
    } else {
      dispatch(updateVendor(data)).then(() => {
        navigateTo('/products/vendors');
      });
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div className="vendor-info-page info-page">
      <DeleteModal
        show={modalShow}
        setShow={setModalShow}
        deleteFunc={() => {
          dispatch(
            updateVendor({
              ...props.data,
              Deleted: props.data.Deleted ? false : true,
            }),
          );
        }}
      />
      <div className="container">
        <Link to={'/products/vendors'}>
          <h2 className="title go-back">
            <img src="/vectors/arrow-left.png" alt="" />
            Go Back
          </h2>
        </Link>

        <h2 className="title">Vendor information</h2>

        <Spin
          spinning={props.loading && props.functionality === 'update'}
          tip="Loading..."
        >
          <Form
            className="school-info"
            layout="vertical"
            form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            initialValues={{
              remember: true,
              size: 'large',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row>
              <Col md={12} span={24}>
                <Form.Item
                  label="Vendor Name"
                  name="Name"
                  key="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your name!',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter your vendor name" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} span={24}>
                <Form.Item
                  label="Website"
                  name="WebSite"
                  key="WebSite"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your website!',
                    },
                    {
                      type: 'url',
                      message: 'This field must be a valid url.',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter your website" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} span={24}>
                <Form.Item
                  label="Phone"
                  name="Phone"
                  key="Phone"
                  validateStatus={IsPhoneValid.validateStatus}
                  help={IsPhoneValid.errorMsg}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your phone!',
                    },
                  ]}
                >
                  <MaskedInput
                    mask="(000) 000-0000"
                    size="large"
                    placeholder="Enter Your phone number"
                    onChange={(e) => {
                      setIsPhoneValid({
                        ...phoneValidation(e.target.value),
                        value: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} span={24}>
                <Form.Item
                  label="Vendor ID"
                  name="id"
                  key="id"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your vendor id!',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter your Vendor ID" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col md={12} span={24}>
                <Form.Item
                  label="Address"
                  name="Address"
                  key="Address"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your address!',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter your address" />
                </Form.Item>
              </Col>
            </Row>

            <div className="btn-gp">
              {props.functionality === 'create' ? (
                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  <Button className="btn" type="primary" htmlType="submit">
                    Confirm
                  </Button>
                </Form.Item>
              ) : (
                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  <Button className="btn" type="primary" htmlType="submit">
                    Update
                  </Button>
                  <Button
                    className="btn"
                    onClick={(e) => {
                      setModalShow(true);
                    }}
                  >
                    Delete
                  </Button>
                </Form.Item>
              )}
            </div>
          </Form>
          <div style={{ height: '120px' }}></div>
        </Spin>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data:
      state.data && state.data.data && state.data.data[0]
        ? state.data.data[0]
        : null,
    loading: state.data.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUrl: (q) => dispatch(setUrl(q)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorInfoPage);
