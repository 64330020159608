/* eslint-disable no-throw-literal */
import { createAsyncThunk, current } from '@reduxjs/toolkit';
import { notification } from 'antd';
import axios from 'axios';

//#region create
export const createUser = createAsyncThunk('data/createUser', async (arg) => {
  const data = arg; //role will contain "school" or "district"
  // throw "YOOOOOOOOOOOOOOO";
  let res = await axios.post('/auth/local/register', data).catch((e) => {
    if (e.response && e.response.data.message[0].messages[0].message)
      throw e.response.data.message[0].messages[0].message;
    else throw 'An error has occurred please contact system administrator';
  });
  return res;
});

export const createUserPending = (state, action) => {
  state.isLoading = true;
};
export const createUserFulfilled = (state, action) => {
  notification.success({
    message: `School invited`,
    description: 'Email will send to the school to complete registration',
    placement: 'bottomRight',
  });
};
export const createUserRejected = (state, action) => {
  // console.error(action);
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion

//#region update
export const updateUser = createAsyncThunk('data/updateUser', async (arg) => {
  return await axios
    .put(`/users/${arg.id}`, arg)
    .then((res) => res)
    .catch((e) => {
      if (e.response && e.response.data.message[0].messages[0].message)
        throw e.response.data.message[0].messages[0].message;
      else throw 'An error has occurred please contact system administrator';
    });
});

export const updateUserPending = (state, action) => {
  // state.isLoading = true;
};
export const updateUserFulfilled = (state, action) => {
  notification.success({
    message: `Success`,
    description: 'User info updated',
    placement: 'bottomRight',
  });

  let newData = [
    ...current(state.data).map((user) => {
      if (user.id !== action.payload.data.id) return user;
      else {
        let newUser = {
          ...action.payload.data,
        };
        return newUser;
      }
    }),
  ];

  state.data = [...newData];
  state.isLoading = false;
};
export const updateUserRejected = (state, action) => {
  // console.error(action);
  notification.error({
    message: `Error`,
    description: action.error.message,
    placement: 'bottomRight',
  });
};
//#endregion
