import React from "react";
import { Link } from "react-router-dom";

function EmptyTable({ title, link }) {
	return (
		<div className="empty-table">
			<div className="wrapper">
				<h3>— Your {title} is empty —</h3>
				<div className="btn">
					<Link to={`link`} style={{ color: "white" }}>
						Add {title}
					</Link>
				</div>
			</div>
		</div>
	);
}

export default EmptyTable;
