import { createSlice } from "@reduxjs/toolkit";

const initialState = { show: false, message: "", color: "" };

const errorSlice = createSlice({
	name: "error",
	initialState,
	reducers: {
		setError: (state, action) => {
			state.show = action.payload.show;
			state.message = action.payload.message;
			state.color = action.payload.color ? action.payload.color : "";
		},
	},
});

export const { setError } = errorSlice.actions;
export default errorSlice.reducer;
