import React, { useEffect, useState } from 'react';
import {
  Steps,
  Button,
  Col,
  Row,
  Form,
  Select,
  Input,
  DatePicker,
  Modal,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Table from './../../components/Table';
import {
  getDistricts,
  getSchools,
  getOrderGuides,
  postOrders,
  editOrder,
} from '../../store/thunks/order';
import {
  setChosenDistrictID,
  setChosenSchoolID,
  setDeliveryDate,
  setChosenOrderGuid,
  addOrder,
  removeOrder,
  deleteOrder,
  setOrders,
  reset,
  resetOrders,
  setQuantity,
  setShippingSiteName,
  setShippingAddress,
  setExistingOrder,
} from './../../store/reducers/orderSlice';
import { setSelect, setUrl, setQuery } from '../../store/reducers/dataSlice';
import * as roles from './../../services/constants/roles';
import axios from 'axios';
import { setSelectedMenu } from '../../store/reducers/uiSlice';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Step } = Steps;
const { Option } = Select;
const { confirm } = Modal;

function CreateOrderPage(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [vendorFilter, setVendorFilter] = useState([]);
  const [visibleNewShipping, setVisibleNewShipping] = useState(false);
  const [orderData, setOrderData] = useState({});

  const myDistrict = props.chosenDistrictID
    ? fetchById(props.chosenDistrictID, props.districts)
    : { IsCoOp: false };

  useEffect(() => {
    if (id) {
      axios
        .get(`/orders/${id}`)
        .then((res) => setOrderData(res?.data))
        .catch(() => {});
    } else {
      setOrderData({});
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `/products/?_limit=1000&Buyable=true&Deleted=0&${
          props.chosenOrderGuidID
            ? `&OrderGuides.id=${props.chosenOrderGuidID}&`
            : ''
        }`,
      )
      .then((res) => {
        setVendorFilter(
          res.data
            .map((type) => {
              return { text: type?.VendorId?.Name, value: type?.VendorId?.id };
            })
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.value === value.value),
            ),
        );
      });
  }, [props.chosenOrderGuidID]);

  useEffect(() => {
    props.setSelectedMenu('2');

    return function () {
      props.setQuery('&#&#');
      props.reset();
    };
  }, []);
  //#endregion

  //#region first step

  const [IsDateValid, setIsDateValid] = useState({
    validateStatus: 'success',
    errorMsg: null,
    value: null,
  });
  const DateValidation = () => {
    if (
      fetchById(props.chosenDistrictID, props.districts)
        .UserDeliveryDays.map((d) => d.DayId)
        .includes(
          form.getFieldValue('deliveryDate').format('dddd').toLowerCase(),
        )
    )
      return { validateStatus: 'success', errorMsg: null };
    return {
      validateStatus: 'error',
      errorMsg: 'selected date is not on delivery days of selected district',
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.chosenSchoolID && form.getFieldValue('deliveryDate'))
        setIsDateValid(DateValidation());
    }, 500);
    setVisibleNewShipping(props.chosenSchoolID === -1);
    if (props.chosenSchoolID !== -1) {
      const selectedSchool = props.schools?.filter(
        (x) => x.id === props.chosenSchoolID,
      );
      selectedSchool?.length > 0 &&
        props.setShippingAddress(selectedSchool[0].shippingAddress);
      selectedSchool?.length > 0 &&
        props.setShippingSiteName(selectedSchool[0].DistrictOrSchoolName);
    }
    return function () {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    dispatch(getDistricts());
    if (props.functionality === 'edit' && orderData?.id) {
      dispatch(props.setChosenDistrictID(orderData?.DistrictUserId?.id));
      dispatch(props.setChosenSchoolID(orderData?.SchoolUserId?.id));
      orderData?.orderGuide &&
        dispatch(props.setChosenOrderGuidID(orderData?.orderGuide?.id));
      dispatch(getSchools());
      dispatch(getOrderGuides());
      props.setDeliveryDate(moment(orderData?.DeliveryDate, 'YYYY-MM-DD'));
      form.setFieldsValue({
        deliveryDate: moment(orderData?.DeliveryDate, 'YYYY-MM-DD'),
      });
      setTimeout(() => {
        setPO(orderData?.PO);
      }, 1000);
    }
  }, [orderData]);

  function deliveryDisabledDate(today) {
    return (
      (today && today < moment().endOf('day')) ||
      (fetchById(props.chosenDistrictID, props.districts) &&
        !fetchById(props.chosenDistrictID, props.districts)
          .UserDeliveryDays.map((d) => d.DayId)
          .includes(today.format('dddd').toLowerCase()))
    );
  }

  useEffect(() => {
    form.setFieldsValue({ SchoolName: props.chosenSchoolID });
    if (props.chosenSchoolID) dispatch(getOrderGuides());
  }, [props.chosenSchoolID]);

  useEffect(() => {
    form.setFieldsValue({ OrderGuide: props.chosenOrderGuidID });
  }, [props.chosenOrderGuidID]);

  useEffect(() => {
    props.setUrl('/products');
    props.setSelect(
      `&Buyable=true&Deleted=0&${
        props.chosenOrderGuidID
          ? `&OrderGuides.id=${props.chosenOrderGuidID}&`
          : ''
      }`,
    );
    props.setQuery('&_sort=Code');
  }, [props.chosenOrderGuidID]);

  //#endregion

  //#region second step

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
    props.resetOrders();
    setCurrent((n) => n - 1);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function fetchById(id, array) {
    let result = array.filter((item) => item.id === id)[0];
    return result;
  }

  useEffect(() => {
    form.setFieldsValue({ DistrictName: props.chosenDistrictID });
    if (props.chosenDistrictID) dispatch(getSchools());
  }, [props.chosenDistrictID]);

  useEffect(() => {
    if (props.functionality === 'edit')
      axios.get(`/orders-products?&OrderId.id=${id}&`).then((res) => {
        props.setOrders(
          res.data.map((p) => {
            return {
              ...p.ProductId,
              quantity: p.Quantity,
              orderProductId: p.id,
            };
          }),
        );
      });
  }, [id]);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'Code',
      key: 'Code',
      render: (text) => text,
      sorter: {},
      searchable: true,
      width: 100,
    },
    {
      title: 'Product Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (text) => text,
      sorter: {},
      searchable: true,
    },
    {
      title: 'Lead Time',
      dataIndex: 'TimeLead',
      key: 'TimeLead',
      render: (text, product) =>
        text ? (
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>{text} days</span>
            {moment()
              .add(product.TimeLead, 'days')
              .isBefore(props.deliveryDate) || true ? (
              <></>
            ) : (
              <Tooltip title="Please go back and change the delivery date to allow requested lead time.">
                <img
                  style={{ width: '16px', height: '16px', alignSelf: 'center' }}
                  src="/vectors/red-warning.png"
                  alt=""
                />
              </Tooltip>
            )}
          </div>
        ) : (
          'N/A'
        ),
      sorter: {},
      width: 120,
    },
    {
      title: 'Commercial Cost',
      dataIndex:
        myDistrict && myDistrict.IsCoOp ? 'CoOPReqularPrice' : 'ReqularPrice',
      key:
        myDistrict && myDistrict.IsCoOp ? 'CoOPReqularPrice' : 'ReqularPrice',
      render: (text) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
      sorter: {},
      width: 150,
    },
    {
      title: 'Commodity Price',
      dataIndex:
        myDistrict && myDistrict.IsCoOp
          ? 'CoOPCommidityPrice'
          : 'CommidityPrice',
      key: 'CommidityPrice',
      render: (text) => (
        <div style={{ textAlign: 'right' }}>
          {text ? `$${text.toFixed(2)}` : 'N/A'}
        </div>
      ),
      sorter: {},
      width: 150,
    },
    {
      title: 'URL (Link to specs)',
      dataIndex: ['productAttachment', 'url'],
      key: 'productAttachment.url',
      ellipsis: {
        showTitle: true,
      },
      render: (text) =>
        text ? (
          <a
            className="btn"
            target="_blank"
            href={text}
            style={{ padding: '3px 24px', background: '#97BE11' }}
            rel="noreferrer"
          >
            Spec
          </a>
        ) : (
          'N/A'
        ),
      width: 150,
    },
    {
      title: 'Vendor',
      dataIndex: 'VendorId.id',
      key: 'VendorId.id',
      width: 100,
      searchable: true,
      sortLocation: 'VendorId.id',
      filters: vendorFilter,
      ellipsis: {
        showTitle: true,
      },
      render: (__, info) => (
        <Tooltip placement="topLeft" title={info?.VendorId?.Name}>
          {info?.VendorId?.Name}
        </Tooltip>
      ),
    },
    {
      title: 'PTV',
      dataIndex: 'PTV',
      key: 'PTV',
      width: 70,
      render: (text) => text,
    },
    {
      title: 'Quantity',
      dataIndex: 'Buyable',
      key: 'Buyable',
      render: (text, product) =>
        moment().add(product.TimeLead, 'days').isBefore(props.deliveryDate) ||
        true ? (
          <Row>
            <Col span={16} offset={4}>
              <Row style={{ textAlign: 'center' }}>
                <Col
                  span={6}
                  className="clickable"
                  onClick={(e) => {
                    props.removeOrder(product);
                  }}
                >
                  {'<'}
                </Col>
                <Col span={12} className="quantity">
                  <input
                    type="number"
                    value={
                      props.orders && fetchById(product.id, props.orders)
                        ? fetchById(product.id, props.orders).quantity
                        : ''
                    }
                    onChange={(e) => {
                      props.setQuantity({
                        ...product,
                        quantity: Number(parseInt(e.target.value)),
                      });
                    }}
                  />
                </Col>
                <Col
                  span={6}
                  className="clickable"
                  onClick={(e) => {
                    props.addOrder(product);
                  }}
                >
                  {'>'}
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row className="NOT OK">
            <Col span={16} offset={4}>
              <Row style={{ textAlign: 'center', opacity: '0.5' }}>
                <Col span={6} className="">
                  {'<'}
                </Col>
                <Col span={12}>
                  {props.orders && fetchById(product.id, props.orders)
                    ? fetchById(product.id, props.orders).quantity
                    : 0}
                </Col>
                <Col span={6} className="">
                  {'>'}
                </Col>
              </Row>
            </Col>
          </Row>
        ),
      sorter: {},
      width: 140,
    },
  ];
  //#endregion

  //#region third step
  function calculateTotalPrice(title) {
    let total = 0;
    props.orders.forEach((order) => {
      total += order[title] * order.quantity;
    });
    return total;
  }

  const handleFirstSubmit = (values) => {
    if (props.functionality === 'edit') {
      setCurrent(current + 1);
      return;
    }
    axios
      .get(`/orders`, {
        params: {
          SchoolUserId: values?.SchoolName,
          DeliveryDate: moment(values?.deliveryDate).format('YYYY-MM-DD'),
        },
      })
      .then((res) => {
        if (res?.data?.[0]) {
          confirm({
            title:
              'You already have an existing order for this school on the selected delivery date. Any additional items will be added to that order.',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              dispatch(setExistingOrder(res?.data?.[0]));
              setCurrent(current + 1);
            },
            onCancel() {},
            okText: 'OK',
            cancelText: 'Cancel',
          });
        } else {
          setCurrent(current + 1);
        }
      })
      .catch(() => {
        setCurrent(current + 1);
      });
  };
  const [PO, setPO] = useState('');
  //#endregion

  return (
    <div className="create-order-page flex">
      <div className="main">
        <div className="go-back">
          {current === 0 ? (
            <h2 className="title go-back" onClick={() => navigate(-1)}>
              <img src="/vectors/arrow-left.png" alt="" />
              Go Back
            </h2>
          ) : (
            <h2
              className="title  go-back"
              onClick={(e) => {
                if (current === 1 && props.orders.length)
                  setIsModalVisible(true);
                else setCurrent((s) => s - 1);
              }}
            >
              <img src="/vectors/arrow-left.png" alt="" />
              Go Back
            </h2>
          )}
        </div>

        <div className="steps">
          <Row>
            <Col span={8} offset={8}>
              <Steps current={current}>
                <Step />
                <Step />
                <Step />
                <Step />
              </Steps>
            </Col>
          </Row>
        </div>

        <div className="content">
          {current === 0 && (
            <div className="step1">
              <Form
                className="district-info"
                layout="vertical"
                name="basic"
                onFinish={handleFirstSubmit}
                onFinishFailed={(e) => {}}
                form={form}
                autoComplete="off"
              >
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Date Submitted"
                      rules={[
                        {
                          type: 'object',
                          required: false,
                          message: 'Please input publishDate',
                          // whitespace: true,
                        },
                      ]}
                    >
                      <DatePicker
                        format="MM-DD-YYYY"
                        disabled={true}
                        value={moment()}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="District Name"
                      name="DistrictName"
                      key="DistrictName"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your district ',
                        },
                      ]}
                    >
                      <Select
                        allowClear={true}
                        size={'large'}
                        onChange={(id) => {
                          props.setChosenDistrictID(id);
                          form.setFieldsValue({
                            SchoolName: null,
                            OrderGuide: null,
                          });
                        }}
                        showSearch
                        optionFilterProp="children"
                        disabled={props.roleId !== roles.ADMIN_ROLE_ID}
                        required={true}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {props.districts ? (
                          props.districts.map((option) => (
                            <Option value={option.id} key={option.id}>
                              {option.DistrictOrSchoolName}
                            </Option>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="School Name"
                      name="SchoolName"
                      key="SchoolName"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your school',
                        },
                      ]}
                    >
                      <Select
                        allowClear={true}
                        size={'large'}
                        onChange={(id) => {
                          props.setChosenSchoolID(id);
                          form.setFieldsValue({
                            OrderGuide: null,
                          });
                        }}
                        disabled={props.roleId === roles.SCHOOL_ROLE_ID}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {props.schools ? (
                          props.schools.map((option) => {
                            if (option.id > 0) {
                              return (
                                <Option value={option.id} key={option.id}>
                                  {option.DistrictOrSchoolName} -{' '}
                                  {option.shippingAddress}
                                </Option>
                              );
                            } else {
                              return (
                                <Option value={option.id} key={option.id}>
                                  {option.DistrictOrSchoolName}
                                </Option>
                              );
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {visibleNewShipping && (
                  <Row gutter={10}>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <>
                            <span style={{ color: 'red', marginRight: 5 }}>
                              *
                            </span>
                            Shipping Address
                          </>
                        }
                        name="shippingAddress"
                        key="shippingAddress"
                        onChange={(e) => {
                          props.setShippingAddress(e.target.value);
                        }}
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (!value.trim()) {
                                return Promise.reject(
                                  new Error(
                                    'Please input your shipping address!',
                                  ),
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Please input your shipping address!"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {visibleNewShipping && (
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label="Shipping Site Name"
                        name="shippingSiteName"
                        key="shippingSiteName"
                        onChange={(e) => {
                          props.setShippingSiteName(e.target.value);
                        }}
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Shipping Site Name!',
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Please input your shipping address!"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Order Guide"
                      name="OrderGuide"
                      key="OrderGuide"
                      rules={[
                        {
                          required: props.roleId === roles.SCHOOL_ROLE_ID,
                          message: 'As a school you need to select order guide',
                        },
                      ]}
                    >
                      <Select
                        allowClear={true}
                        size={'large'}
                        onChange={(id) => {
                          props.setChosenOrderGuidID(id);
                          dispatch(getOrderGuides());
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {props.orderGuides ? (
                          props.orderGuides.map((option) => (
                            <Option value={option.id} key={option.id}>
                              {option.Name}
                            </Option>
                          ))
                        ) : (
                          <></>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Delivery Date"
                      name="deliveryDate"
                      key="deliveryDate"
                      validateStatus={IsDateValid.validateStatus}
                      help={IsDateValid.errorMsg}
                      rules={[
                        {
                          required: true,
                          message: 'Please select your delivery date',
                        },
                      ]}
                    >
                      <DatePicker
                        disabled={!props.chosenSchoolID}
                        format="MM-DD-YYYY"
                        disabledDate={deliveryDisabledDate}
                        size="large"
                        onChange={(value) => {
                          props.setDeliveryDate(value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="btn-gp">
                  <Form.Item
                    wrapperCol={{
                      offset: 0,
                      span: 24,
                    }}
                  >
                    <Button className="btn" type="primary" htmlType="submit">
                      Next
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}

          {current === 1 && (
            <div className="step2">
              <Modal
                title="are you sure ?"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Yes"
                cancelText="No"
              >
                <p>If you go back all changes will be removed, are you sure?</p>
              </Modal>
              <Row style={{ marginBottom: '40px' }}>
                <Col span={5}>
                  <div className="card">
                    <Row className="title">District Name</Row>
                    <Row className="text">
                      {props.districts &&
                      props.chosenDistrictID &&
                      fetchById(props.chosenDistrictID, props.districts)
                        ? fetchById(props.chosenDistrictID, props.districts)
                            .DistrictOrSchoolName
                        : 'N/A'}
                    </Row>
                  </div>
                </Col>
                {props.chosenSchoolID &&
                  props.schools &&
                  fetchById(props.chosenSchoolID, props.schools)
                    .DistrictOrSchoolName !== 'ADD NEW' && (
                    <Col span={5}>
                      <div className="card">
                        <Row className="title">School Name</Row>

                        <Row className="text">
                          {props.chosenSchoolID &&
                          props.schools &&
                          fetchById(props.chosenSchoolID, props.schools)
                            ? fetchById(props.chosenSchoolID, props.schools)
                                .DistrictOrSchoolName
                            : 'N/A'}
                        </Row>
                      </div>
                    </Col>
                  )}
                <Col span={5}>
                  <div className="card">
                    <Row className="title">Delivery Date</Row>
                    <Row className="text">
                      {props.deliveryDate
                        ? props.deliveryDate.format('MM-DD-YYYY')
                        : 'N/A'}
                    </Row>
                  </div>
                </Col>
                <Col span={5}>
                  <div className="card">
                    <Row className="title">Order Guide</Row>
                    <Row className="text">
                      {props.orderGuides &&
                      props.chosenOrderGuidID &&
                      fetchById(props.chosenOrderGuidID, props.orderGuides)
                        ? fetchById(props.chosenOrderGuidID, props.orderGuides)
                            .Name
                        : 'N/A'}
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={10}>
                  <div className="card">
                    <Row className="title">Shipping Address</Row>
                    <Row className="text">
                      {props?.myData?.shippingAddress ||
                        props?.shippingAddress ||
                        'N/A'}
                    </Row>
                  </div>
                </Col>
                <Col span={10}>
                  <div className="card">
                    <Row className="title">Shipping Site Name</Row>
                    <Row className="text">
                      {props.shippingSiteName ? props.shippingSiteName : 'N/A'}
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Table columns={columns} />
                </Col>
              </Row>
            </div>
          )}

          {(current === 2 || current === 3) && (
            <div className="step3">
              <Row>
                <Col span={14} className="orders">
                  {props.orders.map((order) => (
                    <div className="order card" key={order.id}>
                      <Row>
                        <Col span={16} className="title">
                          Product code/name:
                        </Col>
                        <Col span={7} className="value">
                          {order.Code},{order.Name}
                        </Col>
                        <div
                          className="remove"
                          onClick={(e) => {
                            props.deleteOrder(order.id);
                          }}
                        >
                          x
                        </div>
                      </Row>

                      <Row className="quantities">
                        <Col span={16} className="title">
                          Quantity:
                        </Col>
                        <Col span={7} className="value">
                          <span
                            className="clickable"
                            onClick={(e) => {
                              props.removeOrder(order);
                            }}
                          >
                            {'<'}
                          </span>
                          <span>{order.quantity}</span>
                          <span
                            className="clickable"
                            onClick={(e) => {
                              props.addOrder(order);
                            }}
                          >
                            {'>'}
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={16} className="title">
                          Commercial Cost:
                        </Col>
                        <Col span={7} className="value">
                          {myDistrict.IsCoOp
                            ? order.CoOPReqularPrice
                              ? '$' + order.CoOPReqularPrice?.toFixed(2)
                              : 'N/A'
                            : order.ReqularPrice
                              ? '$' + order.ReqularPrice?.toFixed(2)
                              : 'N/A'}
                        </Col>
                      </Row>

                      <Row>
                        <Col span={16} className="title">
                          Commodity Price:
                        </Col>
                        <Col span={7} className="value">
                          {myDistrict.IsCoOp
                            ? order.CoOPCommidityPrice
                              ? '$' + order.CoOPCommidityPrice?.toFixed(2)
                              : 'N/A'
                            : order.CommidityPrice
                              ? '$' + order.CommidityPrice?.toFixed(2)
                              : 'N/A'}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Col>
                <Col span={7} offset={1} className="finito">
                  <form
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '15px',
                    }}
                  >
                    <span style={{ alingSelf: 'center', marginRight: '5px' }}>
                      PO Number :{' '}
                    </span>
                    <Input
                      style={{
                        display: 'inline-block',
                        width: '100%',
                        flex: '1',
                      }}
                      size="large"
                      placeholder="Enter PO"
                      value={PO}
                      onChange={(e) => {
                        setPO(e.target.value);
                      }}
                    />
                  </form>

                  <h3>Summary</h3>
                  <hr />
                  <div className="flex">
                    <div className="title">Total Commercial Cost:</div>
                    <div className="value">
                      $
                      {myDistrict.IsCoOp
                        ? calculateTotalPrice('CoOPReqularPrice').toFixed(2)
                        : calculateTotalPrice('ReqularPrice').toFixed(2)}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="title">Total Commodity Price:</div>
                    <div className="value">
                      $
                      {myDistrict.IsCoOp
                        ? calculateTotalPrice('CoOPCommidityPrice').toFixed(2)
                        : calculateTotalPrice('CommidityPrice').toFixed(2)}
                    </div>
                  </div>

                  <button
                    className="btn"
                    disabled={!props.orders.length}
                    onClick={(e) => {
                      if (props.functionality === 'create') {
                        if (props.existingOrder) {
                          dispatch(editOrder(PO));
                        } else {
                          dispatch(postOrders(PO));
                        }
                      } else {
                        dispatch(editOrder(PO));
                      }
                      setCurrent(current + 1);
                    }}
                  >
                    Place Order
                  </button>
                </Col>
              </Row>
            </div>
          )}
          {current === 3 && !props.isLoading ? (
            <div className="step4">
              <div className="bg flex">
                <div className="modal">
                  <div>
                    <img src="/vectors/package.png" alt="" />
                  </div>
                  {props.functionality === 'edit' ? (
                    <p>Your order has been updated successfully!</p>
                  ) : (
                    <>
                      <p>Thank you for submitting an Order.</p>
                      <div>
                        Your order number is{' '}
                        {props.existingOrder?.id || props.orderId}
                      </div>
                    </>
                  )}
                  <Link to={'/orders'}>
                    <span className="btn">Go to Orders</span>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      {current === 1 && props.orders.length ? (
        <div className="order-list" style={{ flex: '0 0 300px' }}>
          <Row className="title">Cart</Row>
          <Row className="orders">
            {props.orders.map((order) => (
              <div className="order card" key={order.id}>
                <Row>
                  <Col span={12} className="title">
                    Product code:
                  </Col>
                  <Col span={9} className="value">
                    {order.Code}
                  </Col>
                  <div
                    className="remove"
                    onClick={(e) => {
                      props.deleteOrder(order.id);
                    }}
                  >
                    x
                  </div>
                </Row>

                <Row>
                  <Col span={12} className="title">
                    Product name:
                  </Col>
                  <Col span={9} className="value">
                    {order.Name}
                  </Col>
                </Row>

                <Row>
                  <Col span={12} className="title">
                    Quantity:
                  </Col>
                  <Col span={12} className="value">
                    {order.quantity}
                  </Col>
                </Row>

                <Row>
                  <Col span={12} className="title">
                    Commercial Cost:
                  </Col>
                  <Col span={12} className="value">
                    {myDistrict.IsCoOp
                      ? order.CoOPReqularPrice
                        ? '$' + order.CoOPReqularPrice?.toFixed(2)
                        : 'N/A'
                      : order.ReqularPrice
                        ? '$' + order.ReqularPrice?.toFixed(2)
                        : 'N/A'}
                  </Col>
                </Row>

                <Row>
                  <Col span={12} className="title">
                    Commodity Price:
                  </Col>
                  <Col span={12} className="value">
                    {myDistrict.IsCoOp
                      ? order.CoOPCommidityPrice
                        ? '$' + order.CoOPCommidityPrice?.toFixed(2)
                        : 'N/A'
                      : order.CommidityPrice
                        ? '$' + order.CommidityPrice?.toFixed(2)
                        : 'N/A'}
                  </Col>
                </Row>
              </div>
            ))}
          </Row>
          <Row className="bottom">
            <hr />
            <div className="flex price">
              <div className="title">Total Commercial Cost:</div>
              <div className="value">
                $
                {myDistrict.IsCoOp
                  ? calculateTotalPrice('CoOPReqularPrice').toFixed(2)
                  : calculateTotalPrice('ReqularPrice').toFixed(2)}
              </div>
            </div>
            <div className="flex price">
              <div className="title">Total Commodity Price:</div>
              <div className="value">
                $
                {myDistrict.IsCoOp
                  ? calculateTotalPrice('CoOPCommidityPrice').toFixed(2)
                  : calculateTotalPrice('CommidityPrice').toFixed(2)}
              </div>
            </div>

            <button
              className="btn"
              onClick={() => {
                setCurrent(current + 1);
              }}
            >
              {' '}
              Next
            </button>
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

function mapStateToProps(state) {
  let schools = [...state?.order?.schools];
  if (state.order.chosenDistrictID) {
    const selectedDistrict = state.order.districts?.filter(
      (item) => item.id === state.order.chosenDistrictID,
    );
    selectedDistrict?.length > 0 && schools.push(selectedDistrict[0]);
  }
  schools.push({
    id: -1,
    DistrictOrSchoolName: 'ADD NEW',
  });
  return {
    districts: state.order.districts,
    schools: schools,
    orderGuides: state.order.orderGuides,
    chosenDistrictID: state.order.chosenDistrictID,
    chosenSchoolID: state.order.chosenSchoolID,
    chosenOrderGuidID: state.order.chosenOrderGuidID,
    deliveryDate: state.order.deliveryDate,
    orders: state.order.orders,
    isLoading: state.order.isLoading,

    orderId: state.order.orderId,
    data: state.order.data,

    roleId: state.me.roleId,
    myData: state.me.data,
    shippingAddress: state.order.shippingAddress,
    shippingSiteName: state.order.shippingSiteName,
    existingOrder: state.order.existingOrder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setShippingSiteName: (value) => dispatch(setShippingSiteName(value)),
    setShippingAddress: (value) => dispatch(setShippingAddress(value)),
    setDeliveryDate: (value) => dispatch(setDeliveryDate(value)),
    setChosenDistrictID: (id) => dispatch(setChosenDistrictID(id)),
    setChosenSchoolID: (id) => dispatch(setChosenSchoolID(id)),
    setChosenOrderGuidID: (id) => dispatch(setChosenOrderGuid(id)),
    addOrder: (values) => dispatch(addOrder(values)),
    removeOrder: (values) => dispatch(removeOrder(values)),
    deleteOrder: (id) => dispatch(deleteOrder(id)),
    setOrders: (data) => dispatch(setOrders(data)),
    reset: () => dispatch(reset()),
    resetOrders: () => dispatch(resetOrders()),
    setQuantity: (data) => {
      dispatch(setQuantity(data));
    },

    setUrl: (url) => dispatch(setUrl(url)),
    setSelect: (q) => dispatch(setSelect(q)),
    setQuery: (q) => dispatch(setQuery(q)),
    setExistingOrder: (q) => dispatch(setExistingOrder(q)),

    setSelectedMenu: (id) => dispatch(setSelectedMenu(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrderPage);
